$item-font-size-desktop: 16px;
$item-font-size-mobile: 14px;
$total-font-size: 18px;

p {
  margin-top: 0;
  margin-bottom: 0;
}

.start-lines {
  padding: 1px;
  position: relative;
  display: inline-block;

  span {
    position: relative;
    background-color: white;
    z-index: 2;
  }

  .order-receipt-label {
    pointer-events: none;

    &:hover {
      padding-bottom: 2rem;
    }
  }

  .info-icon-cft {
    pointer-events: auto;
    transform: rotateZ(180deg);
    color: #000;

    &:hover + .tooltip-cft {
      display: block;
    }
  }

  .cft-span-label {
    color: #000;
  }

  .tooltip-cft {
    color: #fff;
    max-width: 380px;
    display: none;
    padding: 0 10px;
    background-color: #aaa;
    position: absolute;
    top: 50%;
    border-radius: 0.25rem;
    width: auto;
    font-size: small;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 26%;
      margin-left: -5px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent #aaa;
    }
  }

  .end-lines {
    padding: 1px;

    span {
      position: relative;
      background-color: white;
      z-index: 2;
    }
  }

  &::before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
    z-index: 1;
    color: #adadad;
  }
}

.leading-lines {
  display: flex;
  flex-wrap: nowrap;
  margin: 7px 0;
}

.dots-line {
  border-bottom: 1px dashed #aaa;
  flex-grow: 1;
  margin: 6px;

  &.discount {
    border-bottom: 1px dashed $green-color;
  }

  &.total {
    border-bottom: 1.5px dashed #aaa;
  }
}

.subtotal-item,
.shipping-item,
.gift-item {
  p {
    font-size: $item-font-size-desktop;
    color: $primary-color-2;
    font-weight: $font-weight-regular;

    @include respond-to-max-width($max-mobile-width) {
      font-size: $item-font-size-mobile;
    }
  }
}

.order-discount,
.shipping-discount {
  p {
    font-size: $item-font-size-desktop;
    color: $green-color;
    font-weight: $font-weight-bold;

    @include respond-to-max-width($max-mobile-width) {
      font-size: $item-font-size-mobile;
    }
  }
}

.grand-total {
  p {
    font-size: $total-font-size;
    color: $primary-color-2;
    font-weight: $font-weight-bold;
  }
}
