$grid-gap-spacer: 6px;

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-6,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-6,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-6,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-6,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-6,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: $grid-gap-spacer;
  padding-left: $grid-gap-spacer;
}

.container.page-designer-reference .col,
.container.page-designer-reference .col-1,
.container.page-designer-reference .col-2,
.container.page-designer-reference .col-3,
.container.page-designer-reference .col-4,
.container.page-designer-reference .col-5,
.container.page-designer-reference .col-6,
.container.page-designer-reference .col-7,
.container.page-designer-reference .col-6,
.container.page-designer-reference .col-9,
.container.page-designer-reference .col-10,
.container.page-designer-reference .col-11,
.container.page-designer-reference .col-12,
.container.page-designer-reference .col-auto,
.container.page-designer-reference .col-lg,
.container.page-designer-reference .col-lg-1,
.container.page-designer-reference .col-lg-2,
.container.page-designer-reference .col-lg-3,
.container.page-designer-reference .col-lg-4,
.container.page-designer-reference .col-lg-5,
.container.page-designer-reference .col-lg-6,
.container.page-designer-reference .col-lg-7,
.container.page-designer-reference .col-lg-6,
.container.page-designer-reference .col-lg-9,
.container.page-designer-reference .col-lg-10,
.container.page-designer-reference .col-lg-11,
.container.page-designer-reference .col-lg-12,
.container.page-designer-reference .col-lg-auto,
.container.page-designer-reference .col-md,
.container.page-designer-reference .col-md-1,
.container.page-designer-reference .col-md-2,
.container.page-designer-reference .col-md-3,
.container.page-designer-reference .col-md-4,
.container.page-designer-reference .col-md-5,
.container.page-designer-reference .col-md-6,
.container.page-designer-reference .col-md-7,
.container.page-designer-reference .col-md-6,
.container.page-designer-reference .col-md-9,
.container.page-designer-reference .col-md-10,
.container.page-designer-reference .col-md-11,
.container.page-designer-reference .col-md-12,
.container.page-designer-reference .col-md-auto,
.container.page-designer-reference .col-sm,
.container.page-designer-reference .col-sm-1,
.container.page-designer-reference .col-sm-2,
.container.page-designer-reference .col-sm-3,
.container.page-designer-reference .col-sm-4,
.container.page-designer-reference .col-sm-5,
.container.page-designer-reference .col-sm-6,
.container.page-designer-reference .col-sm-7,
.container.page-designer-reference .col-sm-6,
.container.page-designer-reference .col-sm-9,
.container.page-designer-reference .col-sm-10,
.container.page-designer-reference .col-sm-11,
.container.page-designer-reference .col-sm-12,
.container.page-designer-reference .col-sm-auto,
.container.page-designer-reference .col-xl,
.container.page-designer-reference .col-xl-1,
.container.page-designer-reference .col-xl-2,
.container.page-designer-reference .col-xl-3,
.container.page-designer-reference .col-xl-4,
.container.page-designer-reference .col-xl-5,
.container.page-designer-reference .col-xl-6,
.container.page-designer-reference .col-xl-7,
.container.page-designer-reference .col-xl-6,
.container.page-designer-reference .col-xl-9,
.container.page-designer-reference .col-xl-10,
.container.page-designer-reference .col-xl-11,
.container.page-designer-reference .col-xl-12,
.container.page-designer-reference .col-xl-auto,
.container,
.container-fluid {
  padding: 0 $grid-gap-spacer;
}

.row,
.container.page-designer-reference .row {
  margin-right: -$grid-gap-spacer;
  margin-left: -$grid-gap-spacer;
}

.row-product-set-custom {
  margin: 40px -$grid-gap-spacer 40px -$grid-gap-spacer;

  @include respond-to-max-width(468px) {
    margin: 40px -$grid-gap-spacer 0 -$grid-gap-spacer;
  }
}
