.quick-view-dialog,
.choose-bonus-product-dialog {
  .modal {
    &-header {
      font-weight: 700;
      font-size: 20px;

      .close {
        font-weight: 800;
        line-height: 0.5rem;
      }

      span {
        width: 100%;
        text-align: center;
        font-weight: 600;
      }
    }

    &-body {
      width: 100%;
      padding: 2% 7%;
      max-height: 35em;

      .product {
        &-image-container {
          width: 55%;
          margin-left: 25%;
        }

        &-name {
          font-size: 22px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 20px;
          }
        }
      }

      .attribute {
        margin-left: 15%;
      }

      .btn {
        &-bonus-select:hover {
          color: #fff;
          background-color: $secondary-color-1;
          border-color: $secondary-color-1;
        }

        &-outline {
          color: #fff;
          background-color: $secondary-color-1;
          border-color: $secondary-color-1;
          border-width: 2px;
          font-weight: 500;
          text-transform: uppercase;
          width: 100%;
          height: 50px;
          margin: 20px 0;
          border-radius: 25px;
        }
      }
    }

    &-footer {
      .container {
        margin-left: 25%;

        .align-items-end {
          padding: 0 10%;
        }

        .pre-cart-products {
          font-size: 16px;
        }

        .btn-add-to-cart {
          width: 98%;
          color: #fff;
          background-color: $secondary-color-1;
          font-size: 15px;
          font-weight: 500;
          margin-top: 15px;
          border-radius: 25px;
        }
      }
    }

    &-content {
      width: 100%;
      margin-top: 40%;

      .product-detail {
        position: relative;
        margin: 0;

        .quantity-form {
          margin-top: 5px;
        }

        @include respond-to-max-width($max-mobile-width) {
          padding-left: 5%;
        }
      }
    }
  }
}
