$shipping-methods-parent-class: '.modal-shipping-methods-availability';

.product-detail .attributes,
.cart {
  .modal-shipping-methods-availability {
    &-container {
      display: flex;
    }

    &-input {
      @include font($font-weight-medium, 16px, 20px);

      border: 1px solid $black-dabra-color;
      border-radius: 3px 0 0 3px;
      border-right: none;
      font-family: $main-font;
      outline: none;
      padding: 8px 12px;
      width: 100%;
    }

    &-check-container {
      cursor: pointer;
      display: flex;
      height: 44px;
      margin-right: 17px;
      position: relative;
      width: 18px;
    }

    &-label {
      @include font($font-weight-medium, 14px, 20px);

      display: block;
      font-family: $main-font;
    }

    &-list {
      border-bottom: none;
      display: flex;
      text-align: center;
      margin-top: 15px;
      width: 100%;

      &-element {
        border: 1px solid $black-dabra-color;
        height: 50px;
        margin: 0;
        width: 50%;
      }
    }

    &-other-stores-label {
      @include font($font-weight-bold, 14px, 20px);

      font-family: $main-font;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    &-other-stores-container {
      align-items: center;
      border-bottom: 1px solid $gray4-dabra-color;
      padding: 12px 0;

      svg {
        min-width: 21px;
        text-align: center;
      }
    }

    &-other-methods {
      @include font($font-weight-medium, 16px, 20px);

      display: block;
      font-family: $main-font;
      color: #0070d2 !important;
      text-decoration: underline;
    }

    &-redirect {
      @include font($font-weight-medium, 14px, 20px);

      display: block;
      font-family: $main-font;
      margin-top: 7px;
      text-decoration: none;
      width: max-content;
    }

    &-send-btn {
      @include font($font-weight-medium, 16px, 20px);

      background: $black-dabra-color;
      border: 1px solid $black-dabra-color;
      border-radius: 0 3px 3px 0;
      border-left: none;
      color: white;
      font-family: $main-font;
      outline: none;
      text-transform: uppercase;
      width: 108px;
    }

    &-stores-details {
      overflow-y: auto;
      max-height: 40vh;
    }

    &-store-card-container {
      width: -webkit-fill-available;
    }

    &-tab-title-container {
      color: $black-dabra-color;
      display: flex;
      gap: 5px;
      justify-content: center;
    }

    &-tab-title,
    &-tab-subtitle {
      display: block;
      font-family: $main-font;
    }

    &-tab-title {
      @include font($font-weight-medium, 14px, 20px);

      color: $black-dabra-color;
      text-transform: uppercase;
    }

    &-tab-subtitle {
      @include font($font-weight-medium, 12px, 16px);

      color: $gray1-dabra-color;
    }

    &-tab-container {
      align-content: center;
      background: white;
      display: block;
      height: 100%;
      width: 100%;

      &.active {
        background: $black-dabra-color;

        #{$shipping-methods-parent-class} {
          &-tab-title,
          &-tab-subtitle,
          &-tab-title-container {
            color: white;
          }
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    &-hd-container-data {
      border-bottom: 1px solid $gray4-dabra-color;
      padding: 12px 0;
    }

    &-footer {
      background: $white-dabra-color;
      bottom: 0;
      box-shadow: 0 -2px 3px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-around;
      padding: 16px;
      position: absolute;
      transform: translateX(-16px);
      width: 100%;
      z-index: 2;

      &-reset-btn {
        @include font($font-weight-medium, 14px, 16px);

        background: $white-dabra-color;
        border: none;
        color: $gray1-dabra-color;
        cursor: default;
        font-family: $main-font;
        outline: none;
        padding: 0 50px;
        text-decoration: underline;
        width: 50%;

        &.m-enabled {
          color: $black-dabra-color;
          cursor: pointer;
        }
      }

      &-save-btn {
        @include font($font-weight-medium, 14px, 16px);

        background: $gray1-dabra-color;
        border: none;
        border-radius: 25px;
        color: $white-dabra-color;
        cursor: default;
        font-family: $main-font;
        padding: 10px 50px;
        outline: none;
        text-transform: uppercase;
        width: 50%;

        &.m-enabled {
          background: $black-dabra-color;
          cursor: pointer;
        }

        @include respond-to-min-width($max-mobile-width) {
          padding: 17px 50px;
        }
      }
    }

    &-method-message {
      @include font($font-weight-regular, 14px, 20px);

      color: #777;
      font-family: $main-font;
      width: 95%;
    }

    &-text {
      @include font($font-weight-regular, 14px, 20px);

      display: block;
      font-family: $main-font;

      &.m-bold_text {
        @include font($font-weight-bold, 14px, 20px);
      }
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    .close {
      opacity: unset;
      padding: 0;
      margin: 0;

      &-button-img {
        filter: invert(1);
        height: 20px;
        transform: translateY(-4px);
      }
    }

    .card-header {
      align-items: center;
      background: white;
      padding: 12px 0;

      &-title {
        @include font($font-weight-medium, 16px, 20px);

        color: $black-dabra-color;
        font-family: $main-font;
      }

      &-price-container {
        display: flex;
        gap: 5px;
        margin-top: 5px;
      }

      &-price-label {
        @include font($font-weight-regular, 14px, 16px);

        color: $gray1-dabra-color;
        font-family: $main-font;
      }

      &-price {
        @include font($font-weight-bold, 14px, 16px);

        color: $black-dabra-color;
        font-family: $main-font;
      }

      &-secondary-row {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &-delivery-time {
        @include font($font-weight-regular, 14px, 20px);

        color: #777;
        font-family: $main-font;
        margin-top: 5px;
      }
    }

    .m-cart_container {
      margin-bottom: 75px;
    }

    .card-body {
      padding: 10px 0;
    }

    .card-body-store-data {
      @include font($font-weight-regular, 14px, 20px);

      font-family: $main-font;
      max-width: 350px;
      padding: 5px 0;

      &-title {
        @include font($font-weight-medium, 14px, 20px);
      }
    }

    .card-title-icon-container {
      align-items: center;
      display: flex;
      gap: 5px;

      .fa {
        min-width: 21px;
        text-align: center;
      }

      .fa-shopping-bag {
        font-size: 14px;
      }

      .fa-map-marker {
        font-size: 20.5px;
      }
    }

    .card-title-container {
      display: flex;
      justify-content: space-between;

      .card-title {
        @include font($font-weight-medium, 16px, 20px);

        font-family: $main-font;
        margin-bottom: 0;
      }
    }

    .card-title-data {
      display: flex;
      justify-content: space-between;

      &-delivery-cost {
        @include font($font-weight-bold, 14px, 20px);

        font-family: $main-font;
        color: $green-dabra-color;
      }

      &-delivery-time {
        color: $gray1-dabra-color;
        display: flex;
        font-family: $main-font;

        &-msg {
          @include font($font-weight-regular, 14px, 20px);

          padding-right: 5px;
        }

        &-availability {
          @include font($font-weight-regular, 14px, 20px);

          &.m-bold_text {
            @include font($font-weight-bold, 14px, 20px);
          }
        }

        &.m-fastest-method {
          color: $green-dabra-color;
        }
      }
    }

    .card-header.collapsed {
      .fa-chevron-up {
        transform: rotate(180deg);
      }
    }

    .modal-body {
      padding: 16px;
    }

    .m-border_right {
      border-left: none;
      border-radius: 0 6px 6px 0;
    }

    .m-border_left {
      border-radius: 6px 0 0 6px;
      border-right: none;
    }

    .modal {
      &-header {
        align-items: center;
        padding: 0.4rem 1rem;
      }

      &-title {
        @include font($font-weight-bold, 20px, 20px);

        font-family: $main-font;
      }
    }

    .tab-pane {
      .card-body.show {
        border-bottom: 1px dashed $gray4-dabra-color;
      }
    }

    .card-error-msg {
      margin: 20px 0;
      text-align: center;
      width: 100%;

      &-text {
        @include font($font-weight-regular, 16px, 20px);

        color: $black-dabra-color;
        font-family: $main-font;
        margin-top: 10px;
      }

      &-title {
        @include font($font-weight-bold, 16px, 20px);

        color: $black-dabra-color;
        font-family: $main-font;
        margin-top: 10px;
      }
    }

    * {
      &::-webkit-scrollbar {
        height: 8px;
        padding: 0;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(75deg, $gray2-dabra-color 14%, $gray3-dabra-color 64%);
        border-radius: 9px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(13deg, $gray5-dabra-color 5%, $gray7-dabra-color 74%);
      }

      &::-webkit-scrollbar-track {
        background: $white-dabra-color;
        border-radius: 9px;
        box-shadow: inset 7px -4px 12px $gray6-dabra-color;
      }
    }

    label.shipping-method-radio-button {
      align-items: center;
      cursor: pointer;
      font-family: $main-font;
      font-size: 0.9375rem;
      font-weight: $font-weight-regular;
      height: 70px;
      position: absolute;
      transform: translateY(-10px);
      z-index: 1;

      &.m-have_stores {
        max-width: 440px;
        width: 83vw;
      }

      &::before {
        background-color: $secondary-color-2;
        border: 0.0625rem solid $primary-color-2;
        border-radius: 0.6875rem;
        content: " ";
        display: inline-block;
        height: 1.0625rem;
        position: relative;
        width: 1.0625rem;
        top: 13px;
      }
    }

    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $secondary-color-2;

      &:checked + label.shipping-method-radio-button::after {
        background: white;
        border-radius: 0.6875rem;
        content: " ";
        display: block;
        height: 0.4375rem;
        left: 5px;
        position: absolute;
        top: 18px;
        width: 0.4375rem;
      }

      &:checked + label.shipping-method-radio-button::before {
        background-color: $primary-color-2;
      }
    }
  }
}
