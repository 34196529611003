.container {
  &-outer-promotions {
    width: 380px;
    height: auto;
    border-radius: 3px;
    border: 1px solid #cacaca;
    box-shadow: 0 3px 6px 0 darkgray;
    position: fixed;
    z-index: 1061;
    left: 10px;
    bottom: 80px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 10px;
    line-height: normal;

    .container {
      .popUpContainer {
        .slick {
          &-next {
            right: 0;
            z-index: 3;
            padding-left: 2rem;

            &::before {
              font-family: "FontAwesome";
              content: "\f054";
              display: flex;
              color: $black;
              font-size: 1rem;
              line-height: 0;
            }
          }

          &-prev {
            margin-left: 1rem;
            z-index: 3;
            padding-right: 2rem;

            &::before {
              font-family: "FontAwesome";
              content: "\f053";
              display: flex;
              color: $black;
              font-size: 1rem;
              line-height: 0;
            }
          }
        }

        .closePopUp {
          font-size: 1.2em;
          border: none;
          background: none;
          margin-left: 16.8em;
          color: #342e2e;

          img.close-img {
            width: 16px;
          }
        }

        .titlePromo {
          color: $secondary-color-1;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          font-family: $family;
          margin-bottom: 12px;
          margin-top: 5px;
        }

        .popUpProduct {
          display: flex;
          width: 100%;

          .imgProductContainer {
            width: 35%;

            .imgProduct {
              width: 80%;
              padding-left: 10px;
            }
          }

          .productDetail {
            width: 65%;
            padding-right: 10px;

            .productName {
              color: $primary-color-2;
              text-align: left;
              font-size: 16px;
              font-weight: normal;
              font-family: $family;
              margin-bottom: 10px;
            }

            .productPrice {
              font-size: 22px;
              font-weight: bold;
            }

            .productPriceDiscounted {
              font-size: 22px;
              font-weight: normal;
              color: #cacaca;
              text-decoration: line-through;
            }
          }
        }

        .popUpProductSlider {
          display: flex;

          .productInfoContainer {
            display: flex;

            .imgProductContainer {
              width: 30%;

              .imgProduct {
                width: 80%;
                margin-left: 20px;
              }
            }

            .productDetail {
              display: flex;
              flex-direction: column;

              .productName {
                color: $primary-color-2;
                text-align: left;
                font-size: 16px;
                font-weight: normal;
                font-family: $family;
                margin-bottom: 10px;
                margin-left: 12px;
              }

              .priceContainer {
                margin-left: 15px;

                .productPrice {
                  font-size: 22px;
                  font-weight: bold;
                }

                .productPriceDiscounted {
                  font-size: 22px;
                  font-weight: normal;
                  color: #cacaca;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
    }

    .popUpCategory {
      a {
        color: #444;
        text-decoration: none;
        background-color: transparent;
        z-index: 1;
      }

      .categoryPromoDetail {
        color: $primary-color-2;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-family: $family;
        margin-bottom: 12px;
      }

      .discountPromo {
        color: $secondary-color-1;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .popUpCategorySlider {
      a {
        color: #444;
        text-decoration: none;
        background-color: transparent;
        z-index: 1;
      }

      .categoryPromoDetail {
        color: $primary-color-2;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-family: $family;
        margin-bottom: 12px;
      }

      .discountPromo {
        color: $secondary-color-1;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    .popUpSubCategory {
      a {
        color: #444;
        text-decoration: none;
        background-color: transparent;
        z-index: 1;
      }

      .subcategoryPromoDetail {
        color: $primary-color-2;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-family: $family;
        margin-bottom: 12px;
      }

      .discountPromo {
        color: $secondary-color-1;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .popUpSubCategorySlider {
      a {
        color: #444;
        text-decoration: none;
        background-color: transparent;
        z-index: 1;
      }

      .subcategoryPromoDetail {
        color: $primary-color-2;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-family: $family;
        margin-bottom: 12px;
      }

      .discountPromo {
        color: $secondary-color-1;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    @include respond-to-max-width(400px) {
      width: 95vw;
      padding: 0;
      left: 7px;

      .container {
        .popUpContainer {
          .closePopUp {
            width: 100%;
            margin-left: unset;
            margin-top: 10px;
            position: relative;
            display: flex;
            justify-content: flex-end;
          }

          .popUpProduct .productDetail {
            .productName {
              font-size: 14px;
            }

            .productPrice,
            .productPriceDiscounted {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
