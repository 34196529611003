//local variables
$modal-max-width-desktop: 600px;
$modal-max-width-mobile: 96vw;
$default-font-color: #121212;
$titleFontSize: 20px;
$titleFontSize-mobile: 18px;
$normalFontSize: 1rem;
$robotoFont: "Roboto", sans-serif;
$gutter: 16px;
$grayFont: #999;
$grayBackground: #f2f2f2;
$borderColor: #cacaca;
$paddingBody: 23px;
$paddingBody-mobile: 17px;
$carouselWidth: 550px;
$carouselWidth-mobile: 83vw;
$smallDotsSize: 12px;

#size-chart-bikes {
  .size-chart-bikes-outer {
    max-width: $modal-max-width-desktop;
    width: $modal-max-width-desktop;
    color: $default-font-color;

    @include respond-to-max-width(600px) {
      max-width: $modal-max-width-mobile;
      width: $modal-max-width-mobile;
      color: $default-font-color;
    }
  }

  &-Title {
    font-weight: bold;
    font-size: $titleFontSize;
    font-family: $main-font;

    @include respond-to-max-width(600px) {
      font-size: $titleFontSize-mobile;
    }
  }

  .modal {
    &-header {
      background-color: $grayBackground;
      border: 1px solid $borderColor;
    }

    &-content {
      height: auto;
    }

    &-close {
      font-size: unset;
      padding: unset;
      margin-left: ($gutter / 2);
      border: none;

      span {
        font-size: $titleFontSize;
        color: $grayFont;
      }
    }

    &-body {
      padding: $paddingBody;

      .logo-container {
        margin-bottom: ($gutter / 2) + ($gutter / 4);
      }

      .carousel-container {
        opacity: 0;
        margin: auto;
        width: $carouselWidth;
        height: auto;

        #carouselBikeSizeChart-desktop {
          display: block !important;
        }

        #carouselBikeSizeChart-mobile {
          display: none !important;
        }
      }

      .slick {
        &-list {
          height: auto;
        }

        &-next {
          right: -15px;
        }

        &-prev {
          left: -18px;
        }

        &-dots {
          display: flex;
          justify-content: center;
          bottom: -50px;

          li {
            width: ($gutter / 2) !important;

            button {
              &::before {
                font-size: $smallDotsSize;
                transition: 0.3s ease;
              }
            }
          }
        }

        &-active {
          button {
            &::before {
              font-size: $normalFontSize;
              transition: 0.2s ease;
            }
          }
        }
      }

      @include respond-to-max-width(600px) {
        padding: $paddingBody-mobile;

        .carousel-container {
          opacity: 0;
          width: $carouselWidth-mobile;

          #carouselBikeSizeChart-desktop {
            display: none !important;
          }

          #carouselBikeSizeChart-mobile {
            display: block !important;
          }
        }
      }
    }

    &-text {
      font-size: $normalFontSize;
      margin-bottom: ($gutter / 2);

      &:nth-child(1) {
        margin-bottom: ($gutter / 2);
      }

      &:nth-child(2) {
        margin-bottom: $gutter + ($gutter / 2);
      }
    }
  }
}
