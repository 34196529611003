.modal-dialog.in-store-inventory-dialog {
  max-width: 1109px;
  transform: translateY(-50%);
  top: 50%;

  .modal {
    &-header {
      background-color: white;
      padding: 1rem;
      align-items: center;

      h2 {
        margin: 0;
        font-family: $main-font;
        font-size: 1.25rem;
      }

      .close {
        margin: 0;
        font-size: 1rem;
        padding: 0;

        img.close-img {
          width: 16px;
          vertical-align: bottom;
        }
      }
    }

    &-body {
      padding: 15px;

      .store-locator-container {
        .store-locator {
          .btn-storelocator-search {
            margin-bottom: 0;
          }

          .form-control-label {
            text-align: left;
            margin-top: 20px;

            &::before {
              content: "*";
              color: #c00;
              margin-right: 2px;
            }
          }

          .my-location-search {
            text-decoration: underline;
          }
        }

        .btn-click-collect {
          text-transform: uppercase;
          border-color: #ed3137;
          border-radius: 2px;
          background: #ed3237;
          border: none;
          height: 50px;

          &.add-to-cart,
          &.add-to-cart-color {
            background: #121212;
            border-color: #121212;
          }

          @include respond-to-min-width(544px) {
            margin: 1rem auto;
          }
        }

        .btn-click-collect.cc-checkout {
          border-color: #121212;
          background: #121212;
          border: none;
          height: 50px;
          border-radius: 25px;
          text-transform: none;

          @include respond-to-min-width(544px) {
            margin: 1rem auto;
          }
        }

        .results-card {
          .card-body {
            .results {
              width: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              max-height: 21.875em;
              margin-right: -0.938em;
            }
          }
        }
      }

      .map-cc-container {
        margin-top: 0;
        overflow: hidden;

        @include respond-to-max-width(768px) {
          margin-top: 20px;
        }

        .map-canvas {
          display: block;
          position: relative;
          overflow: hidden;
          height: 100%;

          @include respond-to-max-width(768px) {
            height: 0;
            padding-bottom: 75%;
            margin-top: 0;
          }
        }
      }

      .msg-title {
        display: flex;
        align-items: start;

        .fa-exclamation-circle {
          color: #fda806;
          transform: rotate(180deg);
          margin-right: 4px;

          &::before {
            font-size: 17px;
          }
        }

        p {
          font-size: 14px;
          color: #999;
          margin: -3px 0;
        }
      }

      .container {
        max-width: unset;
      }

      @include respond-to-max-width(768px) {
        padding: 20px;
        max-height: 70dvh;
        overflow-y: auto;

        .results {
          margin: 20px 0;
        }
      }
    }

    &-footer {
      padding: 0;
      border: none;
    }
  }

  &-postalModal {
    max-width: 530px;
    width: 100%;
    margin: auto;
    top: 25%;
    padding: 1rem;

    .modal {
      &-header {
        background-color: white;
        padding: 1rem;
        align-items: center;

        h2 {
          margin: 0;
          font-family: $main-font;
          font-size: 1.25rem;
        }

        .close {
          margin: 0;
          font-size: 1rem;
          padding: 0;

          img.close-img {
            width: 16px;
            vertical-align: bottom;
          }
        }
      }

      &-body {
        padding: 1rem;

        .msg-title {
          display: flex;
          align-items: start;

          .fa-exclamation-circle {
            color: #fda806;
            transform: rotate(180deg);
            margin-right: 5px;

            &::before {
              font-size: 17px;
            }
          }

          p {
            font-size: 14px;
            color: #999;
            margin: 0;
          }
        }

        .my-location-search {
          text-align: center;
          width: 70%;
          text-decoration: underline;
        }
      }

      &-footer {
        padding: 0;
        border: none;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(75deg, #cfcfcf 14%, #d1d1d1 64%);
      border-radius: 9px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(13deg, #ccc 5%, #d6d6d6 74%);
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 9px;
      box-shadow: inset 7px -4px 12px #f0f0f0;
    }
  }
}

#clickandcollect-search {
  margin-bottom: 10px;

  .container-input {
    display: flex;

    input {
      width: 70%;
      height: 40px;
      border-width: 1px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      padding-left: 12px;

      &::placeholder {
        font-size: 16px;
      }
    }

    button {
      margin-bottom: 6px;
      margin-left: -7px;
      width: 30%;
      height: 40px;
      border: none;
      background-color: black;
    }
  }

  .form-control-label {
    text-align: left;
    margin-top: 20px;

    &::before {
      content: "*";
      color: #c00;
      margin-right: 2px;
    }
  }

  .invalid-feedback-click {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #c00;
  }
}
