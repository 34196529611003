// Breadcrumbs
$breadcrumbs-font-size: 14px;
$breadcrumbs-color: #999;
$last-breadcrumb-color: #444;
$robotoFont: 'Roboto';

.b-breadcrumb {
  font-family: $main-font;
  min-width: 100%;

  &-container {
    padding: 20px;

    @include respond-to-min-width(1342px) {
      padding: 20px 32px;
    }

    @include respond-to-between(545px, 1341px) {
      padding: 20px 45px;
    }
  }

  &-list_container {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    font-size: $breadcrumbs-font-size;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &-item_link {
    @include font($main-font, $breadcrumbs-font-size, 20px);

    color: $breadcrumbs-color;
  }

  &-last_item_link {
    @include font($main-font, $breadcrumbs-font-size, 20px);

    color: $last-breadcrumb-color;
  }
}
