@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

$modal-max-width: 790px;
$bigGutter: 30px;
$gutter: 4px;
$fontSize: 0.875em;
$inputWidth: 350px;
$robotoFont: "Roboto", sans-serif;
$borderColor: #cacaca;
$mainFontColor: #121212;
$black: #000;
$warningColor: #fda806;
$white: #fff;
$red: #ed3237;
$warningMsg: #999;
$disabledButton: #f37a7e;
$selectedBlue: #1d83ce;

#personalization-modal {
  padding: ($gutter * 4);
}

.modal {
  &-footer.custom-footer {
    padding: ($gutter * 4) 0;
    align-items: flex-end;
    border: none;

    .main-footer {
      margin: 0;
    }

    .edit-personalization-btn {
      color: $mainFontColor;
      margin: 0;
      padding: 0;
      font-size: $fontSize;
      cursor: pointer;
      text-decoration: underline;
    }

    &.personalization-footer {
      border: none;
    }

    .personalization-confirm,
    .summary-confirm {
      background-color: $red;
      color: $white;
      margin-left: $bigGutter;
      border-color: $red;
      border-radius: 25px;
      min-width: 180px;
      min-height: 50px;
      text-transform: uppercase;
    }
  }

  &-dialog {
    &.custom-modal {
      max-width: $modal-max-width;
      width: auto; // just for number and name inputs
      height: auto;
      margin: auto;

      #personalization-form {
        padding: 10px 30px;
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        padding: 30px 30px 0;

        .inputs-container {
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;

          .input-group {
            max-width: $inputWidth;
            min-width: $inputWidth;

            input {
              background-color: $white;

              &.is-valid {
                border-bottom: 1px solid #27ae60;
                border-top: none;
                border-left: none;
              }
            }

            @include respond-to-max-width(820px) {
              min-width: unset;
              max-width: unset;
            }
          }

          .form {
            &-group {
              margin-bottom: 0.5rem;
            }

            &-control {
              border-radius: 0;
              box-shadow: 0 0 0 1000px #fff inset;
              border: none;
              border-bottom: 1px solid #999;
              border-color: #999;
              color: #999;

              &:focus,
              &:-webkit-autofill,
              &.is-valid {
                + .form-control-label {
                  transform: translateY(-30px);
                  color: #999;
                }
              }

              &-label {
                position: absolute;
                top: 10px;
                left: 0;
                padding: 0 5px;
                transition: 0.3s;
                color: #999;
                pointer-events: none;
              }
            }
          }

          .radioSide-label {
            margin-right: ($gutter * 3);

            &::before {
              content: " ";
              display: inline-block;
              position: relative;
              top: 4px;
              right: 2px;
              width: 17px;
              height: 17px;
              border-radius: 11px;
              border: 1px solid $primary-color-2;
              background-color: $secondary-color-2;
            }
          }

          input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: $secondary-color-2;
            display: none;

            &:checked + label::after {
              border-radius: 11px;
              width: 7px;
              height: 7px;
              position: absolute;
              top: 11px;
              left: 6px;
              content: " ";
              display: block;
              background: white;
            }

            &:checked + label::before {
              border: 5px solid;
            }
          }

          @include respond-to-max-width(820px) {
            flex-direction: column;
            align-items: center;

            .personalization {
              &-name-form,
              &-number-form {
                max-width: unset;
                width: 100%;
              }

              &-number-form {
                #numberPersonalization {
                  margin-top: 20px;
                }
              }
            }
          }
        }

        .sidePresonalization-title {
          margin: 0;
          padding: 0;
        }

        .summaryContainer {
          .titleSummary {
            max-width: 400px;
            font-size: 1em;
            border-bottom: $gutter solid $red;
            margin: auto auto ($gutter * 4);
            text-align: center;
            font-weight: bold;
            padding-bottom: ($gutter * 4);
            pointer-events: none;
          }
        }

        i.fa-check,
        i.fa-times {
          &::before {
            font-family: "FontAwesome";
          }
        }

        .nav-link {
          text-transform: capitalize;
        }

        .container-double-tab {
          margin: auto;
        }

        .personalization-number-form,
        .personalization-name-form,
        .shared-width {
          max-width: $inputWidth;
          width: $inputWidth;
        }

        #personalization-inputs-collapsibles {
          display: flex;
          justify-content: space-between;
        }

        .priceList {
          display: flex;
          justify-content: space-between;
          padding: 20px 0;

          .price-dots {
            border-bottom: 2px dotted $borderColor;
            flex: 1;
            padding: $gutter;
            height: 18px;
          }
        }

        .totalPrice,
        .personalization-reset-btn {
          color: $mainFontColor;
        }

        .totalPrice {
          font-weight: bold;
        }

        .personalization-reset-btn {
          font-size: $fontSize;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .text-inputs-personalization {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to-max-width(820px) {
        flex-direction: column;

        .font-container {
          width: 100%;
        }

        .colorSlider-container {
          width: 100% !important;
        }
      }
    }

    .color-container,
    .font-container {
      margin: ($gutter * 5) 0 3.5em;

      .color-inner-container {
        max-height: 50px;
      }
    }

    .carousel-personalization {
      height: 30px;
      max-width: 30px;
      min-width: 30px;
      margin: 0 5px;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
      }
    }

    .colorTitle-container {
      display: flex;

      .colorNameTitle {
        margin-left: $gutter;
        text-transform: capitalize;
        font-weight: bold;
      }
    }

    .font {
      &-container {
        width: $inputWidth;
      }

      &-inner-container {
        &::after {
          font-family: FontAwesome;
          content: "\F054";
          color: #000;
          font-size: 1rem;
          transform: rotate(90deg);
          display: block;
          position: relative;
          bottom: 18px;
          margin: auto;
          height: -webkit-max-content;
          height: max-content;
          height: 0;
          pointer-events: none;
          float: right;
        }
      }

      &-selector {
        width: 100%;
        height: 40px;
        cursor: pointer;
        border-radius: $gutter;
        padding-left: ($gutter * 3);
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;
      }
    }

    .slickhere {
      max-width: 92%;
      margin: auto;

      .white-color-background {
        min-width: 40px;
        max-width: 40px;
        padding-left: $gutter;
      }

      .slick-dots {
        justify-content: center;
        display: flex;
        top: 45px;

        li {
          button::before {
            color: $warningMsg;
            opacity: 1;
            font-size: 8px;
          }
        }

        .slick-active {
          button::before {
            font-size: 10px;
            color: $mainFontColor;
          }
        }
      }
    }

    .slick {
      &-list.draggable {
        width: 100%;
      }

      &-next,
      &-prev {
        &::before {
          font-family: "FontAwesome";
          display: block;
          color: $black;
          font-size: 1rem;
          line-height: 0;
        }
      }

      &-next::before {
        content: "\f054";
        margin-left: ($gutter * 3);
      }

      &-prev::before {
        content: "\f053";
        margin-right: ($gutter * 3);
      }
    }
  }

  &-content.personalization {
    * {
      font-family: $main-font;
    }

    .warning {
      &-container {
        display: flex;

        @include respond-to-max-width(820px) {
          display: block;

          p {
            margin: 0;
          }
        }
      }

      &-message {
        padding: ($gutter * 3) 0 0 $bigGutter;
        color: $warningMsg;
        font-size: $fontSize;
        padding-right: $bigGutter;
        display: flex;

        .fa-exclamation-circle {
          font-family: "FontAwesome";
          content: "\f06a";
          color: $warningColor;
          margin-right: $gutter;
          transform: rotate(0.5turn);
          font-size: 1rem;
        }
      }
    }
  }

  &-header.custom-header {
    height: 50px;
    background-color: $white;
    border-bottom: 1px solid $borderColor;
    align-items: center;
    padding: 1rem;

    .close {
      font-size: unset;

      span {
        font-size: 1.8em;
        margin: 0;
        color: #999;
        display: flex;

        img.close-img {
          width: 16px;
        }
      }
    }
  }

  &-title.custom-header-title {
    font-size: 1.125em;
    font-weight: bold;
  }
}

.white-color-background {
  max-width: 40px;
  margin-top: 5px;
}

.selected-shadow {
  box-shadow: 0 0 0 3px $white, 0 0 0 5px $selectedBlue;
}

.product-detail {
  #twinTabs,
  #unitedTabs {
    display: flex;
    justify-content: center;
    width: 730px;
  }

  .modal {
    &-header.custom-header {
      background-color: #fff;
      border-bottom: 1px solid #cacaca;
      height: 60px;

      .close span {
        font-size: 1.5rem;
        margin: 0;
      }
    }

    &-dialog.custom-modal .modal-body hr {
      pointer-events: none;
      margin: 1.5em 0;
    }
  }

  .product-personalization-option {
    .inputs-container {
      margin-bottom: 20px;

      input.is-valid {
        border-bottom: 1px solid #27ae60;
        border-top: none;
        border-left: none;

        & ~ span span.input-group-text {
          border-bottom: 1px solid #27ae60;
          border-top: none;
          border-right: none;
        }
      }

      .invalid-feedback {
        color: #999;

        .option-cost {
          font-weight: 700;
          color: #000;
        }
      }
    }

    .product-personalization-header {
      margin: 0;
      padding: 0;
    }

    .form-control.is-valid ~ span span.input-group-text {
      border-top: none;
      border-right: none;
    }
  }

  .orders-tabs.tab-content {
    min-width: unset;
    min-height: unset;
  }

  .colorSlider-container {
    width: 350px;
  }

  #unitedTabs ul.my-orders__tabs,
  #twinTabs ul.my-orders__tabs {
    justify-content: center;
    width: 400px;

    li {
      font-size: $fontSize;
      margin: unset;
      cursor: pointer;
      width: unset;
      height: unset;
      display: unset;
      -ms-flex-pack: unset;
      justify-content: unset;
      -ms-flex-align: unset;
      align-items: unset;

      &.nav-item.twinTabs {
        width: 50%;
      }

      &.nav-item.unitedTabs {
        width: 100%;
      }
    }
  }

  .attributes .personalizationList {
    margin: 0;
    padding: 0;

    .personalizationSummaryList-item {
      font-size: $fontSize;
      margin: 0;
      padding: 0;
      width: 100%;
      background: white;
      display: flex;
      justify-content: flex-start;

      .fontSummary,
      .fontSummary-PDP {
        margin: 0;
        font-weight: bold;
        padding-left: $gutter;
      }

      &.priceless-line {
        text-align: left;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .colorSummary,
      .colorSummary-PDP {
        padding-left: ($gutter * 2);
      }

      .colorSummaryBackground,
      .colorSummaryBackground-PDP {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 0;
        border: 1px solid;
      }

      .nameSide,
      .numberSide,
      .colorSummary,
      .colorSummary-PDP {
        margin: 0 ($gutter * 3) 0 0;
        font-weight: bold;
        text-transform: capitalize;
      }

      &.totalPrice,
      .numberPriceSummary,
      .namePriceSummary,
      .finalPriceSummary {
        font-weight: bold;
      }

      &.totalPrice {
        margin-top: 40px;
      }

      .numberPriceSummary::before,
      .namePriceSummary::before,
      .finalPriceSummary::before {
        content: "$";
      }

      .nameSummary,
      .numberSummary {
        margin: 0;
        display: flex;
        font-weight: bold;
        padding-left: $gutter;
        text-transform: uppercase;
      }

      .price-dots {
        border-bottom: 2px dotted $borderColor;
        flex: 1;
        padding: $gutter;
      }
    }
  }

  .addNewPersonalization {
    border: none;
    background: none;
    color: $red;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    font-family: $main-font;
    padding: 0;

    &:disabled {
      color: $disabledButton;
    }
  }

  .summaryContainer-PDP {
    * {
      font-family: $main-font;
    }

    .personalizationSummaryList-item {
      font-size: 1em;
      display: flex;
      width: auto;
      height: auto;
      margin-inline: 0;

      .nameSummary-PDP,
      .numberSummary-PDP {
        margin: 0;
        display: flex;
        font-weight: bold;
        padding-left: $gutter;
        text-transform: uppercase;
      }

      &:last-child {
        margin-bottom: 20px;
      }

      &.totalPrice {
        font-weight: 700;
      }

      > .price-dots:first-child {
        border-bottom: 2px dotted $borderColor;
        flex: 1;
        padding: $gutter;
        height: 18px;
      }

      .numberPriceSummary-PDP::before,
      .namePriceSummary-PDP::before,
      .finalPriceSummary-PDP::before {
        content: "$";
      }

      .finalPriceSummary-PDP {
        font-weight: 700;
        font-size: 1em;

        &::before {
          content: "+$";
        }
      }
    }

    .personalizationList {
      .namePriceSummary-PDP,
      .numberPriceSummary-PDP {
        font-size: 1rem;
        font-weight: 700;

        &::before {
          content: "$";
        }
      }
    }

    .titleSummary {
      &.-PDP {
        cursor: pointer;
        font-size: 1em;
        font-weight: 500;
        color: $mainFontColor;
        margin-top: ($gutter * 2);
        width: fit-content;
        transition: 0.5s ease-in-out;

        &::after {
          content: "\f078";
          font-family: "FontAwesome";
          margin-left: 20px;
        }

        &.-open {
          &::after {
            content: "\f077";
            font-family: "FontAwesome";
          }
        }
      }
    }

    .personalization-reset-btn.-PDP {
      display: block;
      margin-top: 20px;
      color: $red;
      text-decoration: underline;
      cursor: pointer;
      text-transform: capitalize;
      font-size: $fontSize;
      width: fit-content;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
