#checkout-main {
  .next-step-button {
    padding-left: 7px;
    padding-right: 7px;

    .btn {
      &-primary {
        background-color: #121212;
        border: none;
        border-radius: 25px;
        font-size: 15px;
        height: 50px;
        font-weight: bold;
        font-family: $main-font;
      }
    }
  }

  .customer-information-block {
    margin-top: 0.5rem;
  }

  .shipping-address-row-item,
  .billing-address-row-item,
  .credit-card-form-row-item {
    margin-top: 1rem;
  }

  .form {
    &-group {
      margin-bottom: 1.5rem;

      &.input-cp-modal-container,
      &.zipCode-modal-container {
        margin-bottom: 0;
      }

      .shippingState {
        margin-top: 1rem;
      }

      .input-cp-modal,
      .store-zipCode-pickIt {
        padding: 0.375rem 0.75rem;
      }

      .placeholder-label {
        font-size: 14px;

        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }

        &.card-owner-label{
          @media screen and (max-width: 410px) {
            font-size: 13px;
          }
        }
      }
    }

    &-control {
      border: none;
      border-radius: 0;
      border-bottom: solid 1px #999;
      font-size: 16px;
      font-weight: 400;
      padding-block: 0;
      height: 44px;
      line-height: 44px;
      color: #000;

      &::placeholder {
        color: $gray-accent;
      }

      &:-webkit-autofill,
      &:focus,
      &:not(:placeholder-shown) {
        + .placeholder-label {
          transform: translateY(-120%) scale(1);
          color: $gray-accent;
        }
      }

      &:not(:placeholder-shown) {
        + .state-shipping-select-label {
          transform: translateY(-50%) scale(1);
          color: $gray-accent;
        }
      }

      &.state-id-select-correoarg {
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;
        height: calc(1.5em + 0.75rem + 2px);
        color: #000;
        padding: 0.375rem 0.75rem;
      }

      &.addressSelector {
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;

        &:focus {
          border: 1px solid #cacaca;
        }
      }

      &.saved-payment-security-code {
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;

        &:focus {
          color: #495057;
          background-color: #fff;
          border-color: #61d2ff;
        }
      }

      &-label {
        position: absolute;
        font-size: 16px;
        color: #999;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: 0.3s;
        transform-origin: top left;
      }

      &:-internal-autofill-selected {
        -webkit-background-clip: text;
        background-clip: text;
      }

      &::placeholder {
        color: transparent;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        font-family: $main-font;
      }

      &:focus {
        border-color: #000;
        box-shadow: none;
      }
    }

    select {
      + .placeholder-label {
        transform: translateY(-130%) scale(1);
        transform-origin: left;
        color: $gray-accent;
      }
    }
  }
}

.hide-shipping-discount {
  display: none;
}

.ship-to {
  &-name,
  &-address1,
  &-address2,
  &-phone,
  &-city-st-zip {
    display: block;
    font-family: $main-font;
  }
}
