.position-360 {
  margin-left: 5%;
}

.img-360 {
  background-image: url(../images/img-360.png);
  position: absolute;
  width: 12% !important;
  height: 10% !important;
  margin: 2%;
  z-index: 1010;
}

#mySpriteSpin {
  width: 100% !important;
}
