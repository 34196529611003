$bg-color: #ed3237;

.header.container {
  max-width: 100% !important;
  background-color: $bg-color;
}

//RESPONSIVITY FOR HEADER MOBILE RESOLUTION LOWER THAN 768PX

@media (max-width: 768px){
  .desktop-header{
    display: none !important;
  }
}

@media (min-width: 768px){
  .mobile-header{
    display: none !important;

    .mobile-search{
      width: 100% !important;
    }
  }
}

.col-sm-3 .brand {
  left: 0 !important;
  margin-left: 0 !important;
}

.menu-mobile{
  .navbar-toggler{
    font-size: 1.6em;
    width: auto;
    color: white;
    margin-bottom: 7px;
  }
}
//CSS TO ADAPT MOBILE AND TABLET HEADERS WITH THE NEW MINICART LAYOUT
.col-sm-3.hidden-xs-down{
  .navbar-header.brand{
    .logo-home{
      @media (max-width: 769px){
        display: none !important;
      }
    }
  }
}

// @import "nav-header";
