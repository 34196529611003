$csh-main-font-size-desktop: 30px;
$csh-main-font-size-mobile: 24px;
$csh-subheader-font-size-desktop: 14px;

[data-checkout-stage-id="shipping"] {
  display: block;
}

[data-checkout-stage-id="payment"],
[data-checkout-stage-id="placeOrder"] {
  display: none;
}

.checkout-step {
  &-header {
    &-container {
      font-family: $main-font;

      @include respond-to-max-width(991px) {
        padding: 0 20px;
      }
    }

    &-main {
      font-size: $csh-main-font-size-desktop;
      font-weight: $font-weight-medium;
      color: black;
      margin-top: 30px;
      margin-bottom: 20px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $csh-main-font-size-mobile;
        margin-top: 13px;
        margin-bottom: 13px;
      }
    }

    &-subheader {
      &-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 40px;
        border-bottom: 1px solid #cacaca;
        padding-bottom: 10px;

        @include respond-to-max-width($max-mobile-width) {
          margin-bottom: 26px;
        }
      }

      &-main {
        font-size: $csh-subheader-font-size-desktop;
        font-weight: $font-weight-medium;
        color: $primary-color-2;
      }

      &-phone-container {
        color: black;
        font-size: $csh-subheader-font-size-desktop;

        .need-help {
          font-weight: $font-weight-regular;
        }

        .phone-number {
          font-weight: $font-weight-medium;
        }

        @include respond-to-max-width($max-mobile-width) {
          display: none;
        }
      }
    }
  }

  &-container {
    margin: auto;

    .error-message {
      .error-message-title {
        font-weight: bold;
      }
    }

    .recommendations {
      display: none;
    }

    @include respond-to-max-width($max-mobile-width) {
      .next-step-button {
        position: initial;
        box-shadow: none;

        .mb-sm-3 {
          padding: 0;

          button {
            height: 50px;
          }

          .submit-payment {
            margin-top: 0;
          }

          .place-order-button-container {
            padding: 0 0 15px;
          }
        }
      }
    }

    .privacy-policy-checkout {
      display: flex;
      justify-content: center;
      width: 85%;

      &.custom-checkbox {
        margin-bottom: 25px;
        margin-top: 15px;
        text-align: center;
        max-width: 100%;

        .custom-control-input:checked ~ .custom-control-label::after {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
          background-color: $primary-color-2;
        }

        label {
          padding-top: 0;

          &::before,
          &::after {
            @include respond-to-min-width($max-mobile-width) {
              left: 5px;
            }
          }

          &::after {
            cursor: pointer;
            background-color: white;
            border: 1px solid black;
            border-radius: 3px;
          }

          @include respond-to-min-width($max-mobile-width) {
            padding-left: 25px;
          }
        }

        &.privacy-policy-checkout {
          a {
            font-weight: 500;
            color: #222;
            text-decoration: underline;
          }

          label {
            color: black;
            text-wrap: nowrap;

            @include media-breakpoint-down(sm) {
              text-wrap: wrap;
            }

            #reset-pass-label {
              color: #999;
              position: relative;
              top: 40px;
              transition: all 0.3s ease;

              &.focused {
                top: 0;
              }
            }
          }
        }

        @include respond-to-min-width($max-mobile-width) {
          padding-left: 0;
        }
      }
    }

    @include respond-to-max-width(991px) {
      flex-direction: column-reverse;

      .upper-container,
      .lower-container {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

[data-checkout-stage="customer"] {
  .checkout-step {
    &-container {
      @include respond-to-max-width(991px) {
        flex-direction: column;
      }
    }
  }
}

.data-checkout-stage[data-checkout-stage=payment] .customer-summary {
  display: none;
}

.upper-container {
  .col-12.next-step-button div {
    padding: 0;
  }
}
