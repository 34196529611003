.main_lottery {
  .component-image {
    width: 100%;
  }

  .success-messaging {
    position: absolute;
    z-index: 10001;
    top: 27%;
    left: 45%;
    max-width: 50%;
  }

  .error-messaging {
    position: absolute;
    z-index: 10001;
    top: 23%;
    left: 40%;
    max-width: 24%;
  }
}

.required-asterisk {
  color: #999;
}

.form__lottery {
  margin: 15px auto auto;

  form {
    label {
      font-size: 15px;
      font-family: $main-font;
      margin-left: 0;
    }

    .invalid-feedback {
      color: red;
      margin-left: 0;
    }

    input {
      border-color: $black-text;
      color: $primary-color-2;
      font-size: 16px;
      font-weight: 400;
      font-family: $main-font;
      border: none;
      border-bottom: solid 1px;
    }

    select {
      border-color: $black-text;
      font-size: 16px;
      font-weight: 400;
      font-family: $main-font;
      color: $primary-color-2;
      border-radius: 3px;
      border: none;
      border-bottom: solid 1px;
      -webkit-appearance: none;
      appearance: none;
      background-position: right 50%;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);

      &:invalid {
        color: #999;
      }
    }

    .lottery__btn {
      font-size: 14px;
      height: 50px;
      background-color: $secondary-color-1;
      color: white;
      font-family: $main-font;
      border: none;
      border-radius: 25px;
      margin: 20px auto 0;
      text-transform: uppercase;
    }

    @import "../forms/_form-group.scss";

    .form-group {
      position: relative;
    }
  }

  .form-control {
    margin: 0 auto;
  }

  .checkbox {
    &-lottery {
      width: 1rem !important;
      margin: 0;

      @include respond-to-max-width($max-mobile-width) {
        margin-left: 0;
      }
    }

    &-text {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 15px;

      p {
        margin-bottom: 0;

        a {
          color: #0070d2 !important;

          &:hover{
            text-decoration: underline;
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        width: 100%;
      }
    }

    &-container {
      display: flex;
      height: 2.5rem;
      gap: 8px;
      align-items: center;

      @include respond-to-max-width($max-mobile-width) {
        height: auto;
      }

      .invalid-feedback {
        display: block;
        margin: 0;
        position: absolute;
        top: 80%;
        max-width: 100%;
        line-height: 1;
        font-size: 12px;

        @include respond-to-max-width($max-mobile-width) {
          bottom: 0;
        }
      }
    }
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
}

.title__lottery {
  font-size: 30px;
  font-weight: 500;
  font-family: $main-font;
  color: #000;
  margin-bottom: 0;
  text-align: center;
}

.card {
  margin-top: 1em;
}
