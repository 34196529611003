.card-body.content.value {
  max-height: 150px;
  overflow-y: auto;
  scrollbar-color: rgb(175, 175, 175) rgb(251, 251, 251);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(75deg, #cfcfcf 14%, #d1d1d1 64%);
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, #ccc 5%, #d6d6d6 74%);
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 9px;
    box-shadow: inset 7px -4px 12px #f0f0f0;
  }

  li {
    &.col-md-3 {
      padding-right: 5px;
    }
  }
}

.search-results {
  .refinements {
    .card {
      .card-body {
        border-bottom: 1px solid #f3f2f2;
      }
    }
  }
}
