.login-page {
  &.wishlist {
    .wishlist-breadcrumbs {
      display: none;
    }

    .subtitle {
      text-align: center;
      max-width: 372px;
      margin: 30px auto;
    }
  }
}
