
#sizeChart {
  .modal {
    &-title {
      color: $primary-color-2;
      font-weight: 500;
      font-size: 20px;
      margin: 0;
      font-family: $main-font;
    }

    &-body {
      table {
        text-align: center;

        thead {
          th {
            font-weight: $font-weight-regular;
            background-color: $primary-color-2;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $primary-color-2;
        font-family: $main-font;

        &.title {
          font-weight: bold;
        }
      }
    }

    &-dialog {
      max-width: 560px;
    }
  }
}

header ~ #maincontent .container.product-detail div.attributes ul div.size-chart a.guide-link {
  color: $custom-link-color;
  cursor: pointer;
}
