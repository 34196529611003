$empty-text-font-size: 30px;
$empty-text-font-size-mobile: 24px;

.cart-empty {
  margin-top: 100px;
  margin-bottom: 200px;

  &-text {
    font-family: $main-font;
    font-size: $empty-text-font-size;
    font-weight: $font-weight-medium;
    color: $black-text;
    margin-top: 20px;

    @include respond-to-max-width($max-mobile-width) {
      margin-top: 13px;
      font-size: $empty-text-font-size-mobile;
    }
  }

  i {
    color: $primary-color-2;
  }

  @include respond-to-max-width($max-mobile-width) {
    margin-top: 95px;
    margin-bottom: 240px;
  }
}
