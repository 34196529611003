
.promotion-information {
  display: none;
}

.form-group {
  .zip {
    &-code {
      font-size: 16px;
      color: $black-text;
      font-weight: $font-weight-medium;

      &-col {
        padding-right: 0;
      }

      &-field {
        border: 1px solid $primary-color-2;
        border-radius: 0.1875rem 0 0 0.1875rem;
      }

      &-submit {
        padding-left: 0;
      }

      &-btn {
        background-color: $primary-color-2;
        border-color: $primary-color-2;
        font-size: 15px;
        font-weight: $font-weight-bold;
        height: 38px;
        border-radius: 0 0.1875rem 0.1875rem 0;
      }
    }

    &-error {
      color: $danger;
      margin-top: 0.25rem;
    }

    &-missing-error {
      display: none;
    }
  }

  .normal-shipping,
  .normal-shipping-cost {
    font-size: 14px;
  }

  .end-lines {
    .normal-shipping,
    .normal-shipping-cost {
      text-align: right;
    }
  }
}
