.home-email-signup {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  background-color: $secondary-color-2;
  border-radius: 5px;
  padding: 30px;
  position: relative;
  border: none;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;
  height: unset;

  .row {
    justify-content: center;

    .col-sm-12 {
      color: black;

      .newsletter-title {
        font-size: 24px;
        color: $primary-color-2;
        text-align: center;
        margin-bottom: 0;
        font-weight: bold;
        font-family: $main-font;

        @include respond-to-max-width($max-mobile-width) {
          font-size: 16px;
        }
      }

      .input-group {
        width: 55%;
        margin: 10px auto 0;

        @include respond-to-max-width($max-mobile-width) {
          width: 100%;
        }

        input.form-control {
          height: 40px;
          border: 1px solid $light-black;
          border-radius: 3px 0 0 3px;
          color: $black-text;

          &::placeholder {
            color: #999;
            font-size: 16px;
            font-weight: 400;

            @include respond-to-max-width($max-mobile-width) {
              font-size: 11px;
            }
          }
        }

        > .input-group-append > .btn {
          background-color: #121212;
          height: 50px;
          text-transform: uppercase;
          padding-left: 20px;
          padding-right: 20px;
          border: none;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0.5px;
          border-radius: 25px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 13px;
            width: 100px;
            padding: 0;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }

      .invalid__email-home {
        color: red;
        font-size: 13px;
        margin-top: 8px;
        margin-left: 22.5%;

        @include respond-to-max-width($max-mobile-width) {
          margin-left: 1.5%;
        }
      }

      .newsletter-disclaimer {
        text-align: center;
        color: $primary-color-2;
        font-size: 18px;
        margin: 10px auto;
        font-family: $main-font;
        width: 100%;

        @include respond-to-max-width($max-mobile-width) {
          width: 100%;
          font-size: 12px;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        padding: 0;
      }
    }

    .col-12 .input-group-contact input.form-control.form-control-custom {
      border: none;
      border-bottom: 1px solid $newsletter-input-border-color;
      border-color: $newsletter-input-border-color;
      border-radius: 0;
      box-shadow: 0 0 0 1000px #f8f8f8 inset;
      -webkit-box-shadow: 0 0 0 1000px #f8f8f8 inset;
      background: none;

      &::placeholder {
        color: $newsletter-input-border-color;
      }

      &:focus {
        border-color: $newsletter-input-border-color;
      }

      &:focus + .placeholder-label,
      &:not(:placeholder-shown) + .placeholder-label {
        transform: translateY(-30px);
        color: $newsletter-input-label-color;
      }
    }
  }

  .col-12.col-sm-6 {
    padding: 0 6px;
  }

  .row-disclaimer .col-sm-12 {
    margin-bottom: 40px;
  }

  @include respond-to-max-width(575px) {
    .input-phone-container {
      margin-top: 40px;
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 25px;
    margin-right: 25px;
  }
}

input[type=number].form-control.form-control-custom {
  text-align: left;
}

.page-designer-reference #NewsLetter-btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #121212;
  border: none;
}
