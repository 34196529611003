
.grey-region {
  background: $light-gray;
  width: 100vw;
  max-width: unset;
  overflow-x: hidden;
  margin-left: calc((100% - 100vw) / 2);

  &.center-child-content > div {
    margin: auto !important;

    @include respond-to-min-width(576px) {
      max-width: 540px;
    }

    @include respond-to-min-width($max-mobile-width) {
      max-width: 720px;
    }

    @include respond-to-min-width(992px) {
      max-width: 960px;
    }

    @include respond-to-min-width(1200px) {
      max-width: 1140px;
    }
  }
}
