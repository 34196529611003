.rejected-payment {
  &-modal {
    .modal {
      &-header {
        border-bottom: none;
        padding-bottom: 0.3125rem;
        padding-top: 1.25rem;

        .fa-exclamation-circle {
          color: #ed3137;
          font-size: 1.6rem;
        }
      }

      &-body {
        padding-top: 0;
        padding-bottom: 0;
      }

      &-footer {
        border-top: 1px solid #dee2e6;
        padding: 0.5rem;
      }
    }
  }

  &-content {
    color: #9f9f9f;
    text-align: center;
  }

  &-button {
    font-weight: 500;
    color: #187dd6;
    font-size: 0.8rem;
  }
}
