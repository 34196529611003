
$customMobileBreak: 1340px;

.nav-item {
  a {
    outline: none;
    box-shadow: none;

    &::after {
      @include respond-to-min-width($max-mobile-width) {
        display: none;
      }
    }

    &.nav-link {
      font-size: 18px;
      font-family: $main-font;

      @include respond-to-max-width($max-mobile-width) {
        font-family: $main-font;
        padding: 15px 25px;
        border-bottom: 1px solid $light-gray;

        &::after {
          transform: rotate(-90deg);
        }
      }

      &[aria-expanded="true"] {
        @include respond-to-min-width($max-mobile-width) {
          color: $primary-color-1;
        }
      }
    }

    &::after {
      @include respond-to-max-width($max-mobile-width) {
        right: 22px;
        top: 25px;
        position: absolute;
      }
    }
  }

  &.dropdown {
    .menu-items-container {
      .options-container {
        display: flex;
        padding-bottom: 20px;

        div {
          .dropdown-menu {
            width: 100%;

            @include respond-to-min-width($max-mobile-width) {
              display: block;
            }

            .dropdown-item {
              padding: 0;

              @include respond-to-max-width($customMobileBreak) {
                font-family: $main-font;
                padding: 0;
                border-bottom: 1px solid $border-bottom-color;

                &.single {
                  padding: 15px 25px;
                }

                .dropdown-item {
                  padding: 15px 25px;
                }

                .dropdown-link {
                  &.dropdown-toggle {
                    padding: 15px 25px;
                  }
                }
              }

              a {
                color: $light-black;
                font-family: $main-font;

                &::after {
                  @include respond-to-min-width($max-mobile-width) {
                    display: none;
                  }
                }

                @include respond-to-min-width($max-mobile-width) {
                  &.items-title {
                    font-weight: bold;
                    font-size: 18px;
                  }

                  &.dropdown-toggle {
                    font-weight: bold;
                  }
                }

                @include respond-to-max-width($max-mobile-width) {
                  &::after {
                    top: auto;
                    transform: initial;
                  }

                  &.items-title {
                    font-weight: bold;
                    font-size: 18px;
                  }

                  &.dropdown-toggle {
                    font-weight: bold;
                  }
                }
              }

              &.dropdown {
                > a {
                  color: $primary-color-2;
                }
              }

              &:hover {
                background-color: transparent;
              }

              .dropdown-link {
                @include respond-to-max-width($max-mobile-width) {
                  font-size: 18px;
                  padding: 0;
                }
              }
            }
          }

          &.banner-container {
            img {
              cursor: pointer;
              width: 100%;
            }
          }
        }

        @include respond-to-min-width($max-mobile-width) {
          padding-top: 20px;
        }
      }

      @include respond-to-min-width(769px) {
        z-index: 9;
        height: auto;
      }
    }
  }
}
