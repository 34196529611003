.page-designer-reference {
  .richtext {
    max-width: 800px;
    margin-inline: auto;

    p {
      font-family: $main-font;
    }
  }

  .experience-layouts-4columnLandscape {
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 800px;

    .fit-content {
      font-family: $main-font;

      h3 {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: $font-weight-medium;
        color: black;
        font-family: $main-font;
      }

      .banner {
        min-height: 50px;
        background-color: $primary-color-1;
        color: white;
        text-align: center;
        border: 1px solid $regular-light-gray;
        border-radius: 3px;
        margin: 10px auto 15px;

        p {
          font-size: 18px;
          padding-top: 12px;
          font-family: $main-font;

          @include respond-to-max-width($max-mobile-width) {
            line-height: 25px;
          }
        }
      }

      .experience-commerce_assets-paymentMethod {
        .paymentMethod-component-container {
          .img {
            &-container {
              max-width: 180px;
            }

            &-text {
              text-align: center;
              font-family: $main-font;
            }
          }

          .modal {
            &-title {
              p {
                margin-bottom: 0;
                font-family: $main-font;
              }
            }

            &-body {
              img {
                max-width: 180px;
              }

              p {
                padding-left: 25px;
                padding-right: 25px;
                font-family: $main-font;
              }
            }
          }
        }
      }
    }

    @include respond-to-min-width($max-mobile-width) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
