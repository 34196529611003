// Confirmation page
$confirmation-title-font-size-desktop: 30px;
$confirmation-title-font-size-mobile: 24px;

$sub-header-font-size: 14px;

// Thank you for your order
$thank-you-title-font-size-desktop: 31px;
$thank-you-title-font-size-mobile: 16px;
$order-email-font-size-desktop: 16px;
$order-email-font-size-mobile: 14px;

// PagoFacil button
$pago-facil-button-font-size: 15px;
$pago-facil-button-height: 50px;

.checkout-confirmation-page {
  .hero-confirmation {
    display: none;
  }

  .receipt {
    padding-top: 30px;

    .order-thank-you-msg {
      color: black;
      font-family: $main-font;
      font-weight: $font-weight-medium;
      font-size: $confirmation-title-font-size-desktop;
      margin-bottom: 15px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $confirmation-title-font-size-mobile;
        margin-bottom: 13px;
      }
    }

    .confirmation-subheader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $main-font;
      font-size: $sub-header-font-size;
      border-bottom: 1px solid #cacaca;
      padding-bottom: 10px;

      .order-confirmed-msg {
        font-weight: $font-weight-medium;
        color: $primary-color-2;
      }

      .help-msg {
        font-weight: $font-weight-regular;
        color: black;

        @include respond-to-max-width($max-mobile-width) {
          display: none;
        }
      }

      .help-phone-number {
        color: black;
        font-weight: $font-weight-medium;

        @include respond-to-max-width($max-mobile-width) {
          display: none;
        }
      }
    }

    .custom-checkbox {
      margin-bottom: 10px;
      margin-top: 10px;

      .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
        background-color: $primary-color-2;
      }

      label {
        padding-top: 2px;

        &::before,
        &::after {
          @include respond-to-min-width($max-mobile-width) {
            left: 5px;
          }
        }

        &::after {
          cursor: pointer;
          background-color: white;
          border: 1px solid black;
          border-radius: 3px;
        }

        @include respond-to-min-width($max-mobile-width) {
          padding-left: 25px;
        }
      }

      &.privacy-policy {
        a {
          font-weight: $font-weight-medium;
          color: $black-text;
          text-decoration: underline;
        }
      }

      @include respond-to-min-width($max-mobile-width) {
        padding-left: 0;
      }
    }

    .confirmation-details-container {
      padding: 2.8125rem 6rem;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      &-guest-form {
        padding: 0 6rem 2.8125rem;
      }

      .thanks-for-your-order-container {
        font-family: $main-font;
        width: 40%;
        padding: 0 6px;

        .thanks-for-your-order-title {
          font-size: $thank-you-title-font-size-desktop;
          font-weight: $font-weight-medium;
          color: $black-text;
          margin-bottom: 15px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: $thank-you-title-font-size-mobile;
            margin-bottom: 13px;
          }
        }

        .order-confirmation-mail-container {
          font-size: $order-email-font-size-desktop;
          font-weight: $font-weight-regular;
          color: $black-text;
          margin: 15px 0 30px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: $order-email-font-size-mobile;
            margin: 13px 0;
          }

          .order-email {
            font-weight: $font-weight-medium;
          }
        }

        .pagofacil-cupon-button {
          color: white;
          background: $primary-color-2 0% 0% no-repeat padding-box;
          border-radius: 3px;
          font-size: $pago-facil-button-font-size;
          width: 100%;
          padding: 15px 0;
          font-family: $main-font;
          font-weight: bold;
          line-height: normal;
          height: $pago-facil-button-height;
          border: unset;
          margin-top: 30px;

          .fa-file {
            margin-right: 10px;
          }

          @include respond-to-max-width($max-mobile-width) {
            margin-bottom: 13px;
            margin-top: 13px;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          width: 100%;
          padding: 0;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        padding: 25px 6px;
        flex-flow: wrap;
      }

      @import "./confirmationDetails";
    }

    .information-guest-account-creation {
      padding: 3rem 6.8rem 0 6.4rem;

      @include media-breakpoint-down(sm) {
        padding: 3rem 0.5rem 0;
      }

      h2 {
        font-size: 1rem;
        font-family: 'Roboto';
        font-weight: bold;
      }
    }

    .success-guest-registration {
      text-align: center;
      padding-top: 4rem;
      padding-bottom: 2rem;

      .fa-check-circle-o-cv {
        font-size: 4rem;
        color: #559550;
      }

      &-msg1 {
        font-family: $robotoFont;
        margin-top: 10px;
        font-weight: bold;
        font-size: 18px;
        color: #559550;
      }

      &-msg2 {
        font-size: 16px;
      }
    }

    .confirmation-guest-password-container {
      width: 100%;
      display: flex;
      padding: 0 6.8rem 0 6.4rem;

      @include media-breakpoint-down(sm) {
        padding: 0 0.5rem;
      }

      .order-confirm-create-account {
        width: 60%;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        .form-group {
          .form-control-custom {
            border: none;
            border-bottom: 1px solid $gray-accent;
            border-color: $gray-accent;
            border-radius: 0;
            -webkit-box-shadow: 0 0 0 1000px white inset;
            box-shadow: 0 0 0 1000px white inset;

            &::placeholder {
              color: $gray-accent;
            }

            &:-webkit-autofill + .placeholder-label {
              position: absolute;
              top: 10px;
              left: 0;
              padding: 0 5px;
              transition: 0.3s;
              color: $gray-accent;
              transform: translateY(-30px);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              zoom: 1.1666;
              font-family: Roboto, sans-serif;
            }

            &:-webkit-autofill::first-line {
              font-size: 0.8333rem;
              line-height: 1.5;
              font-family: Roboto, sans-serif;
            }

            &:-internal-autofill-selected {
              -webkit-background-clip: text;
              background-clip: text;
            }

            &:focus + .placeholder-label,
            &:not(:placeholder-shown) + .placeholder-label {
              transform: translateY(-30px);
              color: $gray-accent;
            }
          }

          &-email {
            position: relative;
            margin-top: 50px;
          }

          &-name,
          &-lastname,
          &-emailr,
          &-email-confirm {
            position: relative;
            margin-top: 30px;
          }

          &-password,
          &-confirmpassword,
          &-dni,
          &-phone {
            position: relative;
            margin-top: 40px;
          }

          @include respond-to-max-width(604px) {
            &-lastname,
            &-email-confirm {
              position: relative;
              margin-top: 40px;
            }

            &-dni,
            &-phone,
            &-confirmpassword {
              position: relative;
              margin-top: 30px;
            }
          }
        }
      }

      .btn-primary {
        background-color: #121212;
        border-color: #121212;
        font-family: $main-font;
        font-weight: bold;
        height: 50px;
        padding: 15px;
        border-radius: 25px;
      }
    }
  }
}

.page.checkout-layout {
  background-color: $background;
}
