[data-action="Address-EditAddress"] {
  .breadcrumb {
    .wishlist-breadcrumbs {
      padding-left: 0;
    }

    &-container {
      @include respond-to-max-width(991px) {
        display: block;
      }
    }
  }
}

[data-action="Account-EditProfile"] {
  .edit-account-buttons {
    align-items: center;
    font-family: $main-font;
  }
}

.address-book-header {
  .breadcrumb.wishlist-breadcrumbs {
    width: 100vw;
    padding: 0 25px;
    font-family: $main-font;
  }
}

.card {
  &-header {
    &.edit-add-address {
      background-color: #f9f9f9;
      color: $carousel-indicator-active;
      font-family: $main-font;

      h3 {
        font-family: $main-font;
        font-size: 20px;
        text-align: left;
      }

      h2 {
        font-family: $main-font;
      }
    }
  }

  &-body {
    .form-group.required {
      .form-control-label {
        &::before {
          color: #999;
        }
      }
    }
  }
}

.edit-address {
  &-cancel,
  &-save {
    border: none;
    border-radius: 25px;
    color: $background;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $main-font;
    height: 50px;
    padding: 12px;
  }

  &-cancel {
    background-color: $black-text;

    &:hover,
    &:active {
      background-color: $black-text !important;
    }
  }

  &-save {
    background-color: $secondary-color-1;

    &:hover,
    &:active,
    &:disabled {
      background-color: $secondary-color-1 !important;
    }
  }

  &-form {
    width: 95%;
    display: block;
    margin: auto;

    .row {
      align-items: baseline;

      .birth-edit {
        font-size: 14px;
        margin-bottom: 0;
        color: #999;

        .required-asterisk {
          color: #999;
        }
      }
    }

    .col-4 .form-group,
    .form-group .form-group {
      padding-top: 0;
    }

    input[type="text"],
    select,
    .form-control {
      border: none;
      border-bottom: solid 1px #999;
      border-radius: 0;
      font-size: 14px;
    }

    .form {
      &-group:not(.birth-edit) {
        position: relative;
        padding-top: 30px;
      }

      &-control {
        &-label {
          &:has(~ input.form-control:focus),
          &:has(~ input.form-control:valid),
          &:has(~ input.form-control.is-valid),
          &:has(~ input.form-control:-webkit-autofill),
          &:has(~ input.form-control[readonly]),
          &:has(~ select.form-control) {
            transform: translateY(-30px);
            color: #999;
          }

          &:has(~ .form-control) {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 5px;
            transition: 0.3s;
            color: #999;
            pointer-events: none;
            z-index: 30;
            font-size: 14px;
          }
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:has(~ .form-control-label) {
          margin-block: 20px;
        }
      }
    }

    @include respond-to-min-width($max-mobile-width) {
      width: 80%;
    }
  }

  &-title {
    border-bottom: 1px solid #cacaca;
    color: $black-text;
    font-size: 30px;
    font-weight: $font-weight-medium;
    margin: 0 0 30px;
    padding-bottom: 10px;
    position: relative;

    @include respond-to-min-width(1342px) {
      margin: 0 24px 30px;
    }

    @include respond-to-between(545px, 1341px) {
      margin: 0 37px 30px;
    }
  }
}

.edit-address-container {
  max-width: 100%;

  @include respond-to-max-width($max-mobile-titles-width) {
    padding: 0 20px;
  }
}
