div[data-action="Account-Show"] {
  font-family: $main-font;

  .breadcrumb-container {
    padding-top: 30px;

    ol {
      border: none;
      font-size: $breadcrumbs-font-size;
      background-color: transparent;

      li.breadcrumb-item {
        a:not(.btn-primary):not(.btn-outline-primary) {
          color: $breadcrumbs-color;

          &[aria-current="page"] {
            color: $last-breadcrumb-color;
          }
        }
      }
    }
  }

  .account-dashboard-container {
    padding: 0 20px;
  }

  .card {
    font-family: $main-font;

    &.payment-card {
      .card {
        &-image-container {
          margin: 0;
          display: flex;
          font-family: $main-font;
          align-items: center;

          @include respond-to-max-width($max-mobile-width) {
            img {
              height: 50px;
            }
          }
        }

        &-info-group p {
          margin-bottom: 15px;
        }

        &-name {
          font-weight: $font-weight-medium;
          font-size: 16px;
          font-family: $main-font;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 14px;
          }
        }
      }
    }

    &-header {
      background-color: $light-gray;
      font-family: $main-font;

      h2 {
        font-size: 20px;
        font-family: $main-font;

        @include respond-to-max-width($max-mobile-width) {
          font-size: 16px;
        }
      }
    }

    &-body {
      font-family: $main-font;

      .dashboard-cards-block-title,
      .recent-title {
        font-weight: $font-weight-medium;
        font-size: 16px;
        margin-bottom: 5px;
        font-family: $main-font;

        @include respond-to-max-width($max-mobile-width) {
          font-size: 14px;
        }
      }

      .info {
        font-size: 14px;
        line-height: 24px;
        font-family: $main-font;
      }

      .price-row {
        margin-top: 10px;
      }
    }

    &-footer {
      display: none;

      .leading-lines {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin: 7px 0;

        .dots-line {
          border-bottom: 1px dashed #aaa;
          -ms-flex-positive: 1;
          flex-grow: 1;
          margin: 6px;
        }

        p {
          font-size: 16px;
          font-family: $main-font;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 14px;
          }
        }
      }
    }

    dt {
      font-size: 14px;
      padding-bottom: 5px;
      font-family: $main-font;
    }

    dd {
      font-size: 16px;
      font-family: $main-font;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 14px;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      &.profile-margin-top-mobile {
        margin-top: 2rem;
      }
    }
  }
}
