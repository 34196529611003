.home-email-signup {
  display: flex;
  align-items: center;
  font-family: $main-font;

  .row {
    justify-content: center;

    .col-sm-12 {
      color: #000;

      .input-group {
        width: 55%;
        margin: auto;

        .form-control {
          border: 2px solid #212121;
          border-right: none;
        }

        & > .input-group-append > .btn {
          background-color: $btn-subscribe;
          border: none;
          border-radius: 2px;
        }

        @include respond-to-max-width($max-mobile-width) {
          width: 100%;
        }
      }

      .newsletter {
        &-title {
          font-size: 24px;
          color: #000;
          text-align: center;
          margin-bottom: 15px;
          font-family: $main-font;
        }

        &-disclaimer {
          color: #707070;
          text-align: center;
          width: 45%;
          font-size: 12px;
          margin: 10px auto;
          font-family: $main-font;

          @include respond-to-max-width($max-mobile-width) {
            width: 100%;
            text-align: left;
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        padding: 0;
      }
    }
  }
}
