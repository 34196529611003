.dashboard-order-card-status {
  font-weight: 500;
  color: $breadcrumb-active-border;
}

.row {
  &.margin-bottom-custom {
    margin-bottom: 0.3rem;
  }
}

.breadcrumbTracking {
  width: 100%;
  height: auto;
  display: inline-flex;
  justify-content: space-between;
  left: 0.5%;

  .conector {
    background-color: $breadcrumb-inactive-background;
    width: 12%;
    height: 6px;
    top: 42%;
    position: relative;
    z-index: 1000;

    &.active {
      border-bottom: 2px solid $breadcrumb-inactive-background;
      border-top: 2px solid $breadcrumb-inactive-background;
      background-color: $breadcrumb-active-border;
      z-index: 1001;
    }

    &-cc,
    &-p {
      background-color: $breadcrumb-inactive-background;
      width: 12%;
      height: 6px;
      top: 42%;
      position: relative;
      z-index: 1000;

      &.active {
        border-bottom: 2px solid $breadcrumb-inactive-background;
        border-top: 2px solid $breadcrumb-inactive-background;
        background-color: $breadcrumb-active-border;
        z-index: 1001;
      }

      @include respond-to-max-width($max-mobile-width) {
        width: 6%;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      width: 6px;
    }
  }

  .circle {
    &-conector {
      border-radius: 50px;
      position: relative;
      top: 26%;
      width: 18px;
      height: 18px;
      background-color: $breadcrumb-inactive-background;
      z-index: 1008;
      display: inline-flex;

      .line-circle-active-right,
      .line-circle-active-left {
        &.active {
          border-bottom: 2.25px solid $breadcrumb-inactive-background;
          border-top: 2.25px solid $breadcrumb-inactive-background;
          background-color: $breadcrumb-active-border;
          height: 6px;
          width: 3px;
          position: relative;
          top: 36%;
        }
      }

      &-internal {
        background-color: $breadcrumb-inactive-background;

        &.active {
          border-radius: 50px;
          width: 12px;
          height: 12px;
          border: 2px solid $breadcrumb-active-border;
          background-color: #fff;
          position: relative;
          top: 17%;
        }
      }

      &-status-right,
      &-status-left {
        position: relative;
        height: 6px;
        width: 3px;
        top: 42%;
        z-index: 1001;

        &.active {
          border-bottom: 2px solid $breadcrumb-inactive-background;
          border-top: 2px solid $breadcrumb-inactive-background;
          background-color: $breadcrumb-active-border;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        top: 27%;
      }
    }

    &-external {
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      background-color: $breadcrumb-inactive-background;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      display: inline-flex;
      margin-bottom: 0;
    }

    &-internal {
      width: 34px;
      height: 34px;

      &.active {
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
        background-color: $breadcrumb-active-background;
        border: 2px solid $breadcrumb-active-border;
        width: 34px;
        height: 34px;
        position: relative;
        top: 8%;
      }

      .product-svg {
        margin: 22% 0 0 16%;
        width: 85%;

        &.active {
          margin: 4% 0 0 7%;
          width: 85%;

          path {
            fill: $breadcrumb-active-svg;
          }
        }
      }

      .payment-svg {
        margin: 24% 0 0 12%;
        width: 80%;

        path {
          fill: $breadcrumb-inactive-svg;
        }

        &.active {
          margin: 5% 0 0 11%;
          width: 80%;

          path {
            fill: $breadcrumb-active-svg;
          }
        }
      }

      .package-svg {
        margin: 26% 0 0 6%;
        width: 88%;

        path {
          fill: $breadcrumb-inactive-svg;
        }

        &.active {
          margin: 15% 0 0 9%;
          width: 80%;

          path {
            fill: $breadcrumb-active-svg;
          }
        }
      }

      .pickup-svg {
        path {
          fill: $breadcrumb-inactive-svg;
        }

        &.active {
          path {
            fill: $breadcrumb-active-svg;
          }
        }
      }

      .sended-svg {
        margin: 26% 0 0 6%;
        width: 88%;

        path {
          fill: $breadcrumb-inactive-svg;
        }

        &.active {
          margin: 10% 0 0 10%;
          width: 80%;

          path {
            fill: $breadcrumb-active-svg;
          }
        }
      }

      .delivered-svg {
        margin: 28% 0 0 7%;
        width: 85%;

        path {
          fill: $breadcrumb-inactive-svg;
        }

        &.active {
          margin: 12% 0 0 5%;
          width: 90%;

          path {
            fill: $breadcrumb-active-svg;
          }
        }
      }
    }
  }

  .titles {
    font-size: 12px;
    font-weight: bold;

    &.active {
      color: $breadcrumb-active-border;
    }

    &.product-stage {
      margin-left: -1%;

      @include respond-to-max-width($max-mobile-width) {
        margin-left: -3%;
        text-align: center;
      }
    }

    &.payment-stage {
      @include respond-to-max-width($max-mobile-width) {
        text-align: center;
      }
    }

    &.package-stage {
      @include respond-to-max-width($max-mobile-width) {
        text-align: center;
      }
    }

    &.sended-stage {
      @include respond-to-max-width($max-mobile-width) {
        text-align: center;
      }
    }

    &.delivered-stage {
      @include respond-to-max-width($max-mobile-width) {
        text-align: center;
      }
    }

    &.delivered-stage-cc {
      @include respond-to-max-width($max-mobile-width) {
        text-align: center;
      }
    }
  }

  &-cc {
    justify-content: space-between;
  }
}

.status-history {
  &__table {
    border: solid 1px $regular-light-gray;
    margin-bottom: 27px;

    &-td {
      font-size: 14px;
    }
  }

  &__date {
    text-align: end;

    &-title {
      text-align: end;
      padding-right: 4.5rem;

      @include respond-to-max-width($max-mobile-width) {
        padding-right: 2.1rem;
      }
    }
  }

  &__tracking-number {
    font-weight: bold;
  }
}

header ~ #maincontent .container a:not(.btn-primary):not(.btn-outline-primary) {
  &.status-history__tracking-link {
    color: #0070d2;
    font-size: 14px;
    text-decoration: underline;
  }
}

header ~ #maincontent .container.order-details-page-container .card-body .summary-details.custom-color-status {
  color: $breadcrumb-active-border;
}
