.modal-dialog.choose-bonus-product-dialog {
  max-width: 400px;
  top: 50%;
  transform: translateY(-50%) !important;

  .modal-content-custom-bonus {
    scale: 0.93;

    .modal-header {
      background: none;
      display: flex;
      align-items: center;

      span {
        text-align: left;
      }

      img.close-img {
        background: url(../images/Icon_x.svg) no-repeat;
        margin: 0;
        background-size: 16px;
        padding: 8px;
      }
    }

    .modal-body-custom-bonus {
      padding: 0 !important;

      .imageContainer {
        padding-left: 1px;
        padding-right: 1px;

        .productImage {
          max-width: 100%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          border-bottom: 1px solid #cacaca;
        }
      }

      .product-info-custom-bonus {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;

        .product-name {
          min-height: 1.5rem;
          font-size: 24px;
          font-family: $main-font;
          font-weight: 500;
        }

        .detail-panel {
          border-top: 1px solid $light-gray;

          .attribute {
            padding-bottom: 15px;
            margin: 0;
            border-bottom: 1px solid $light-gray;

            .color-attribute {
              margin-right: 10px;

              .swatch-circle {
                width: 25px;
                height: 25px;

                &.selected::after {
                  font-size: inherit;
                }
              }

              ul {
                &.attribute-size {
                  padding-inline-start: 0;
                  display: flex;
                  flex-wrap: wrap;
                  margin: -5px;
                }

                div {
                  &.variation-attribute-size-values {
                    display: flex;
                    flex-wrap: wrap;
                    margin: -5px;
                    margin-left: 2px;

                    .variation-attribute-size {
                      font-size: 18px;
                      width: 84px;
                      height: 46px;
                      border-radius: 3px;
                      margin: 2px;
                      border: 1px solid #121212;
                      color: #121212;
                      display: flex;
                      flex: 0 1 84px;

                      @media (max-width: 991px) {
                        width: 76px;
                        flex: 0 1 76px;
                      }

                      &.disabled {
                        border-color: #cacaca;
                        color: #cacaca;
                      }

                      &.selected {
                        color: white;
                        background-color: $primary-color-2;
                      }

                      span {
                        padding-bottom: 1px;
                        padding-left: 5px;
                        padding-right: 5px;
                      }
                    }
                  }

                  &.size-chart {
                    display: none;
                  }
                }

                li {
                  span {
                    font-family: $main-font;
                    font-weight: $font-weight-regular;
                  }

                  font-size: $variation-attribute-size-font-size;
                  margin: 5px;
                  cursor: pointer;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &.selected {
                    border: 1px solid $primary-color-2;
                    border-radius: 50%;
                    cursor: not-allowed;
                    pointer-events: none;
                  }

                  &.disabled {
                    color: $medium-light-gray;
                    cursor: not-allowed;
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }

        ul {
          padding: 0;

          div {
            &.variation-attribute-size-values {
              display: flex;
              flex-wrap: wrap;
              margin: -5px;
              margin-left: 2px;

              .modal-li {
                min-width: 105px;
                white-space: nowrap;
              }

              .variation-attribute-size {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                width: 86px;
                height: 46px;
                border-radius: 3px;
                margin: 2px;
                border: 1px solid #121212;
                color: #121212;

                &.disabled {
                  border-color: #cacaca;
                  color: #cacaca;
                }

                &.selected {
                  color: #fff;
                  background-color: #121212;
                }

                span {
                  padding-bottom: 1px;
                  padding-left: 5px;
                  padding-right: 5px;
                }
              }
            }
          }
        }

        .select-bonus-product {
          height: 50px;
          padding: 0;
          margin: 0;
          width: 100%;
          background-color: #ed3237;
          color: white;
          text-transform: uppercase;
        }
      }

      .slick-prev-bonus {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 25%;
        display: block;
        width: 100px;
        height: 44px;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: 0;
        background: 0 0;
        z-index: 999;

        @media (max-width: 768px) {
          display: block !important;
        }
      }

      .slick-next-bonus {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 25%;
        display: block;
        width: 100px;
        height: 44px;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: 0;
        background: 0 0;
        z-index: 999;

        @include respond-to-max-width(768px) {
          display: block !important;
        }
      }

      .slick-prev-bonus.slick-disabled,
      .slick-next-bonus.slick-disabled {
        display: none !important;
      }
    }

    .modal-footer {
      padding: 0;

      .container {
        padding: 0;

        .add-bonus-products {
          height: 50px;
          margin-bottom: 30px;
          background-color: #121212;
          text-transform: uppercase;
        }
      }
    }
  }
}

.minicart .popover-bottom .cart .embedded-bonus-container .price-and-quantity {
  justify-content: end;
  flex-direction: column;
}

.bonus-product-line-item {
  height: 340px;

  .product-line-item-details {
    overflow: unset;
    margin-bottom: 20px;
  }
}

.cart-page {
  .bonus-product-line-item {
    height: auto;

    .bundled-line-item{
      .row.justify-content-between.right-container {
        max-width: 620px;

        @include respond-to-between(991px, 1200px) {
          justify-content: space-between !important;
        }
      }

      .col-8.line-item-total-price {
        @include respond-to-min-width( 991px) {
          display: block;
          position: absolute;
          right: 0;

          .line-item-total-text.line-item-total-price-label {
            width: auto;
          }

          .price.pricing.line-item-total-price-amount {
            width: auto;
          }
        }
      }
    }

    .bundled-line-item + .bundled-line-item {
      margin-top: 20px;
    }

    .bonus-line-item-msg {
      margin-top: 30px;
    }
  }
}

// checkout page
.card.order-product-summary.checkout {
  .product-line-item-details {
    overflow: unset;
    margin-bottom: 20px;
  }
}

.card.bonus-product-button .btn.btn-outline-primary {
  height: 50px;
  background-color: $bg-color-btn-login;
  border-color: $bg-color-btn-login;
  border-radius: 25px;
  text-transform: uppercase;
  color: white;
}
