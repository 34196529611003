#message-modal {
  border: 1px solid $regular-light-gray;
  border-radius: 3px;
  z-index: 10;
  background-color: $background;
  width: 500px;
  padding: 0;

  .text-message {
    font-size: 1.125rem;
    text-align: center;
    color: #999;
    margin: 26px;

    a {
      color: #fda806;
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }

    &::before {
      font-family: "FontAwesome";
      content: "\f06a";
      font-weight: 900;
      display: block;
      color: #fda806;
      transform: rotate(0.5turn);
      font-size: 2rem;
    }
  }

  .close-message {
    color: $custom-link-color;
    font-size: 0.75rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  hr {
    border-color: $regular-light-gray;
  }

  @include respond-to-max-width($max-mobile-width) {
    width: auto;
  }
}

.msg-container {
  display: flex;

  hr,
  .close-message {
    display: none;
  }

  &.pdp {
    a {
      color: #fda806;
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .fa-exclamation-circle {
    color: #fda806;
    transform: rotate(180deg);
    vertical-align: middle;
    position: absolute;
    margin-top: 4px;
  }

  .text-message {
    font-size: 12px;
    text-align: left;
    color: #999;
    margin-left: 26px;
  }
}

.big-volume-tag {
  color: #fda806;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
