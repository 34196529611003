$big-volume-accent:#fda806;
$gray-accent: #999;

.big-volume {
  &-product {
    margin-bottom: 12px;
  }

  &-title {
    .fa-exclamation-circle {
      color: $big-volume-accent;
      transform: rotate(180deg);
      vertical-align: middle;
    }
  }

  &-tag {
    color: $big-volume-accent;
    text-align: left;
    text-decoration: underline;
    font-size: 14px;
  }

  &-message {
    color: $gray-accent;
    text-align: left;
    font-size: 12px;
    margin-left: 2%;
  }
}
