.modal-dialog.in-store-inventory-dialog {
  .modal-body {
    .store-locator-container {
      .btn-click-collect {
        border-radius: 25px;
        font-weight: bold;
      }
    }
  }

  .modal-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
  }
}

.modal-header.justify-content-end .modal-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
}
