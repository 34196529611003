$select-icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=";
$check-icon: "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E";

.order-list-header {
  margin: auto;
  width: 100%;

  .breadcrumb {
    margin-top: 30px;
    margin-left: 0;
    margin-bottom: 30px;
    padding-bottom: 0;
    font-size: 14px;
    font-family: $main-font;

    a {
      color: $dark-gray;
    }

    &-item {
      &:last-child {
        a {
          color: $primary-color-2;
        }
      }
    }
  }

  .checkout-step-header-container {
    margin: 0 20px 30px;
    padding: 0;

    @include respond-to-min-width(1342px) {
      margin: 0 30px 30px;
    }

    @include respond-to-between(545px, 1341px) {
      margin: 0 44px 30px;
    }
  }

  .checkout-step-header {
    &-main {
      margin-bottom: 0;
      margin-top: 0;
    }

    &-subheader {
      &-main,
      &-phone-container {
        display: none;
      }
    }
  }
}

.orders {
  font-family: $main-font;

  .form-group {
    p {
      font-weight: $font-weight-medium;
      font-family: $main-font;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 14px;
      }
    }

    select {
      border-color: $black-text;
      font-size: 16px;
      font-weight: $font-weight-regular;
      font-family: $main-font;
      color: $primary-color-2;
      border-radius: 3px;
      -webkit-appearance: none;
      appearance: none;
      background-position: right 50%;
      background-repeat: no-repeat;
      background-image: url($select-icon);
      background-size: 20px 20px;

      &:invalid {
        color: $dark-gray;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .price-row {
    display: none;
  }

  .back {
    margin-top: 20px;

    a {
      font-weight: $font-weight-medium;
      text-decoration: underline;

      p::before {
        content: "<";
      }
    }
  }

  .card {
    &-header {
      background-color: $light-gray;
      border-bottom: none;

      h3 {
        font-family: $main-font;
        font-size: 20px;

        @include respond-to-max-width($max-mobile-width) {
          font-size: 16px;
        }
      }

      a {
        font-size: 14px;
        font-weight: $font-weight-medium;
      }
    }

    &-body {
      padding-bottom: 0;
      padding-top: 0;

      p {
        font-family: $main-font;
        font-size: 16px;
        padding-top: 10px;

        &:last-child {
          padding-bottom: 10px;
        }
      }
    }

    &-footer {
      border-top-style: dashed;

      .price-row {
        display: block;
      }

      .leading-lines {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin: 7px 0;

        .dots-line {
          border-bottom: 1px dashed #aaa;
          -ms-flex-positive: 1;
          flex-grow: 1;
          margin: 6px;
        }

        p {
          font-size: 18px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
