$select-icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=";

.form-group {
  &.birth {
    margin-bottom: 0;
  }

  label {
    font-size: 14px;
    font-family: $main-font;

    .required-asterisk {
      color: #999;
    }
  }

  .col-8.document {
    padding-right: 0;
  }

  input {
    border-color: $black-text;
    color: $primary-color-2;
    font-size: 14px;
    font-weight: $font-weight-regular;
    font-family: $main-font;
    transition: all ease 0.333ms;
  }

  select {
    border-color: $black-text;
    font-size: 14px;
    font-weight: $font-weight-regular;
    font-family: $main-font;
    color: $primary-color-2;
    border-radius: 3px;
    -webkit-appearance: none;
    appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url($select-icon);

    &:invalid {
      color: $dark-gray;
    }
  }

  &.custom-checkbox {
    margin-bottom: 25px;
    margin-top: 15px;

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
      background-color: $primary-color-2;
    }

    label {
      padding-top: 2px;

      &::before,
      &::after {
        @include respond-to-min-width($max-mobile-width) {
          left: 5px;
        }
      }

      &::after {
        cursor: pointer;
        background-color: white;
        border: 1px solid black;
        border-radius: 3px;
      }

      @include respond-to-min-width($max-mobile-width) {
        padding-left: 25px;
      }
    }

    &.privacy-policy {
      a {
        font-weight: $font-weight-medium;
        color: $black-text;
        text-decoration: underline;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      padding-left: 0;
    }
  }

  &.remember-me {
    @include respond-to-max-width($max-mobile-width) {
      text-align: center;
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }

  .form-control-custom {
    border: none;
    border-bottom: 1px solid $gray-accent;
    border-color: $gray-accent;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;

    &::placeholder {
      color: $gray-accent;
    }

    &:-webkit-autofill + .placeholder-label {
      position: absolute;
      top: 10px;
      left: 0;
      padding: 0 5px;
      transition: 0.3s;
      color: $gray-accent;
      transform: translateY(-30px);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      zoom: 1.1666;
      font-family: Roboto, sans-serif;
    }

    &:-webkit-autofill::first-line {
      font-size: 0.8333rem;
      line-height: 1.5;
      font-family: Roboto, sans-serif;
    }

    &:-internal-autofill-selected {
      -webkit-background-clip: text;
      background-clip: text;
    }

    &:focus + .placeholder-label,
    &:not(:placeholder-shown) + .placeholder-label {
      transform: translateY(-30px);
      color: $gray-accent;
    }
  }

  &-email {
    position: relative;
    margin-top: 50px;
  }

  &-name,
  &-lastname,
  &-emailr,
  &-email-confirm {
    position: relative;
    margin-top: 30px;
  }

  &-password,
  &-confirmpassword,
  &-dni,
  &-phone {
    position: relative;
    margin-top: 40px;
  }

  @include respond-to-max-width(604px) {
    &-lastname,
    &-email-confirm {
      position: relative;
      margin-top: 40px;
    }

    &-dni,
    &-phone,
    &-confirmpassword {
      position: relative;
      margin-top: 30px;
    }
  }
}
