.page-designer-reference {
  .editorialRichText-component-container {
    margin: auto;
    padding: 30px 10px;

    @extend .container;

    p {
      font-size: 16px;
      font-weight: $font-weight-regular;
      font-family: $main-font;
      color: $primary-color-2;
      line-height: 21px;
      max-width: 800px;
      margin-bottom: 0;
      margin-inline: auto;

      @include respond-to-max-width($max-mobile-width) {
        text-align: left;
      }
    }

    h1 {
      font-family: $main-font;
      color: $carousel-indicator-active;
    }

    h2 {
      font-family: $main-font;
      font-size: 20px;
      text-align: left;
      max-width: 800px;
      margin-inline: auto;
      margin-bottom: 20px;
      color: $carousel-indicator-active;
    }

    @include respond-to-max-width($max-mobile-width) {
      padding: 20px 10px;
    }
  }
}

.container.px-2.page-designer-reference{
  @include respond-to-min-width(992px){
    max-width: 100%;
  }

  @include respond-to-min-width(1200px){
    max-width: 100%;
  }
}
