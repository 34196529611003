.link-redirect {
  width: 100%;
  height: auto;
  margin-left: 15%;
  margin-top: 7%;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="password"],
input[type="text"] {
  box-shadow: none !important;
}

button,
input,
select,
textarea {
  &:focus {
    outline: none !important;
  }
}

nav[role="navigation"] .header .navbar-header.brand .logo-home img {
  width: 160px;

  @include respond-to-max-width($max-mobile-width) {
    width: 115px;
    margin-top: -2%;
  }
}

.header {
  &__nav-redirect-moov {
    position: relative;
    width: fit-content;
    padding: 0 40px;
    border-left: 2px solid #dbdcda;
    border-right: 2px solid #dbdcda;
    margin: 0 auto;
  }

  &__nav-redirect-dexter {
    display: none;
  }
}

.navbar-header {
  .search {
    .site-search {
      &.search__noCategories {
        left: unset;
        margin-left: -25%;
      }
    }
  }
}

.custom-margin-stamp-limited {
  max-width: 66%;
}

.account-payment-methods,
.payments,
.address-book,
.addresses {
  .card-header {
    h2 {
      font-family: 'Roboto';
    }
  }

  .add-new {
    a {
      padding: 15px 20px;
      font-size: 15px;
      border: none;
      outline: 0;
      height: 50px;
      display: block;
      margin: 0 auto;
      font-weight: 700;
      background-color: $primary-color-1;
      border-color: $primary-color-1;
      border-radius: 25px;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Roboto';

      &:hover {
        background-color: $primary-color-1;
      }

      &:focus {
        background: $primary-color-1 !important;
        box-shadow: none !important;
      }
    }
  }
}

.address-book,
.addresses {
  .card-header {
    h2 {
      max-width: 90%;

      @include respond-to-max-width(992px) {
        max-width: 80%;
      }
    }
  }
}

@include respond-to-max-width(543.98px) {
  #home {
    #recommendations {
      .carousel.indicators-xs {
        padding-bottom: 0;
      }
    }
  }
}
