$white: #fff;
$black: #121212;
$gray: #cacaca;

.wrapper {
  height: 80px;
}

.range {
  &_container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }

  &-min-wrapper {
    display: block;
    height: 2px;
    background: $gray;
  }

  &-slider {
    position: relative;
    width: 100%;
    height: 2px;

    .progress {
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      border-radius: 15px;
      background: $black;
    }

    input[type=range] {
      position: absolute;
      width: 100%;
      height: 7px;
      -webkit-appearance: none;
      appearance: none;
      pointer-events: none;
      background: none;
      outline: none;
      transform: translateY(-3px);

      &::-webkit-slider-thumb,
      &::-moz-range-thumb {
        -webkit-appearance: none;
        pointer-events: all;
        width: 20px;
        height: 20px;
        background-color: $white;
        border-radius: 50%;
        border: 2px solid $black;
        cursor: pointer;
      }

      &::-webkit-slider-thumb:hover {
        background: $white;
      }

      &::-webkit-slider-thumb:active {
        border: 1px solid $gray;
      }
    }
  }

  &-max,
  &-min {
    &::-webkit-slider-thumb {
      pointer-events: auto;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: $white;
      border: 2px solid $black;
      border-radius: 20px;
      opacity: 1;
    }
  }
}

.sliders_control {
  position: relative;
  min-height: 54px;
  color: $black;
}

.form_control {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $black;
  padding-bottom: 14px;
  line-height: 1.2;
}

input[type="number"] {
  color: $black;
  width: 100%;
  height: 30px;
  font-size: 16px;
  border: none;
  text-align: center;
  background-color: transparent;
  -moz-appearance: textfield;
  appearance: textfield;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 0;
    -webkit-appearance: none;
    margin: 0;
  }
}

#fromSlider {
  height: 0;
  z-index: 1;
  bottom: 49px;
}

#fromInput {
  text-align: right;
  padding-right: 8px;
}

#toInput {
  text-align: left;
  padding-left: 8px;
}

.search-buttons button {
  text-align: center;
  font-weight: 500;
  text-decoration: underline;
  position: relative;
  z-index: 20;
  width: 100%;
}
