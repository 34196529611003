$searchInputLarge: 340px;
$searchInputMedium: 240px;
$searchInputSmall: 200px;
$searchIconSize: 21px;
$desktop-search-suggestions: 310px;
$gutter: 16px;
$customMobileBreak: 1340px;

#mainContainer,
[data-action="Cart-Show"] {
  .header {
    &__search {
      .fa-times.reset-button{
        margin-right: -20px;

        @include respond-to-max-width($customMobileBreak) {
          display: none !important;//overriding bootstrap rule that shows this content on mobile and cannot be changed without affecting desktop
        }
      }

      &__icon {
        height: $searchIconSize;
        margin-bottom: ($gutter / 4);
        cursor: pointer;
      }

      .fa-close,
      .fa-search,
      .reset-button {
        color: $brand-identity;
      }

      &__input {
        position: absolute;
        bottom: -20px;
        right: 0;

        @include respond-to-min-width(1750px) {
          min-width: $searchInputLarge; // 340px
        }

        @include respond-to-between(1500px, 1750px) {
          min-width: $searchInputMedium; // 240px
        }

        @include respond-to-max-width(1500px) {
          min-width: $searchInputSmall; // 200px
        }
      }

      &__suggestions {
        .suggestions {
          z-index: 150;

          @include respond-to-min-width(1300px) {
            width: $desktop-search-suggestions;
          }
        }
      }

      @include respond-to-max-width($customMobileBreak){
        width: 100%;

        .search-field{
          height: 46px;
          border-radius: 0;
          padding-left: 80px;
        }
      }
    }

    .search__mobile {
      .site-search {
        form {
          .fa-close {
            top: 15px;
          }
        }
      }

      @include respond-to-min-width($customMobileBreak) {
        display: none;
      }

      @include respond-to-max-width($customMobileBreak) {
        display: block;
        position: absolute;
        top: 70px;
        width: 100%;
        z-index: 10;
      }
    }
  }
}

.navbar-header {
  .search {
    .site-search {
      width: 100%;

      &.search__noCategories {
        left: unset;
        margin-left: -40%;
      }

      form {
        height: 100%;

        .fa-close {
          top: 15px;
        }
      }
    }
  }
}

.header__search__icon {
  height: 21px;
  margin-bottom: 4px;
  cursor: pointer;
}
