.infobar {
  width: 100%;
  background-color: $medium-light-gray;
  height: 37px;
  display: inline-flex;
  border-bottom: solid 1px $inforbar-border-buttom;
  justify-content: flex-end;

  .infobar-attcli {
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  hr {
    display: none;
  }

  div {
    height: 19px;
    margin-top: 7px;
    padding: 0 8px;
    text-align: left;
    font: normal normal normal 14px/22px $main-font;
    letter-spacing: 0;
    color: $bg-color-navbar-menu-mobile;
    opacity: 1;
    border-right: 1px solid;

    a {
      color: $bg-color-navbar-menu-mobile;
      text-decoration: none;
    }

    &:last-child {
      border-right: 0;
      margin-right: 40px;
    }
  }

  .content-asset {
    margin: 0;
    padding: 0;

    .infobar-contact_container {
      margin: 0;
      padding: 0;
    }
  }
}

@include respond-to-max-width($max-mobile-width) {
  .infobar {
    height: 159px;
    display: table-cell;
    vertical-align: middle;
    width: 768px;
  }

  .infobar div {
    border-right: 0;
    margin: 10px 0 10px 20px;
    padding: 0;
  }

  .infobar div:last-child {
    margin-bottom: 30px;
  }

  .infobar hr {
    display: block;
    margin: 0 20px;
    width: calc(100% - 40px);
  }

  .infobar .fa-envelope-o {
    display: none;
  }
}

.whatsapp_icon {
  position: fixed;
  height: 64px;
  width: 85px;
  right: 0;
  bottom: 7px;
  z-index: 98;
}

button {
  &.styles_button_main__2rvIi {
    &.styles_slideIn__1Hvh3 {
      &.styles_button_left__VEVz8 {
        z-index: 98;
      }
    }
  }
}
