.product {
  height: auto;

  &-tile {
    width: 100%;

    .tile-body {
      padding: 0;
      margin-top: 10px;

      .price {
        margin: 0;

        @include respond-to-between(768px, 991px) {
          font-size: 16px;
        }
      }

      .pdp-link {
        .link {
          @include respond-to-min-width(1820px) {
            max-width: 400px;
          }

          @include respond-to-min-width(1632px) {
            max-width: 350px;
          }

          @include respond-to-min-width(1452px) {
            max-width: 295px;
          }

          @include respond-to-min-width(1272px) {
            max-width: 250px;
          }

          @include respond-to-min-width(1200px) {
            max-width: 200px;
          }

          @include respond-to-between(1031px, 1199px) {
            max-width: 180px;
          }

          @include respond-to-min-width(1030px) {
            max-width: 145px;
          }

          @include respond-to-min-width(991px) {
            display: inline-block;
            max-width: 450px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        @include respond-to-min-width(991px) {
          min-height: 0 !important;
        }
      }
    }
  }

  &-image-container-grid {
    .ecoFriendlyIcon {
      position: absolute;
      left: 20px;
      width: 40px;
      height: 40px;
      top: 50px;
    }
  }
}

.quickview-newContainer {
  z-index: 10;
  position: relative;
  opacity: 0;
  background: white;
  width: auto;

  .quickview {
    display: flex;
    justify-content: center;

    button {
      width: 100%;
      background-color: #121212;
      color: #fff;
      text-align: center;
      font-size: 16px;
      border-radius: 25px;
      height: 50px;
      border: none;
      max-width: 250px;

      @include respond-to-max-width(991px) {
        height: 25px;
        font-size: 10px;
      }
    }
  }
}

.product,
.product-tile,
.tile-body,
.tile-image {
  &:hover .quickview-newContainer {
    opacity: 1;
    z-index: 15;
  }
}

.ecoFriendlyIcon {
  position: absolute !important;
  top: 50px !important;
  left: 20px;
  width: fit-content;
  z-index: 1;
}

.carousel.categories-carousel {
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;
  height: unset;
  padding: 0 40px;

  .carousel-item {
    padding: 8px;
  }

  @include respond-to-max-width(991px) {
    padding: 0 16px 4.375rem;
  }
}

.experience {
  &-layouts-4column {
    width: 100vw;
    left: 50%;
    position: relative;
    transform: translateX(-50%);

    .promotions-desktop-only {
      padding: 0 42px;

      .region {
        &_portrait-small {
          &::before {
            content: unset;
          }

          .experience-component {
            position: relative;
            left: unset;
            right: unset;
          }
        }
      }

      .experience-component {
        left: unset;
        right: unset;
      }

      @include respond-to-max-width($max-mobile-width) {
        display: none;
      }
    }
  }

  &-commerce_layouts-mobileGrid1r1c {
    .mobile-1r-1c {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      overflow-x: hidden;
      height: unset;
      padding: 0;
      position: relative;

      .col-12 {
        padding: 0 40px;

        @include respond-to-max-width(991px) {
          padding: 0 16px;
        }
      }
    }

    .experience-einstein-einsteinCarousel .row.row-fw {
      padding-left: 0;
    }
  }
}

@include respond-to-max-width(992px) {
  .ecoFriendlyIcon {
    left: 13px;
    top: 35px;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .slick-slide {
    .ecoFriendlyIcon {
      position: absolute;
      left: 8px;
      width: 30px;
      height: 30px;
      top: 40px;
    }
  }
}
