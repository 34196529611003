.wl-social-sharing {
  margin-bottom: 30px;
  margin-top: 30px;

  &-text {
    margin: 0;
  }

  a,
  span {
    &.share-icons {
      width: 40px;
      height: 40px;
      font-size: 22px;
      color: $black-text;
      padding: 2px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

header ~ #maincontent .container a:not(.btn-primary):not(.btn-outline-primary) {
  &.share-icons {
    color: $black-text;
  }
}
