// Social networks
$share-on-font-size-desktop: 14px;
$share-on-font-size-mobile: 12px;

.social {
  &-container {
    text-align: left;
    font-family: $main-font;
    font-size: $share-on-font-size-desktop;
    font-weight: $font-weight-regular;
    margin-top: 30px;

    .share-social-label {
      margin-bottom: 6px;

      @include respond-to-max-width($max-mobile-width) {
        margin-bottom: 11px;
      }
    }
  }

  &-icons {
    list-style: none;
    padding-left: 0;
    font-size: 0;

    li {
      margin-right: 16px;

      @include respond-to-min-width($max-mobile-width) {
        &:first-child {
          margin-right: 0;
        }
      }
    }

    .share-icons {
      color: unset;

      &:hover {
        text-decoration: none;
      }

      i {
        &.icon-mobile {
          display: none;
        }

        &.icon-desktop {
          display: block;
          font-size: 36px;
          padding: 0;
        }

        @include respond-to-max-width($max-mobile-width) {
          font-size: 30px;
          padding: 0;

          &.icon-mobile {
            display: block;
          }

          &.icon-desktop {
            display: none;
          }
        }
      }

      img {
        display: flex;
        background-color: #454444;
        width: 23px;
        height: 23px;
        border-radius: 7px;
        padding: 3px;
        margin-top: 1px;

        @include respond-to-max-width(769px) {
          width: 26px;
          height: 26px;
          border-radius: 5px;
          margin-top: 2px;
          bottom: 0;
        }

        @include respond-to-min-width(769px) {
          margin-top: -2px;
          width: 25px;
          height: 25px;
          position: relative;
        }

        @include media-breakpoint-up(lg) {
          width: 32px;
          height: 32px;
          margin-top: -3px;
        }
      }
    }
  }
}
