header {
  ~ #maincontent {
    .container {
      &.wishlist {
        min-height: calc(100vh - 400px);
        min-width: 100%;
        max-width: unset;
        padding: 0;

        .wishlist-breadcrumbs {
          border-bottom: none;
          margin-bottom: 0;

          .breadcrumb-item {
            a {
              &:not(.btn-primary) {
                &:not(.btn-outline-primary) {
                  color: $text-gray;
                }

                &.last-wishlist-breadcrumb {
                  color: $black-text;
                }
              }
            }
          }
        }

        .wishlist-header {
          margin: 0 auto;
          max-width: 1200px;

          .wishlist-owner {
            padding-left: 1rem;
            padding-bottom: 15px;
            margin-bottom: 30px;
            font-size: 30px;
            font-weight: $font-weight-medium;
            color: $black-text;
            border-bottom: 1px solid $medium-light-gray;

            span {
              font-size: 14px;
              padding-left: 10px;
            }
          }
        }

        .header-wishlist{
          span {
            font-size: 14px;

            &#number-of-products{
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}
