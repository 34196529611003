$personalization-header-label-color: #212121;
$personalization-header-font-size-desktop: 16px;
$personalization-header-font-size-mobile: 14px;
$personalization-header-toggle-color: $secondary-color-1;

.product-giftpackage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  label {
    color: $personalization-header-label-color;
    font-size: $personalization-header-font-size-desktop;
    font-weight: $font-weight-medium;

    @include respond-to-max-width($max-mobile-width) {
      font-size: $personalization-header-font-size-mobile;
    }
  }

  a {
    color: $personalization-header-toggle-color;
    font-size: $personalization-header-font-size-desktop;
    cursor: pointer;

    &[aria-expanded="false"] {
      .expanded {
        display: none;
      }

      .not-expanded {
        display: block;
        text-decoration: underline;
      }
    }

    &[aria-expanded="true"] {
      .expanded {
        display: block;
        margin-top: 25%;
        text-decoration: underline;

        .cost-detail {
          font-size: 0.7rem;
          color: $personalization-header-label-color;
          text-align: end;
          text-decoration: underline white;
          cursor: default;
        }
      }

      .not-expanded {
        display: none;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      font-size: $personalization-header-font-size-mobile;
    }
  }
}
