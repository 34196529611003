.container__payments-promotions {
  font-family: $main-font;
  font-size: $saving-and-installments-font-size-desktop;
  font-weight: $font-weight-regular;
  color: $black-text;

  .modal {
    &-dialog {
      max-width: 562px;
    }

    &-title {
      &.title__promotions-custom {
        color: $black-text;
        font-weight: 500;
        font-size: 17px;
        margin: 0;
        font-family: $main-font;
        line-height: 2.5;
      }
    }
  }

  a {
    color: $custom-link-color !important;
    font-weight: $font-weight-medium;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  .low-stock {
    font-size: 18px;
    font-weight: $font-weight-medium;
    margin-top: 5px;
  }

  #sizeChart {
    .modal-title {
      &.title__promotions-custom {
        font-size: 20px;
        line-height: 1.5;
      }
    }
  }

  p {
    font-size: 12px;

    &.title {
      font-weight: 700;
      color: $black-text;
      font-size: 14px;
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    font-size: $saving-and-installments-font-size-mobile;
  }
}

.pdp-promotions-container {
  .take-advantage-of-our-banking-promotions {
    border-radius: 3px 3px 0 0;
    border: 1px solid $regular-light-gray;
    border-bottom: none;
    padding: 15px;
    background: #f2f2f2;

    p {
      font-size: 16px;
      margin: 0;
      font-weight: 500;
    }
  }

  .cards-and-installments-container {
    border: 1px solid $regular-light-gray;
    border-bottom: none;
    padding: 3px 15px;

    .card-img-pdp {
      margin: 5px 5px 5px 0;
    }
  }

  .bankPromotionsAContainer {
    border: 1px solid $regular-light-gray;
    border-radius: 0 0 3px 3px;
    padding: 3px 0 3px 15px;
  }
}

.modal-bank-promotions {
  overflow-y: auto;
  height: 80%;
  top: 15%;
  z-index: 9999;

  #modal-dialog-bank-promotions {
    max-width: 800px;

    .modal-header-bank-promotions {
      display: flex;
      align-items: center;
    }

    .modal-body-bank-promotions {
      padding: 15px;

      .value-in-one-payment {
        display: flex;
        justify-content: end;
        background: #f4f4f4;
        padding: 3px;
        border-radius: 3px;
        margin-top: 14px;

        b {
          margin-left: 5px;
        }
      }

      .user-selection {
        margin: 14px 0;
        font-size: 14px;
      }

      .promotions-info-titles span {
        font-size: 12px;
        color: #999;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .promotions-container.promotions-container-desktop {
        background: #f4f4f4;
        padding: 3px;
        border-radius: 3px;
        margin-bottom: 10px;

        span {
          font-size: 12px;
        }

        .installment-value {
          color: #559550;
          font-weight: bold;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .promotions-container.promotions-container-mobile {
        background: #f4f4f4;
        padding: 3px;
        border-radius: 3px;
        margin-bottom: 10px;

        span {
          font-size: 12px;
        }

        .installment-value {
          color: #559550;
          font-weight: bold;
          padding: 0;
        }

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .cft-tea-info {
        margin-bottom: 0;
        margin-top: 20px;

        .cft-tea-number {
          font-weight: 500;
        }

        span {
          color: #999;
        }
      }
    }

    .p-select-method-bank-promotions {
      font-size: 14px;
      color: #999;
      margin-bottom: 0;
    }

    h4 {
      color: #222;
      font-weight: 500;
      font-size: 17px;
      margin: 0;
      font-family: Roboto, sans-serif;
      line-height: 2.5;
    }

    img.close-img {
      background: url(../images/Icon_x.svg) no-repeat;
      margin: 0;
      background-size: 16px;
      padding: 8px;
    }

    .card-option {
      margin: 5px;

      &.selected {
        border: 1px solid #212529;
        border-radius: 3px;
      }
    }

    .col-promotion-banks {
      .card-and-bank-option {
        margin: 5px;

        &.selected {
          border: 1px solid #212529;
          border-radius: 3px;
        }
      }
    }
  }
}
