$input-label-font-size: 14px;
$invalid-feedback-font-size: 12px;
$input-invalid-border-color: $primary-color-1;
$option-cost-plus-color: $secondary-color-1;
$input-valid-border-color: $green-color;
$input-on-focus-border-color: #ced4da;

label {
  font-family: $main-font;
  font-size: $input-label-font-size;
  font-weight: $font-weight-regular;
}

input {
  text-transform: uppercase;

  &.form-control {
    &:focus {
      &:not(.is-invalid):not(.is-valid) {
        background-color: #fff;
        border-color: $input-on-focus-border-color;
        outline: 0;
        box-shadow: none;
      }
    }

    &:not(.is-invalid):not(.is-valid) ~ span {
      display: none;
    }
  }

  &.is-invalid {
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid $input-invalid-border-color;

    &:focus {
      box-shadow: none;
    }

    & ~ span {
      border-top-right-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem;

      span {
        border-top-right-radius: 0.1875rem;
        border-bottom-right-radius: 0.1875rem;

        &.input-group-text {
          border: 1px solid $input-invalid-border-color;
        }

        i {
          color: $input-invalid-border-color;

          &.fa-check {
            display: none;
          }
        }
      }
    }

    & ~ div {
      &.invalid-feedback {
        color: $input-invalid-border-color;

        .option-cost {
          display: none;
        }
      }
    }
  }

  &.is-valid {
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid $input-valid-border-color;

    &:focus {
      box-shadow: none;
      border: 1px solid $input-valid-border-color;
    }

    & ~ span {
      border-top-right-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem;

      span {
        border-top-right-radius: 0.1875rem;
        border-bottom-right-radius: 0.1875rem;

        &.input-group-text {
          border: 1px solid $input-valid-border-color;
        }

        i {
          color: $input-valid-border-color;

          &.fa-times {
            display: none;
          }
        }
      }
    }
  }
}

.invalid-feedback {
  font-size: $invalid-feedback-font-size;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;

  span {
    font-weight: $font-weight-regular;

    &.option-cost-plus {
      color: $option-cost-plus-color;
    }
  }
}
