
body {
  overflow-x: hidden;
}

@include respond-to-min-width(1200px) {
  .container {
    max-width: 1200px;
  }
}

.header-banner {
  font-family: $main-font;
  background-color: $promotional-banner;
  height: auto;

  .container {
    .d-flex {
      padding: 7px;

      .content {
        .html-slot-container {
          span {
            font-weight: 400;
            font-size: 18px;
          }
        }
      }

      .close-button {
        height: 25px;

        .close {
          font-weight: 100;
          background-color: $promotional-banner;
        }

        button {
          font-size: 30px;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .carousel-item {
    padding: 0;
  }
}
