$gray-accent: #999;

.login-page {
  overflow-x: hidden;
  margin-top: 0;

  &.wishlist {
    padding-top: 0;
  }

  .card {
    border: none;

    h2 {
      text-align: center;
      font-weight: 500;
      color: black;
      font-family: $main-font;
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 30px;

      &.header-email-confirmation {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
      }

      &.request-passwordless-login-title {
        font-size: 1.0625rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.25rem;
        }

        margin: 0;
      }

      &.hide {
        display: none;
      }

      &.sobre-tu-cuenta {
        margin-top: 50px;
      }

      @include respond-to-max-width($max-mobile-width) {
        font-size: 23px;
      }
    }

    .subtitle {
      font-family: $main-font;
      max-width: 400px;
      margin: auto;
      text-align: center;
    }

    .login-form-nav {
      .nav-tabs {
        max-width: 400px;
        margin: auto;

        .nav-item {
          .nav-link {
            font-size: 16px;
            font-weight: 500;

            &[aria-selected="true"],
            &.active {
              border-color: $secondary-color-1;
              border-top: none;
              border-left: none;
              border-right: none;
            }

            &:not(.btn-primary):not(.btn-outline-primary) {
              color: $black-text;
            }

            @include respond-to-max-width($max-mobile-width) {
              font-size: 15px;
            }
          }
        }
      }

      .tab-pane {
        margin: 30px auto auto;

        form,
        .registration {
          .form-group {
            &.birth {
              margin-bottom: 0;
            }

            label {
              font-size: 14px;
              font-family: $main-font;

              .required-asterisk {
                color: $gray-accent;
              }
            }

            input {
              border-color: $black-text;
              color: $primary-color-2;
              font-size: 16px;
              font-weight: 400;
              font-family: $main-font;
            }

            select {
              border-color: $black-text;
              font-size: 16px;
              font-weight: 400;
              font-family: $main-font;
              color: $primary-color-2;
              border-radius: 3px;
              -webkit-appearance: none;
              appearance: none;
              background-position: right 50%;
              background-repeat: no-repeat;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);

              &:invalid {
                color: $gray-accent;
              }
            }

            &.custom-checkbox {
              margin-bottom: 25px;
              margin-top: 15px;

              .custom-control-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
                background-color: $primary-color-2;
              }

              label {
                padding-top: 2px;

                &::before,
                &::after {
                  @include respond-to-min-width($max-mobile-width) {
                    left: 5px;
                  }
                }

                &::after {
                  cursor: pointer;
                  background-color: white;
                  border: 1px solid black;
                  border-radius: 3px;
                }

                @include respond-to-min-width($max-mobile-width) {
                  padding-left: 25px;
                }
              }

              &.privacy-policy {
                a {
                  font-weight: 500;
                  color: #222;
                  text-decoration: underline;
                }

                label {
                  color: black;

                  #reset-pass-label{
                    color: #999;
                    position: relative;
                    top: 40px;
                    transition: all 0.3s ease;

                    &.focused{
                      top: 0;
                    }
                  }
                }
              }

              @include respond-to-min-width($max-mobile-width) {
                padding-left: 0;
              }
            }
          }

          .forgot-password {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 1rem;

            a {
              font-weight: 500;
              font-family: $main-font;
              color: $primary-color-2;
              font-size: 14px;
              text-decoration: underline;
              line-height: normal;
            }

            @include respond-to-max-width($max-mobile-width) {
              margin-top: 0;
              text-align: center;
            }
          }

          button {
            font-size: 14px;
            height: 50px;
            background-color: $secondary-color-1;
            font-family: $main-font;
            border: none;
          }
        }

        &.login {
          max-width: 400px;
        }

        &.register {
          max-width: 850px;

          .registration {
            h2 {
              font-size: 16px;
              text-align: left;
            }

            .custom-checkbox {
              max-width: 405px;
              padding-left: 0;
              margin: 0 auto 25px;

              &.privacy-policy {
                margin: 40px auto 15px;
              }

              @include respond-to-max-width($max-mobile-width) {
                padding-left: 25px;

                label {
                  &::before,
                  &::after {
                    left: 0;
                  }
                }
              }
            }

            .notifications {
              margin: auto auto 15px;
              max-width: 405px;
              font-size: 14px;
              font-weight: 500;
            }

            .checkbox {
              &-group {
                display: flex;
                padding-left: 0;
                margin-bottom: 0;
              }

              &-section {
                margin-left: 0;
              }
            }

            button {
              max-width: 380px;
              margin: auto;
            }

            @include respond-to-min-width($max-mobile-width) {
              .left-col {
                padding-right: 50px;
              }

              .right-col {
                padding-left: 50px;
              }

              .document {
                padding-left: 5px;
                padding-right: 5px;
              }
            }
          }
        }

        .passwordless-modal-body {
          padding: 18px;
        }

        .oauth-google {
          height: 50px;
          border: 1px solid #121212;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          font-weight: 500;

          &:active,
          &:focus,
          &:hover {
            background: none;
            box-shadow: none;
          }

          .login-google {
            width: 20px;
            margin-right: 8px;
          }
        }

        .btn-login,
        .checkout-as-guest.checkout-as-guest {
          background-color: $bg-color-btn-login;
          border-radius: 25px;
          text-transform: uppercase;
          height: 50px;
          font-size: 14px;
          font-family: $main-font;
          border: none;
          color: #fff;
          line-height: 50px;
          padding-block: 0;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        form {
          .form-group {
            .form-control-custom {
              border: none;
              border-bottom: 1px solid $gray-accent;
              border-color: $gray-accent;
              border-radius: 0;
              -webkit-box-shadow: 0 0 0 1000px white inset;
              box-shadow: 0 0 0 1000px white inset;

              &::placeholder {
                color: $gray-accent;
              }

              &:-webkit-autofill + .placeholder-label {
                position: absolute;
                top: 10px;
                left: 0;
                padding: 0 5px;
                transition: 0.3s;
                color: $gray-accent;
                transform: translateY(-30px);
              }

              &:-webkit-autofill,
              &:-webkit-autofill:hover,
              &:-webkit-autofill:focus {
                zoom: 1.1666;
                font-family: $main-font;
              }

              &:-webkit-autofill::first-line {
                font-size: 0.8333rem;
                line-height: 1.5;
                font-family: $main-font;
              }

              &:-internal-autofill-selected {
                -webkit-background-clip: text;
                background-clip: text;
              }

              &:focus + .placeholder-label,
              &:not(:placeholder-shown) + .placeholder-label {
                transform: translateY(-30px);
                color: $gray-accent;
              }
            }

            &-email {
              position: relative;
              margin-top: 50px;
            }

            &-name,
            &-lastname,
            &-emailr,
            &-email-confirm {
              position: relative;
              margin-top: 30px;
            }

            &-password,
            &-confirmpassword,
            &-dni,
            &-phone {
              position: relative;
              margin-top: 40px;
            }

            @include respond-to-max-width(604px) {
              &-lastname,
              &-email-confirm {
                position: relative;
                margin-top: 40px;
              }

              &-dni,
              &-phone,
              &-confirmpassword {
                position: relative;
                margin-top: 30px;
              }
            }
          }

          .request-passwordless-login-body {
            p {
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 0;
            }

            .pwdless-token-form-container {
              padding-bottom: 2rem;

              p {
                margin-left: 0.3rem;
              }

              .pwdless-token {
                .col {
                  padding-left: 3px;
                  padding-right: 3px;
                }

                .form-control {
                  padding: 1.375rem 0.75rem;
                  border-radius: 0.375rem;
                  width: 100%;
                  height: calc(2rem + 1rem + 2px);
                  font-size: 0.875rem;
                  font-weight: bold;
                  color: #121212;

                  @include media-breakpoint-up(sm) {
                    font-size: 1.875rem;
                  }
                }
              }
            }
          }

          .passwordless-form-input-container {
            position: relative;
            margin-top: 2.8rem;
            margin-bottom: 2rem;
          }

          .btn-login,
          .submitPasswordlessButton,
          .submitTokenButton {
            background-color: $bg-color-btn-login;
            border-radius: 25px;
            text-transform: uppercase;
            height: 50px;
          }

          .oauth-google {
            height: 50px;
            border: 1px solid #121212;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            font-weight: 500;

            &:active,
            &:focus,
            &:hover {
              background: none;
              box-shadow: none;
            }

            .login-google {
              width: 20px;
              margin-right: 8px;
            }
          }

          .text-center {
            margin: 24px auto auto;

            .btn-google-form-declined {
              color: #0070d2;
              font-size: 14px;
              font-weight: 600;
              font-family: $main-font;
            }
          }
        }

        .form {
          &-header-google {
            max-width: 447px;
            text-align: center;
            color: #121212;
            font-size: 24px;
            font-weight: 500;
            border-bottom: 1px solid $gray-accent;
            margin: auto;
            padding-bottom: 8px;
          }

          &-google-msg-header {
            display: flex;
            margin-top: 15px;
            color: $gray-accent;
            font-size: 14px;

            i {
              margin: 5px 5px auto;
              color: #fda806;
              transform: rotate(180deg);
            }
          }
        }

        button.close {
          img.close-img {
            width: 16px;
          }
        }
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      margin-bottom: 0;
    }

    @include respond-to-max-width(604px) {
      &.card-login {
        border: none;
      }
    }
  }

  .modal {
    .request-password-title {
      font-size: 23px;
    }

    button.close {
      img.close-img {
        width: 16px;
      }
    }
  }

  &.checkout {
    @import "../components/checkout/checkoutLogin";
  }

  @include respond-to-max-width($max-mobile-width) {
    margin: 0;
    border: none;
    padding: 0;

    & ~ #footercontent {
      margin-top: 0;
    }
  }

  .request-passwordless-login-modal {
    .modal-dialog {
      margin-top: 12.5rem;

      .modal-body {
        padding-top: 1rem;
      }
    }

    .close-img-expand-modal {
      width: 1rem;
      height: auto;
    }
  }
}

.register-account-message {
  @include font($font-weight-regular, 14px, 20px);

  font-family: $main-font;
  color: $primary-color-2;
}

.register-account {
  @include font($font-weight-medium, 14px, 20px);

  font-family: $main-font;
  color: $primary-color-2;
  text-decoration: underline;
}

.page-designer-reference {
  .placeholder-label {
    color: #999;
  }
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-text {
  font-size: 14px;
  color: $gray-accent;
}

@include respond-to-min-width(604px) {
  .row-register-form {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .form-group-registration {
    width: 48%;
  }

  .row-register-form-dni {
    display: flex;
    width: 48%;
  }
}

.placeholder-label {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 0 5px;
  transition: 0.3s;
  color: $gray-accent;
  pointer-events: none;

  &-dni {
    top: -25px;
    left: 0;
  }
}
