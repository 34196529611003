@import "~base/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/variables";

.btn-storelocator-search-click {
    border-color: #000;
    border-radius: 0;
    color: white;
    margin-left: -7px;
    width: 30%;
    height: 40px;
    border: none;
    background-color: #000;

    &:hover {
        background-color: #000;
        color: white;
    }
}

.invalid-feedback-click {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #c00;
}

.store-name {
    font-weight: 600;
}

.btn-get-in-store-inventory {
    .btn-storelocator-search-click {
        margin-bottom: 0.625rem;
        display: block;
        width: 100%;

        @include media-breakpoint-up(sm) {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }
}

.selected-store-with-inventory {
    .change-store {
        margin: 0 1em 1em;
        width: auto;
    }
}

.display-none {
    display: none;
}

.modal-header {
    background-color: $white;
    border-bottom: 2px solid $grey3;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;

    .close {
        font-size: 2rem;

        span {
            font-size: 1rem;
            margin-right: 0.313em;
        }
    }
}
