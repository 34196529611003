#manualCarouselRecomendations {
  .slick {
    &-arrow {
      z-index: 10;
      left: 0;
      color: black;
    }

    &-prev,
    &-next {
      &::before {
        font: normal normal normal 14px/1 FontAwesome;
        color: black;
        font-size: 18px;
        font-weight: 600;
        position: absolute;
      }

      @include respond-to-max-width(768px) {
        display: none !important;
      }
    }

    &-prev {
      &::before {
        content: "\F104";
        bottom: 80px;
      }
    }

    &-next {
      &::before {
        content: "\F105";
        top: -80px;
      }
    }
  }
}

.recommendations-title {
  line-height: 1.2;
  font-size: 30px;
  font-weight: 500;
  color: #121212;
  font-family: $main-font;
  margin-bottom: 30px;
}

.product-recommendation-listing {
  margin-top: 60px;

  h3 {
    font-size: 30px;
    font-weight: 500;
    color: $primary-color-2;
    font-family: $main-font;
    margin-bottom: 30px;
  }

  .carousel {
    a[class^="carousel-control-"] {
      span {
        &.fa.icon-prev,
        &.fa.icon-next {
          background-color: transparent;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        display: none;
      }
    }

    &-indicators {
      display: none;

      li {
        background-color: #21212180;
        height: 11px;
        width: 11px;

        &.active {
          background-color: $light-black;
          height: 14px;
          width: 14px;

          @include respond-to-max-width($max-mobile-width) {
            height: 9px;
            width: 9px;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          height: 7px;
          width: 7px;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        display: block;
      }
    }

    &-onebyone .carousel-inner .carousel-item {
      margin-right: initial;
    }

    &-item {
      margin-right: 0;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      &-left,
      &-right {
        &.active {
          + .carousel-item-next.carousel-item-left,
          + .carousel-item-prev.carousel-item-right {
            position: relative;
            visibility: visible;
            display: block;
          }
        }
      }

      &-left {
        &.active + .carousel-item-next.carousel-item-left {
          transform: translate3d(-100%, 0, 0);
        }
      }

      &-right {
        &.active + .carousel-item-prev.carousel-item-right {
          transform: translate3d(100%, 0, 0);
        }
      }

      &-next,
      &-prev {
        &.carousel-item-left,
        &.carousel-item-right {
          + .carousel-item,
          + .carousel-item + .carousel-item {
            position: relative;
            visibility: visible;
            display: block;
          }

          @include respond-to-min-width($max-mobile-width) {
            + .carousel-item + .carousel-item + .carousel-item,
            + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
              position: relative;
              visibility: visible;
              display: block;
            }
          }
        }
      }

      &-next {
        &.carousel-item-left {
          + .carousel-item,
          + .carousel-item + .carousel-item {
            transform: translate3d(-100%, 0, 0);
          }

          @include respond-to-min-width($max-mobile-width) {
            + .carousel-item + .carousel-item + .carousel-item,
            + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
              transform: translate3d(-100%, 0, 0);
            }
          }
        }
      }

      &-prev {
        &.carousel-item-right {
          + .carousel-item,
          + .carousel-item + .carousel-item {
            transform: translate3d(100%, 0, 0);
          }

          @include respond-to-min-width($max-mobile-width) {
            + .carousel-item + .carousel-item + .carousel-item,
            + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
              transform: translate3d(100%, 0, 0);
            }
          }
        }
      }
    }

    &-inner {
      .active,
      .active + .carousel-item {
        display: block;
      }

      .carousel-item {
        &.active {
          &:not(.carousel-item-right) {
            &:not(.carousel-item-left),
            &:not(.carousel-item-left) + .carousel-item {
              transition: none;
            }

            @include respond-to-min-width($max-mobile-width) {
              &:not(.carousel-item-left) + .carousel-item + .carousel-item,
              &:not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
                transition: none;
              }
            }
          }

          @include respond-to-max-width($max-mobile-width) {
            + .carousel-item + .carousel-item {
              position: absolute;
              top: 0;
              right: -50%;
              z-index: -1;
              display: block;
              visibility: visible;
            }
          }

          @include respond-to-min-width($max-mobile-width) {
            + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
              position: absolute;
              top: 0;
              right: -25%;
              z-index: -1;
              display: block;
              visibility: visible;
            }
          }
        }
      }

      &-next,
      &-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
      }

      &-prev {
        &.carousel-item-right {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          visibility: visible;
        }
      }

      @include respond-to-min-width($max-mobile-width) {
        .active + .carousel-item + .carousel-item,
        .active + .carousel-item + .carousel-item + .carousel-item {
          display: block;
        }
      }
    }
  }
}
