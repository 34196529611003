$card-header-font-size-desktop: 20px;
$card-header-font-size-mobile: 16px;
$summary-label-font-size-desktop: 16px;
$summary-label-font-size-mobile: 14px;
$summary-value-font-size: 14px;
$robotoFont: "Roboto", sans-serif;

.card {
  &-header {
    padding: 12px 20px;

    &:not(.card-header-your-cart) {
      background-color: $medium-light-gray;
    }

    &-custom {
      color: $primary-color-2;
      font-size: 1.25rem;
      font-weight: $font-weight-medium;
      font-family: $main-font;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $card-header-font-size-mobile;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      padding: 10px;
    }
  }

  &-body {
    padding: 15px 20px;

    .order-detail-section-divider,
    .order-help-items-divider {
      border-top: 1px dashed $regular-light-gray;
      margin: 15px -20px;

      @include respond-to-max-width($max-mobile-width) {
        margin: 13px -10px;
      }
    }

    .order-help-items-divider {
      border-top: 1px solid $regular-light-gray;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    span {
      &.summary {
        &-details {
          &.order-date,
          &.order-status,
          &.order-number {
            font-weight: $font-weight-medium;
            font-size: $summary-label-font-size-desktop;

            @include respond-to-max-width($max-mobile-width) {
              font-size: $summary-label-font-size-mobile;
            }
          }
        }
      }
    }

    .summary {
      &-section-label {
        font-family: $main-font;
        font-weight: $font-weight-medium;
        font-size: $summary-label-font-size-desktop;
        color: #333;

        @include respond-to-max-width($max-mobile-width) {
          font-size: $summary-label-font-size-mobile;
        }
      }

      &-details {
        &.leading-lines {
          margin-bottom: 0;
        }
      }
    }

    .payment-details,
    .summary-details {
      font-family: $main-font;
      font-weight: $font-weight-regular;
      font-size: $summary-value-font-size;
      color: $black-text;
    }

    @include respond-to-max-width($max-mobile-width) {
      padding: 13px 10px;
    }
  }
}
