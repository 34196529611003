[data-action="Address-List"] {
  .breadcrumb-container {
    padding: 0.75rem;
  }

  .addresses {
    @include respond-to-max-width($max-mobile-titles-width) {
      padding: 0 20px;
    }
  }
}

[data-action="Address-AddAddress"] {
  .breadcrumb-container {
    padding: 0 5px;
  }
}

[data-action="Account-EditPassword"] .pass-title-container {
  .password-title {
    font-size: 30px;
    border-bottom: 1px solid #cacaca;
    margin: 0 19px;
    max-width: 100%;
    padding-bottom: 10px;

    @include respond-to-min-width(1342px) {
      margin: 0 31px;
    }

    @include respond-to-between(545px, 1341px) {
      margin: 0 44px;
    }
  }
}

@include respond-to-between(769px, 991px) {
  [data-action="Address-List"] {
    .breadcrumb-container {
      margin-left: -17px;
      padding: 0.75rem 0;
    }
  }

  [data-action="PaymentInstruments-List"] {
    .breadcrumb-container {
      padding: 0.75rem 0;
    }
  }

  [data-action="PaymentInstruments-AddPayment"] {
    .breadcrumb-container {
      padding: 0;
    }
  }
}

.back-to-account-link,
.back.text-center a,
.profile-back-to-account-link {
  font-family: $main-font;
  font-weight: 500;
  margin-bottom: 30px;
  color: #000;
  font-size: 16px;
}
