.cross-banner-link {
  font-weight: 500;
  font-family: $main-font;
  color: #121212;
  font-size: 14px;
  text-decoration: underline;
}

.ver-todo-link {
  display: block;
  text-align: right;
  margin-top: 30px;
}

.grid-loop {
  &:last-child {
    @include respond-to-min-width(544px) {
      display: none;
    }

    @include respond-to-max-width(545px) {
      display: block;
    }
  }

  @include respond-to-max-width(545px) {
    font-size: 12px;
  }
}
