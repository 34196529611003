header ~ #maincontent .container {
  @import "~instorepickup/product/pdpInstoreInventory";
  @import "../components/product/clickAndCollectPDP";

  .card-body {
    &.shipping-content {
      .shipping-form {
        .shipping-address {
          .nav-tabs {
            margin: 20px 10px 0;
            flex-wrap: initial;
          }

          .tab-container {
            ul .nav {
              flex-wrap: nowrap;
            }

            .delivery-shipment-accordion {
              margin-top: 1rem;

              &.hidden {
                visibility: hidden;
                height: 0;
                margin-top: 0;
              }
            }

            .nav-item {
              border-bottom: 0.0625rem solid #dee2e6;

              a {
                &.nav-link {
                  cursor: pointer;
                  font-size: 16px;
                  color: $primary-color-2;
                  font-weight: $font-weight-medium;
                  align-items: center;
                  padding: 0.5rem 0;

                  label.shipping-method-radio-button {
                    padding-right: 0.6875rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .nav-ico-shipping {
                    width: 22px;
                    margin-right: 5px;
                    display: flex;
                    justify-content: center;

                    i {
                      margin-right: 0;

                      &.fa-map-marker{
                        font-size: 19px;
                        width: 16px;
                      }
                    }

                    img.shipping-pickit-img {
                      width: 19px;
                    }

                    &.fa-map-marker{
                      font-size: 19px;
                      width: 16px;
                    }
                  }

                  &[aria-selected="true"] {
                    border-bottom: none;
                    border-color: $secondary-color-1;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                  }

                  @media screen and (max-width: $max-mobile-width) {
                    font-size: 14px;
                    padding-left: 10px;
                    padding-right: 10px;
                  }

                  &:not(.btn-primary):not(.btn-outline-primary) {
                    color: $black-text;
                  }
                }

                label.shipping-method-radio-button {
                  font-family: $main-font;
                  font-size: 0.9375rem;
                  font-weight: $font-weight-regular;
                  display: flex;
                  align-items: center;

                  &::before {
                    content: " ";
                    display: inline-block;
                    position: relative;
                    width: 1.0625rem;
                    height: 1.0625rem;
                    border-radius: 0.6875rem;
                    border: 0.0625rem solid $primary-color-2;
                    background-color: $secondary-color-2;
                  }
                }

                input[type="radio"] {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  background: $secondary-color-2;

                  &:checked + label.shipping-method-radio-button::after {
                    border-radius: 0.6875rem;
                    width: 0.4375rem;
                    height: 0.4375rem;
                    position: absolute;
                    content: " ";
                    display: block;
                    background: white;
                  }

                  &:checked + label.shipping-method-radio-button::before {
                    background-color: $primary-color-2;
                  }
                }

                .shipping-title-span {
                  margin-top: 0.0625rem;
                  margin-left: 0.1875rem;
                }
              }

              &:nth-last-child(-n + 1 of .nav-item) {
                margin-bottom: 1.25rem;
              }
            }
          }

          .row-retiro-sucursal {
            @media (min-width: 1200px) {
              text-align: center;
            }
          }

          .instore-pickup,
          .cc-pickup,
          .pickit-store-search {
            padding: 15px 0;

            label {
              font-weight: $font-weight-medium;
              color: $primary-color-2;
              font-size: 16px;
              font-family: $main-font;
            }

            input {
              width: 75%;
              height: 40px;
              border-color: $primary-color-2;
              border-width: 1px;
              border-bottom-left-radius: 3px;
              border-top-left-radius: 3px;
              padding-left: 10px;

              &::placeholder {
                font-size: 16px;
                color: $dark-gray;
                font-weight: $font-weight-regular;
              }
            }

            button {
              margin-bottom: 4px;
              margin-left: -7px;
              width: 25%;
              height: 40px;
              border: none;
              background-color: $primary-color-2;
              border-radius: 0 0.1875rem 0.1875rem 0;
            }
          }

          .selected-store {
            display: none;
            padding: 15px 0;
            border-bottom: 1px dashed $regular-light-gray;
            border-top: 1px dashed $regular-light-gray;

            .selected-store-header {
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 15px;

              i {
                float: right;
                cursor: pointer;
              }
            }

            .store-name {
              font-weight: 500;
              font-size: 16px;
            }

            address {
              font-size: 14px;
            }

            p {
              margin: 0;
            }
          }

          .shipment-selector-block {
            [for="shipmentSelector-default"] {
              font-size: 16px;
              font-family: $main-font;
              font-weight: $font-weight-medium;
              color: $primary-color-2;
            }

            .addressSelector {
              border-color: $primary-color-2;
              font-size: 16px;
              font-weight: 400;
              font-family: $main-font;
              color: $primary-color-2;
              border-radius: 3px;
              -webkit-appearance: none;
              background:
                url(
                  data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=
                  )
                no-repeat 99% 50%;

              &:invalid {
                color: #999;
              }
            }
          }

          .shipping-method-block {
            margin-top: 10px;

            h3 {
              font-size: 16px;
              font-family: $main-font;
              font-weight: $font-weight-medium;
              color: $primary-color-2;
            }

            .shipping-method-list {
              .form-check {
                margin-bottom: 10px;
                padding-left: 3px;

                label {
                  font-family: $main-font;
                  font-size: 15px;
                  font-weight: $font-weight-regular;

                  .display-name {
                    margin-right: 5px;
                  }

                  .arrival-time {
                    color: $text-gray;
                  }

                  &::before {
                    content: " ";
                    display: inline-block;
                    position: relative;
                    top: 4px;
                    right: 2px;
                    width: 17px;
                    height: 17px;
                    border-radius: 11px;
                    border: 1px solid $primary-color-2;
                    background-color: $secondary-color-2;
                  }
                }

                input[type="radio"] {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  background: $secondary-color-2;

                  &:checked + label::after {
                    border-radius: 11px;
                    width: 7px;
                    height: 7px;
                    position: absolute;
                    top: 11px;
                    left: 6px;
                    content: " ";
                    display: block;
                    background: white;
                  }

                  &:checked + label::before {
                    background-color: $primary-color-2;
                  }
                }
              }

              .shipping-method-pricing {
                color: $text-light-gray;
                font-size: 16px;
                font-family: $main-font;
                font-weight: $font-weight-medium;

                .shipping-cost {
                  padding-left: 5px;
                }
              }
            }
          }

          .btn-show-details {
            display: none;
          }

          .save-address {
            text-decoration: underline;
            font-size: 14px;
            margin: unset;
            padding-top: 15px;
            display: block;
            color: $custom-link-color;
            font-weight: $font-weight-medium;
            cursor: pointer;

            .info-icon {
              .icon {
                border-radius: 1rem;
                background-color: $custom-link-color;
                width: 1rem;
                height: 1rem;
                line-height: 1rem;
              }
            }

            &.save-address-custom {
              text-decoration: underline;
              font-size: 14px;
              margin: auto;
              padding-top: 15px;
              display: block;
              color: $custom-link-color;
              font-weight: $font-weight-medium;
              cursor: pointer;
            }
          }

          .btn-add-new {
            text-decoration: underline;
            font-size: 14px;
            margin: auto;
            padding-top: 15px;
            display: block;
            color: $custom-link-color;
            font-weight: $font-weight-medium;

            .info-icon {
              .icon {
                border-radius: 1rem;
                background-color: $custom-link-color;
                width: 1rem;
                height: 1rem;
                line-height: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.store-locator-modal{
  .modal-header{
    padding: 1rem;

    button span{
      vertical-align: middle;

      img.close-img {
        width: 16px;
      }
    }
  }
}

header {
  ~ {
    #maincontent {
      .container {
        a {
          &:not(.btn-primary) {
            &:not(.btn-outline-primary).btn-add-new {
              text-decoration: underline;
              font-size: 14px;
              color: $custom-link-color;
              font-weight: $font-weight-medium;
            }
          }
        }
      }
    }
  }
}

.instorepickup-warning-message {
  font-weight: bolder;
}

.shipment-button {
  display: flex;
  justify-content: space-between;
  padding: 0;

  &:focus {
    box-shadow: none;
  }

  p {
    margin: 0;
    font-weight: 500;
  }
}

header ~ #maincontent .container .shipping-section .single-shipping .card-header.card-header-shipment {
  background: none;
  padding: 12px 0;
}

.collapse-shipment {
  visibility: hidden;
  height: 0;
}

.invalid-feedback-sp,
.invalid-feedback-store {
  display: block;
  margin: 7px 0 0 1rem;
  position: relative;
  max-width: 80%;
  line-height: 1;
  font-size: 12px;
  margin-left: 0%;
  color: red;

  @media screen and (max-width: $max-mobile-width) {
    bottom: 0;
    margin-left: 0%;
  }
}

.form-check-store,
.form-check-correoarg {
  padding-left: 0;
}

.container-radio-buttons {
  padding-left: 0;
}

.form-control-label-sp {
  text-align: left;
  margin-top: 20px;
}

.form-control-label-sp::before {
  content: "*";
  color: #c00;
}

.form-check-label-correoarg,
.form-check-label-store {
  display: inline;
}

.form-check-input-store::before,
.form-check-input-correoarg::before {
  content: " ";
  display: inline-block;
  position: relative;
  top: -3px;
  right: 2px;
  width: 17px;
  height: 17px;
  border-radius: 11px;
  border: 1px solid #121212;
  background-color: #ffffffde;
}

.form-check-input-store:checked::before,
.form-check-input-correoarg:checked::before {
  background-color: black;
  cursor: pointer;
}

@media only screen and (min-width: 1199px) {
  .form-check-label-store::after {
    border-radius: 11px;
    width: 7px;
    height: 7px;
    position: absolute;
    top: 8px;
    left: 61px;
    content: " ";
    display: inline;
    background: #fff;
    cursor: pointer;
  }

  .form-check-label-correoarg::after {
    border-radius: 11px;
    width: 7px;
    height: 7px;
    position: absolute;
    top: 8px;
    left: 23px;
    content: " ";
    display: inline;
    background: #fff;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1199px) {
  .form-check-label-store::after {
    border-radius: 11px;
    width: 7px;
    height: 7px;
    position: absolute;
    top: 8px;
    left: 3px;
    content: " ";
    display: inline;
    background: #fff;
    cursor: pointer;
  }

  .form-check-label-correoarg::after {
    border-radius: 11px;
    width: 7px;
    height: 7px;
    position: absolute;
    top: 8px;
    left: 3px;
    content: " ";
    display: inline;
    background: #fff;
    cursor: pointer;
  }
}
