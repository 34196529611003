.page-designer-reference {
  .editorialRichTitle-component-container {
    margin-top: 30px;

    h1 {
      font-size: 30px;
      font-weight: $font-weight-medium;
      padding-bottom: 25px;
      font-family: $main-font;
      color: #000;
      margin-bottom: 0;

      @include respond-to-min-width($max-mobile-width) {
        margin-left: 10px;
      }

      @include respond-to-max-width($max-mobile-width) {
        text-align: left;
        font-size: 28px;
        padding-left: 10px;
        padding-bottom: 0;
      }
    }

    @include respond-to-min-width($max-mobile-width) {
      margin-top: 50px;
      border-bottom: 1px solid #f2f2f2;
    }
  }
}
