.cookies-policy-container {
  display: flex;
  position: fixed;
  top: 84%;
  left: 0;
  right: 0;
  width: 60rem;
  margin: 0 auto;
  padding: 1.5625rem;
  background-color: #181817;
  color: #fff;
  font-size: 0.875rem;
  z-index: 10000;

  @media screen and (max-width: 991px) {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    top: auto;
  }

  &-row {
    inline-size: -webkit-fill-available;

    .cookies-title {
      font-size: 1rem;
      font-family: $main-font;
    }

    .landing-cookies-page-link {
      font-weight: bold;
    }

    p {
      margin-bottom: 0.625rem;
    }

    a span {
      color: #fff;
      text-decoration: underline;
    }

    .cookies-close-popup-col {
      display: flex;
      justify-content: end;
      align-items: center;

      @media screen and (max-width: 991px) {
        justify-content: center;
        margin-top: 1.875rem;
      }

      .cookies-close-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 3.125rem;
        padding: 0.875rem;
        background-color: #fff;
        color: #181817;
        border-radius: 1.5625rem;
        text-align: center;
        font-weight: 600;

        @media screen and (max-width: 991px) {
          width: 19.68rem;
        }
      }
    }
  }
}
