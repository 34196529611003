$max-mobile-width: 768px;
$max-small-device: 543px;

.search-results {
  max-width: unset;
  font-family: $main-font;

  &#product-search-results {
    padding: 0 20px;
    position: relative;
    z-index: 0;

    @include respond-to-min-width(1342px) {
      padding: 0 24px;
    }

    @include respond-to-between(545px, 1341px) {
      padding: 0 39px;
    }
  }

  hr {
    display: none;
  }

  .search-nav {
    border: none;
  }

  .filter-header {
    @include respond-to-max-width($max-mobile-width) {
      .header-bar {
        height: 70px;

        .logo {
          height: 25px;
          left: 27px;
          position: absolute;
          top: 23px;
        }

        button {
          font-size: 1rem;
          font-weight: $font-weight-regular;
          opacity: 1;
          position: absolute;
          right: 25px;
          top: 26px;

          .fa-close::before {
            font-size: 20px;
            color: $filters-close;
          }
        }
      }
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    .button__filter-shadow {
      .btn-primary {
        border-color: $primary-color-2;
      }
    }
  }

  .filter-results {
    border-radius: 25px;

    @include respond-to-max-width($max-mobile-width) {
      background-color: $primary-color-2;
      border-color: $primary-color-2;

      &:active {
        background-color: $primary-color-2 !important; //overriding boostrap blue color
        border-color: $primary-color-2 !important; //overriding boostrap blue color
      }

      &:hover {
        border-color: $primary-color-2;
      }

      &.btn-primary {
        &.focus,
        &:focus {
          box-shadow: unset;
        }
      }
    }
  }

  .result-count {
    border-bottom: 0.5px solid $light-gray;

    .search-result-count {
      .count {
        font-weight: $font-weight-bold;
      }

      .search-keywords {
        font-weight: $font-weight-bold;
      }
    }
  }

  .no-result-container {
    margin: auto;
    position: relative;

    i {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 30px;
    }

    .no-results {
      font-size: 30px;
      font-weight: $font-weight-medium;
      text-align: center;
      padding: 50px 0 30px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 24px;
      }
    }

    .redirect-home {
      text-decoration: underline;
      text-align: center;
    }

    @include respond-to-max-width($max-mobile-width) {
      width: 100%;
      margin-top: 60px;
    }
  }

  .custom-select {
    border: 1px solid $primary-color-2;
    padding-bottom: 8px;
    padding-top: 8px;
    border-radius: 3px;
    width: 100%;
    display: block;
    margin-inline: auto;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    @include respond-to-max-width($max-mobile-width) {
      width: 100%;
    }
  }

  .refinement-bar {
    @include respond-to-max-width($max-mobile-width) {
      position: fixed;
      display: none;
      top: 0;
      left: 0;
      background: white;
      z-index: 99;
      height: 100%;

      .header-bar {
        position: relative;
      }

      .refinements-container {
        overflow: auto;
        max-height: 101vh;
        padding-bottom: 5rem;
      }
    }

    .refinement-lanzamiento {
      display: none;
    }

    @include respond-to-max-width($max-small-device) {
      height: 101vh;
    }
  }

  .refinements {
    .pills-container {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      margin-top: 8px;

      @include respond-to-max-width($max-mobile-width) {
        display: none;
      }
    }

    .card {
      border: none;

      &-header {
        background-color: transparent;

        button.title {
          color: $primary-color-2;
          font-size: 18px;
          font-weight: $font-weight-bold;
          text-align: left;
          padding: 0;
        }

        h2 {
          font-size: 16px;
          font-weight: $font-weight-bold;
          color: $primary-color-2;
        }
      }

      &-body {
        padding: 0;

        .values {
          padding: 0 15px;
          list-style: none;

          .show-more-options {
            text-decoration: underline;
            cursor: pointer;
          }

          @include respond-to-max-width($max-mobile-width) {
            padding: 0 12px;
          }

          .price-refinement {
            padding: 0;
          }

          .button {
            padding: 0;
          }

          li {
            padding-bottom: 10px;
            padding-inline: 0;

            &.color-attribute {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              padding: 0;
            }

            button {
              color: $primary-color-2;
              border: none;
              background-color: transparent;
              display: flex;
              align-items: baseline;

              i {
                color: $primary-color-2;
              }

              span {
                font-family: $main-font;
                font-weight: $font-weight-medium;

                &.swatch-circle {
                  width: 25px;
                  height: 25px;

                  &.selected::after {
                    font-size: inherit;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .card-product-plp {
    padding-bottom: 30px;
    background-color: white;
  }
}

.pills-container {
  display: none;
  flex-flow: row;
  flex-wrap: wrap;

  .refinement-pill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $regular-light-gray;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 10px;

    h2 {
      margin: 0;
      font-size: 14px;
      padding: 6px 10px;
    }

    .btn-close-icon {
      cursor: pointer;
      background-color: $text-color-footer;
      height: 29px;
      width: 25px;

      &::after {
        font-family: "FontAwesome";
        content: "\f00d";
        color: $background;
        position: relative;
        left: 6px;
        top: 1.4px;
      }
    }
  }

  .refinement-pill-lanzamiento.refinement-pill-lanzamiento {
    display: none;
  }

  @include respond-to-max-width($max-mobile-width) {
    display: flex;
    padding: 15px 6px;
  }
}

[data-action="Search-Show"] #maincontent {
  background: $background;
}

.show-more {
  button {
    color: $black-text;
    font-size: 14px;
    font-weight: $font-weight-medium;
    border: solid 1px $black-text;
    border-radius: 1.56rem;
    background: #fff;
    text-decoration: none;
    transition-duration: 0.2s;
    width: 13.75rem;
    height: 3.125rem;

    &:hover {
      background: $black-text;
      color: #fff;
      text-decoration: none;
      border-color: $black-text;

      .arrow-element {
        border-color: #fff;
      }
    }

    &:active {
      color: $black-text;
      background: $black-text !important; //overriding bootstrap blue color
      border-color: $black-text !important; //overriding bootstrap blue color
      outline: none;
      text-decoration: none;
    }

    &:focus {
      outline: none;
      text-decoration: none;
      background: $black-text !important;
      color: #fff;

      .arrow-element {
        border-color: #fff;
      }
    }

    .arrow-element {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-bottom: 0.12rem;
      margin-left: 0.5rem;
      transition-duration: 0.2s;
    }

    .down-direction {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    @include respond-to-max-width($max-mobile-width) {
      margin-top: 0;
      width: 11.25rem;
      height: 2.25rem;
    }
  }
}

.numberVal {
  position: relative;

  input {
    padding: 0;
    font-size: 12px;
    height: auto;
    width: 100%;
    border: none;
    background: transparent;
  }
}
