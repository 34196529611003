// Pricing
$sales-price-font-size-desktop: 30px;
$strike-through-price-font-size-desktop: 24px;

$sales-price-font-size-mobile: 30px;
$strike-through-price-font-size-mobile: 18px;

.prices {
  padding: 0;

  span {
    display: flex;
    align-items: center;

    .sales {
      font-size: $sales-price-font-size-desktop;
      margin-right: 12px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $sales-price-font-size-mobile;
      }
    }

    .strike-through {
      font-size: $strike-through-price-font-size-desktop;
      margin-left: 12px;
      font-weight: normal;
      color: #cacaca;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $strike-through-price-font-size-mobile;
      }
    }
  }
}
