// zoom images

.carousel-item {
  * {
    border: 0;
    margin: 0;
    padding: 0;
  }

  p {
    position: absolute;
    top: 3px;
    right: 28px;
    color: #555;
    font: bold 13px/1 sans-serif;
  }

  .zoom {
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 33px;
      height: 33px;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      display: block;

      &::selection {
        background-color: transparent;
      }
    }
  }

  .img-zoom img:hover {
    cursor: url('../images/lupa.png'), auto;
  }
}
