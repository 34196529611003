
// START HEADER CHECKOUT BANNER STYLES
.header-banner {
  &.checkout {
    .container {
      height: 100%;
      position: relative;
      display: table;

      .content {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
        margin-right: unset;
        padding-top: unset;
        padding-bottom: unset;
        font-size: 14px;
      }

      .close-button {
        position: absolute;
        right: 20px;
        top: 6px;

        .close {
          background-color: $primary-color-1;
          font-weight: $font-weight-light;
          font-size: 30px;
        }
      }
    }
  }
}

@include respond-to-min-width($max-mobile-width) {
  .header-banner {
    &.checkout {
      .container {
        .content {
          font-size: 18px;
        }

        .close-button {
          right: 0;
        }
      }
    }
  }
}
// FINISH HEADER CHECKOUT BANNER STYLES

// START HEADER CHECKOUT STYLES
nav[role="navigation"] {
  .header {
    &.checkout {
      height: 100px;

      .col-12 {
        height: 100%;

        .navbar-header {
          &.brand {
            position: relative;
            left: unset;
            width: 100%;
            text-align: left;
            display: table;
            padding-top: 0;
            height: 100%;

            @include respond-to-max-width($max-tablet-width) {
              position: absolute;
            }

            .logo-home {
              position: relative;
              top: unset;
              transform: none;
              left: unset;
              display: table-cell;
              vertical-align: middle;
              width: 100%;

              .hidden-md-down {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to-max-width($max-tablet-width) {
  nav[role=navigation] {
    .header {
      &.checkout {
        height: 70px;

        .col-12 {
          height: 100%;

          .navbar-header {
            height: 100%;
            margin-left: unset;
            top: unset;
            transform: none;

            .logo-home {
              .d-lg-none {
                width: 100px;
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
// FINISH HEADER CHECKOUT STYLES
