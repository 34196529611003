$videoMarginTop: 16px;

.page-designer-reference {
  .videoLink-component-container {
    margin-bottom: 30px;

    .col-12.embed-responsive.grid {
      &-1x1 {
        padding-top: calc(500 / 1024 * 100%);
        margin-top: $videoMarginTop;
        margin-bottom: -($videoMarginTop);
      }

      &-1x2 {
        padding-top: calc(500 / 1024 * 100%);
      }

      &-1x3 {
        padding-top: calc(990 / 1024 * 100%);
      }
    }

    .video {
      &-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        padding-top: 20px;
        position: relative;

        iframe,
        object,
        embed {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}
