/* google fonts import */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

/* fonts */
$robotoFont: "Roboto",
  sans-serif;

// Cart header
$title-font-size: 30px;
$title-font-size-mobile: 24px;
$num-of-products-font-size: 14px;
$continue-shopping-link-font-size: 14px;
$continue-shopping-link-font-size-mobile: 12px;
$need-help-font-size: 14px;

.tooltip-FreeShipping {
  position: relative;
  display: inline-block;
  top: 30px;

  .tooltiptext-FreeShipping {
    .tooltiptext-content {
      display: flex;
      align-items: center;

      .approaching-discounts {
        display: inline-block;
        line-height: 1.2;
        position: relative;
        bottom: 40px;

        .single-approaching-discount {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: $main-font;
          font-size: $num-of-products-font-size;
          width: 380px;
          max-width: 380px;
          background-color: #dbdcda;
          color: #000;
          text-align: center;
          border-radius: 6px;
          padding: 8px;
          position: absolute;
          top: 15px;
          left: 185px;
          transform: translateX(-50%);
          transition: opacity 0.3s;
          word-wrap: break-word;
          z-index: 101;
          border: none;
          margin: none;

          .discount-value {
            font-weight: bold;
            color: #000;
            background: none;
            border: none;
          }

          .tooltiptext-spans-content {
            max-width: 380px;
            width: 380px;
            padding: 0 10px;
            display: flex;

            &::before {
              content: "";
              display: inline-block;
              width: 49px;
              height: 28px;
              margin-right: 9px;
              margin-top: 6px;
              background-image: url(../images/delivery-truck.svg);
              background-size: cover;

              @include respond-to-max-width(500px) {
                margin-right: unset;
                margin-left: 9px;
              }
            }
          }

          .spans-text {
            width: 363px;
          }

          @include respond-to-max-width(500px) {
            width: 360px;
            left: 168px;
          }
        }
      }
    }

    .freeShippingValueField {
      font-weight: bold;

      &::before {
        font-weight: bold;
      }
    }

    .closeFreeShipping-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 0;
      margin: 8px 0;
      opacity: 1;
      outline: none;
      padding: 0;
      background-color: transparent;
      border: 0;
      appearance: none;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;

      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 5%;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #dbdcda;
      }
    }

    .st0 {
      fill: #000;
    }

    #Capa_1 {
      width: 55px;
    }

    span {
      white-space: initial;
      padding: 8px 0 15px;
    }

    @include respond-to-max-width(400px) {
      max-width: 350px;
      left: 168px;
    }
  }
}

.cart {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 50%;
    width: 95.5vw;
    transform: translateX(-50%);
    position: relative;
    padding: 20px 0;
    height: 0;
    top: 22px;
    z-index: 1;
    border-bottom: 1px solid #cacaca;

    .need-help-container {
      font-family: $main-font;
      font-size: $need-help-font-size;
      font-weight: $font-weight-regular;

      .phone-number {
        font-weight: $font-weight-medium;
      }

      @include respond-to-max-width($max-mobile-width) {
        display: none;
      }
    }

    .continue-shopping-link {
      font-family: $main-font;
      font-size: $continue-shopping-link-font-size;
      font-weight: $font-weight-medium;
      text-decoration: underline;
      color: $custom-link-color;
      position: relative;
      bottom: 32px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $continue-shopping-link-font-size-mobile;
      }
    }

    @include respond-to-max-width(991px) {
      margin-top: -30px;
    }

    @include respond-to-between(991px, 1200px) {
      width: 93vw;
    }

    @include respond-to-between(1200px, 1440px) {
      width: 94.5vw;
    }
  }

  &-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    margin-bottom: -15px;
    width: 100vw;
    padding: 0 40px;
    margin-left: calc(-50vw + 50%);

    .page-title {
      font-family: $main-font;
      font-size: $title-font-size;
      color: black;
      font-weight: $font-weight-medium;
      padding: 0;
      position: unset;
      background-color: unset;
      margin: 0;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $title-font-size-mobile;
      }

      &::before {
        content: none;
      }
    }

    .number-of-items {
      font-family: $main-font;
      font-size: $num-of-products-font-size;
      color: black;
      font-weight: $font-weight-regular;
      margin-left: 11px;

      @include respond-to-max-width($max-mobile-width) {
        color: $black-text;
        margin-left: 10px;
        margin-top: 0;
      }
    }

    @include respond-to-max-width(991px) {
      margin-top: 13px;
      margin-bottom: 13px;
      padding-left: 10px;
    }
  }
}
