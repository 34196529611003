$black-text: #000;
$gutter: 1rem;
$user-bg-color: #f2f2f2;

#mainContainer,
[data-action="Cart-Show"] {
  .header {
    .right__menu {
      &__userName {
        background-color: $user-bg-color;
        user-select: none;

        .fa-user-o {
          color: $black-text;
          margin-right: $gutter;
        }
      }
    }

    &__right{
      .fa-user-o {
        margin-bottom: ($gutter / 4);
        cursor: pointer;
      }
    }
  }
}
