
.payment-form {
  a{
    box-shadow: none !important;
  }

  .card-header {
    background-color: $medium-light-gray;

    &.tab-payment {
      background-color: transparent;
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-bottom: 0.5rem;

      li{
        span{
          font-size: 16px;
        }
      }
    }
  }

  .new-billing-address {
    button {
      &.cancel-new-billing-address {
        border: 0;
        background-color: transparent;
        color: $primary-color-2;
        text-decoration: underline;
        display: block;
        margin: 0 auto;
      }
    }

    &-divider {
      width: 100%;
      border-top: 1px dashed $medium-light-gray;
    }
  }
}

.billing-address-block {
  .address-selector-block {
    .btn {
      &-show-details {
        display: none;
      }

      &-add-new {
        margin: auto;
        text-decoration: underline;
        margin-top: 10px;
        color: $custom-link-color;
        font-weight: $font-weight-medium;
        font-size: 14px;
      }
    }
  }
}
