.suggestions {
  top: 5px;
  left: 0;
  width: 90%;
  z-index: 11;

  .container {
    .header {
      justify-content: flex-end;

      .col-sm-10 {
        flex: 0 0 80%;
        max-width: 80%;
        padding-left: 15px;
      }

      @include respond-to-max-width($max-mobile-width) {
        justify-content: flex-start;
      }
    }

    .items {
      .item {
        a {
          display: flex;
          justify-content: space-evenly;

          img {
            height: 50px;
            width: 50px;
            margin-top: 0.313em;
            margin-right: 15px;
          }

          .name {
            margin-bottom: 0;
            margin-top: 0.313em;
            overflow: auto;
            text-overflow: unset;
            white-space: unset;
          }

          p {
            width: 80%;
            text-align: left;
            margin-bottom: 0;
          }

          @include respond-to-max-width($max-mobile-width) {
            justify-content: flex-start;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          padding-left: 15px;

          &.term {
            > div {
              padding-left: 9px;
            }
          }
        }
      }
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    width: 100%;
  }
}
