$icon-arrow-color: #fff;
$dark-gray-carousel-icon: #999;

.experience-commerce_layouts-carousel {
  &::before {
    height: 36.25rem;
  }

  .carousel-control-next.carousel-control-next,
  .carousel-control-prev.carousel-control-prev {
    background-color: $dark-gray-carousel-icon;
    border-radius: 50%;
    font-size: 1.8rem;
    height: 3.125rem;
    opacity: 1;

    i {
      color: $icon-arrow-color;
    }

    @include respond-to-max-width($max-mobile-width) {
      font-size: 20px;
      height: 36px;
      width: 36px;
    }
  }

  .carousel-control-next.carousel-control-next {
    right: 1.25rem;
  }

  .carousel-control-prev.carousel-control-prev {
    left: 1.25rem;
  }
}
