.error-not-found {
  max-width: 425px;

  .fa-frown-o {
    display: block;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 15px;
    font-size: 26px;

    @include respond-to-min-width($max-mobile-width) {
      font-size: 60px;
      margin-top: 200px;
    }
  }

  .error-message {
    margin: 0;
    font-size: 24px;
    margin-bottom: 15px;

    @include respond-to-min-width($max-mobile-width) {
      margin: 0;
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  .back-to-home {
    text-align: center;

    .error {
      &.continue-shopping {
        text-decoration: underline;
      }
    }
  }
}

[data-action="RedirectURL-Start"] {
  background-color: unset;

  #maincontent {
    background-color: unset;
  }

  footer {
    display: none;
  }
}
