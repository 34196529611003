.experience-component.experience-commerce_assets-cvs_page {
  font-family: $main-font;
}

.cvs {
  &-title {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
    font-size: 30px !important;
    font-weight: $font-weight-medium !important;
    color: $black-text !important;
    position: relative;
    padding: 0 20px 10px 0;
    border-bottom: 1px solid #cacaca;

    @include respond-to-min-width(768px) {
      left: 50%;
      width: 95.5vw;
      transform: translateX(-50%);
    }
  }

  &-subtitle {
    margin-top: 40px;
    text-align: center;

    @include respond-to-max-width($max-mobile-width) {
      text-align: left;
    }
  }

  &-formTitle {
    margin: 0 auto 20px;

    @include respond-to-max-width($max-mobile-width) {
      text-align: left;
      margin: 0 0 20px;
    }
  }
}

.cv {
  &-options-container {
    display: flex;
    justify-content: center;
  }

  &-option-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 250px;
    margin: 20px 12px;
    background-color: #f0f0f0;

    @include respond-to-max-width($max-mobile-width) {
      width: 165px;
      height: 160px;
      margin: 16px 6px;
    }
  }

  &-form-container {
    max-width: 850px;
    margin: 40px auto;

    .form-msg {
      font-weight: bold;
    }

    .form-group-profile {
      position: relative;
    }

    .form-group-state {
      position: relative;
    }

    @include respond-to-max-width($max-mobile-width) {
      margin-left: 16px;
      margin-right: 16px;
      text-align: left;
    }
  }

  &-select-file {
    &-css,
    &-css2 {
      padding: 8px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #fff 0% 0% no-repeat padding-box;
      border: 1px solid #121212;
      border-radius: 25px;
      width: 175px;
      height: 36px;
    }

    &-css {
      margin: 15px;
    }

    &-css2 {
      display: block;
      margin: 15px auto;
    }
  }

  &-form {
    margin-top: 30px;
  }
}

#staff-btn,
#comercial-btn {
  &:hover {
    text-decoration: none;
  }

  span {
    font-weight: bold;
  }
}

.row-register-form-state {
  display: flex;
  align-items: end;
}

.page-designer-reference {
  .form-control {
    font-size: 1rem;

    &:focus {
      box-shadow: none;
      border-color: #999;
    }

    &.form-control-custom {
      border: none;
      border-bottom: 1px solid #999;
      border-color: #999;
      border-radius: 0;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      box-shadow: none !important;
      padding: 0;

      &::placeholder {
        color: #999;
      }

      &:focus {
        border-color: #999;
      }

      &:not(:placeholder-shown),
      &:focus {
        + .placeholder-label {
          transform: translateY(-30px);
          color: #999;
        }
      }
    }
  }

  input[type=submit].btn-block.btn-send-cv-form {
    background-color: $bg-color-btn-login;
    color: #fff;
    border-radius: 25px;
    text-transform: uppercase;
    height: 50px;
    width: 400px;
    margin: 0 auto;

    &:hover {
      background-color: $bg-color-btn-login;
      border-color: $bg-color-btn-login;
    }

    &:disabled {
      opacity: 0.6;
    }

    @include respond-to-max-width($max-mobile-width) {
      width: 344px;
      max-width: 100%;
    }
  }

  .btn-primary.btn-send-cv-form:not(:disabled):not(.disabled):active {
    background-color: $bg-color-btn-login;
  }

  .privacy-policy .custom-control-input.is-invalid ~ .custom-control-label{
    color: black;
  }
}

.drag-and-drop {
  &-container {
    text-align: center;
    padding: 30px;
    margin: 57px auto 30px;
    width: 300px;
    border: 1px dashed #999 !important;
    border-radius: 10px;

    .fa-upload {
      display: block;
      margin: 5px auto;
      font-size: x-large;
    }
  }

  &-text {
    font-weight: bold;
    font-size: 16px;
  }
}

.file-example-types,
.file-selected-name {
  display: block;
  color: #999;
  font-size: 12px;
}

.privacy-policy {
  a {
    font-weight: 500;
    color: #222;
    text-decoration: underline;
  }
}

.dragging {
  background-color: #f2f2f2;
}

.success-cvs {
  &-msg1 {
    font-family: $main-font;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    color: #559550;
  }

  &-msg2 {
    font-size: 16px;
  }

  &-a {
    display: block;
    margin-top: 36px;
    text-decoration: underline;
  }

  &-msg {
    width: 400px;
    text-align: center;
    margin: 150px auto;
    font-family: $main-font;

    .fa-check-circle-o-cv {
      font-size: 4rem;
      color: #559550;
    }

    @include respond-to-max-width($max-mobile-width) {
      width: 338px;
    }
  }
}

.custom {
  &-control {
    &-label {
      padding-top: 2px;

      &::after {
        cursor: pointer;
        background-color: white;
        border: 1px solid black;
        border-radius: 3px;

        @include respond-to-min-width($max-mobile-width) {
          left: 5px;
        }
      }

      @include respond-to-min-width($max-mobile-width) {
        &::before {
          left: 5px;
        }
      }
    }

    &-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
      background-color: $primary-color-2;
    }
  }

  &-checkbox {
    max-width: 405px;
    padding-left: 0;
    margin: 0 auto 25px;

    &.privacy-policy {
      margin: 40px auto 15px;
    }

    label {
      &::before,
      &::after {
        @include respond-to-min-width($max-mobile-width) {
          left: 0;
        }
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      padding-left: 25px;
    }
  }
}

.row-register-form .form-group-cv {
  margin-bottom: 35px !important;
}

.form-group-cv {
  position: relative;

  label {
    color: #999;
  }
}

.form-group-state {
  label {
    color: #999;
  }
}

@include respond-to-min-width(604px){
  .row-register-form {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &-state {
      display: flex;
      width: 48%;
    }
  }

  .form-group-cv {
    width: 48%;
  }

  .row-drag-and-drop {
    display: flex;
    justify-content: center;
  }
}
