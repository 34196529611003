// Variables de tamaños comunes
$padding-small: 8px;
$padding-medium: 16px;
$padding-large: 40px;

.carousel {
  &.hero {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
    height: unset;

    .row.carousel-inner {
      margin: 0;
    }
  }

  &-inner {
    margin: 0;
    overflow: hidden;

    @include respond-to-min-width($max-mobile-width) {
      max-width: 101%;
    }
  }

  &-item {
    padding: $padding-small;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }

    .component-figure {
      margin: 0;
    }
  }

  .pd-carousel-indicators {
    z-index: auto;

    li {
      width: 7px;
      height: 7px;
      border-radius: 10px;
      cursor: pointer;
      background-color: $carousel-indicators;
      margin-top: 1px;

      &.active {
        margin-top: 0;
        background-color: $carousel-indicator-active;
        width: 9px;
        height: 9px;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      margin-top: 15px;
    }
  }

  &.brands-carousel {
    margin-top: 30px;
    margin-bottom: 40px;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
    height: unset;
    padding: 0 $padding-large;

    .carousel-item {
      border-right: 1px solid $medium-light-gray;

      &:nth-child(6) {
        border-right: none;
      }
    }
  }

  &.promotions-carousel {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
    height: unset;
    padding: 0 $padding-large;
    margin-bottom: 10px;

    .carousel-item {
      padding: $padding-small;
    }

    @include respond-to-max-width($max-tablet-width) {
      padding: 0 $padding-medium 4.375rem;
    }
  }

  @include respond-to-min-width(769px) {
    &.indicators-md {
      &.slide {
        padding-bottom: 55px;
      }
    }
  }
}

.carousel-fade {
  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    z-index: 0;
    opacity: 1;
  }
}

.page-designer-reference {
  .carousel {
    &-title {
      font-family: $main-font;

      &.with-subtitle {
        margin-bottom: 10px;
      }

      @include respond-to-max-width($max-mobile-width) {
        margin-top: 10px;
      }
    }

    &-subtitle {
      font-family: $main-font;
    }
  }

  .btnCollapse {
    width: 35px;
    height: 35px;
    margin: 0 auto;
    background-color: $brand-identity;
    border-radius: 100%;
    border: none;
    color: $secondary-color-2;
    position: relative;
    bottom: 20px;
  }

  .divCollapse {
    height: 20px;
    background-color: $brand-identity;
    margin-bottom: -10px;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @include respond-to-min-width(543px) {
      display: none;
    }
  }
}

.experience-commerce_layouts-carousel .carousel-inner .product-tile {
  padding-bottom: 20px;
}
