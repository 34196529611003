@import "nav-item";

$customMobileBreak: 1340px;

.multilevel-dropdown {
  .nav-item {
    &.dropdown {
      outline: none;

      .menu-items-container {
        border-top: 0.5px solid $border-gray;
        z-index: 20;

        @include respond-to-min-width($max-mobile-width) {
          .options-container {
            margin: 0 auto;
          }
        }
      }

      .dropdown-menu {
        padding: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        position: initial;
        margin: 0;

        @include respond-to-min-width($customMobileBreak) {
          position: initial;
          box-shadow: none;
          column-count: var(--column-count);

          .four-items-container {
            flex-flow: column wrap;
            display: inline-flex;

            .dropdown-item {
              border: 0;

              &:hover {
                background-color: transparent;
              }

              .dropdown-link {
                font-family: $main-font;
                margin: 0;
              }
            }
          }

          .dropdown-item {
            border: 0;

            .dropdown-link {
              font-family: $main-font;
              margin: 0;
              width: 100%;
              padding: 3px 0;
            }

            & + .dropdown-item {
              border-top: none;
            }
          }

          &:has(.has-subcategories) {
            display: flex !important;
            margin: 0;
            justify-content: space-evenly;
          }

          .has-subcategories {
            display: flex;

            .menu-column-wrapper {
              flex-wrap: wrap;
              display: flex;
              align-self: flex-start;
            }
          }
        }

        @include respond-to-max-width($customMobileBreak) {
          .dropdown-menu.dropdown-menu {
            display: none;
          }
        }
      }
    }
  }
}
