
.order-summary-request {
  margin-bottom: 10px;
  border-bottom: 1px solid $medium-light-gray;

  p {
    font-size: 20px;
    font-weight: $font-weight-medium;
  }
}

.cart-shipping-discount,
.order-discount {
  .discount-label,
  .order-discount-total,
  .shipping-discount-total,
  .gift-total-cost {
    color: $green-color;
    font-weight: $font-weight-bold;
  }
}
