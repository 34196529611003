$order-details-header-font-size-desktop: 30px;
$order-details-header-font-size-mobile: 24px;
$back-link-font-size-desktop: 14px;
$back-link-font-size-mobile: 12px;
$black-color: #000;
$border-color:#cacaca;
$order-details-font-color: #222;
$succes-submit-icon-color: #559550;

.order-details-page-container {
  font-family: $main-font;

  @import "./order-details-breadcrumbs";

  .order-details-header {
    margin-bottom: 55px;
    font-size: 30px;
    font-weight: 500;
    color: $order-details-font-color;
    position: relative;
    width: 100vw;
    padding: 0 40px;
    margin-left: calc(-50vw + 51%);

    @include respond-to-max-width($max-mobile-width) {
      margin-bottom: 26px;
      font-size: $order-details-header-font-size-mobile;
      padding: 0 20px;
    }
  }

  @import "./order-details-card";
  @import "./order-change-form-details";
  @import "./your-cart-card";
  @import "./order-total-summary";

  .my-account {
    text-align: center;

    a {
      font-size: 16px;
      font-weight: 500;
      color: $black-color;
      text-decoration: underline;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $back-link-font-size-mobile;
      }
    }
  }

  h3 {
    font-family: $main-font;
    font-weight: $font-weight-medium;
    font-size: 16px;
    color: $text-light-gray;

    &.selected-shipping-method {
      margin-top: 15px;
    }
  }

  .multi-shipping {
    & + .order-detail-section-divider {
      display: none;
    }
  }

  &.receipt {
    .dots-line {
      border: none;

      &.discount,
      &.total {
        border: none;
      }
    }
  }

  @include respond-to-max-width(991px) {
    .wishlist-breadcrumbs-text {
      padding: 0 20px;
    }
  }
}

.order-change-confirmation {
  .order-change-return-link {
    text-align: center;

    a {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: $black-color;
      text-decoration: underline;
    }

    &-confirmation {
      margin-top: 2.25rem;
    }
  }

  .my-account {
    text-align: center;

    a {
      font-size: 16px;
      font-weight: 500;
      color: $black-color;
      text-decoration: underline;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $back-link-font-size-mobile;
      }
    }
  }

  .success-guest-registration {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 2rem;

    .fa-check-circle-o-cv {
      font-size: 4rem;
      color: $succes-submit-icon-color;
    }

    &-msg1 {
      font-family: $robotoFont;
      margin-top: 10px;
      font-weight: bold;
      font-size: 18px;
      color: $succes-submit-icon-color;
    }

    &-msg2 {
      font-size: 16px;
    }
  }
}

.hr-border {
  left: 50%;
  width: 95.5vw;
  transform: translateX(-50%);
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid $border-color;
  margin-top: -80px;
  margin-bottom: 30px;

  @include respond-to-max-width(991px) {
    margin-top: -56px;
  }
}

.help-with-order-container {
  .order-change-link {
    &:hover {
      text-decoration: none;
      font-weight: 500;
    }
  }
}
