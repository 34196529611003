.product-set-detail {
  .prices-add-to-cart-actions {
    .price {
      padding-bottom: 15px;
      text-align: left;

      .starting {
        .cancelled-price {
          color: $dark-gray;
        }
      }
    }

    .add-to-cart-global {
      background-color: #e31724;
      opacity: 1;
      border: none;

      @include respond-to-min-width($max-mobile-width) {
        width: 100%;
      }
    }
  }

  .set {
    &-contains-title {
      font-size: 14px;

      @include respond-to-min-width($max-mobile-width) {
        font-size: 14px;
      }
    }

    &-items {
      .product {
        &-name {
          a {
            font-size: 20px;
            color: $black-text;
            font-weight: $font-weight-medium;
            font-family: $main-font;

            @include respond-to-min-width($max-mobile-width) {
              font-size: 30px;
            }
          }
        }

        &-number {
          padding: 0;
        }

        &-detail {
          .set-prices {
            span {
              &.value {
                font-size: 30px;
                font-weight: $font-weight-bold;
              }
            }

            del {
              span {
                &.strike-through {
                  span {
                    &.value {
                      font-size: 18px;
                      font-weight: $font-weight-regular;
                    }
                  }
                }
              }
            }
          }

          .cart-and-ipay {
            .pdp-checkout-button {
              .add-to-cart {
                background-color: #e31724;
                opacity: 1;
                width: 100%;

                &:disabled {
                  opacity: 0.65;
                }
              }
            }
          }

          .size-chart {
            .btn {
              &-primary {
                background-color: transparent;
                border: none;
                color: $black-text;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

#mySpriteSpin {
  margin-bottom: 2em;

  .spritespin {
    &-stage,
    &-canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    margin-top: 0;
    width: 350px !important;
    height: 300px !important;
  }
}

.add-to-cart.btn.btn-primary i.fa.fa-shopping-bag {
  display: none;
}
