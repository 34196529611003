.container {
  &.email-verification-page {
    .card {
      margin: auto;
      padding: 60px 30px 40px;
      text-align: center;

      &-body {
        max-width: 360px;
        margin: auto;

        h2 {
          font-size: 30px;
          color: $black-text;
          font-weight: $font-weight-medium;
          font-family: $main-font;
        }

        p {
          font-family: $main-font;
          font-size: 16px;
          font-weight: $font-weight-regular;
          color: $black-text;
          margin-top: 25px;
          margin-bottom: 25px;
        }

        button {
          font-family: $main-font;
          height: 50px;
          width: 100%;
          background-color: $secondary-color-1;
          color: $secondary-color-2;
          border: none;
          border-radius: 3px;
          font-size: 15px;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        padding: 75px 0;
      }
    }

    @include respond-to-min-width($max-mobile-width) {
      margin-top: 100px;
      margin-bottom: 150px;
    }

    @include respond-to-max-width($max-mobile-width) {
      padding: 0;
    }
  }
}

@include respond-to-max-width($max-mobile-width) {
  footer {
    margin-top: 0;
  }
}
