$product-personalization-summary-bg-color: #f2f2f2;
$total-cost-color: $secondary-color-1;

.product-giftpackage-summary {
  padding: 10px 55px;
  text-align: center;
  font-family: $main-font;
  background-color: $product-personalization-summary-bg-color;

  &-header {
    font-weight: $font-weight-medium;
    margin-bottom: 5px;

    @include respond-to-max-width($max-mobile-width) {
      font-size: 14px;
    }
  }

  &-total-cost {
    color: $total-cost-color;
  }

  &-body {
    font-weight: $font-weight-regular;
    max-width: 300px;
    margin: auto;

    @include respond-to-max-width($max-mobile-width) {
      font-size: 12px;
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    padding: 12px 25px;
  }
}

@import "../personalization/productPersonalizationSummary";
