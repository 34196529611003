.wp {
  &-content {
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #cacaca;
    margin-top: 20px;
  }

  &-row {
    padding: 20px;

    .checkbox-contact {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .form-group {
        .custom-control {
          &-input:checked ~ .custom-control-label::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
            background-color: $primary-color-2;
          }

          &-label {
            padding-top: 4px;

            &::before {
              border: 1px solid #000;
              background: #f9f9f9;
              background: linear-gradient(#f9f9f9, #ccc);
              border-radius: 0.1875rem;
            }

            &::after {
              cursor: pointer;
              background-color: white;
              border: 1px solid black;
              border-radius: 3px;
            }
          }
        }
      }
    }

    .input-group-append {
      width: 100%;
      padding: 0 6px;

      > .btn {
        background-color: $btn-subscribe;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        border-radius: 25px;
        width: 385px;
        height: 50px;
        text-transform: uppercase;

        @include respond-to-max-width($max-mobile-width) {
          font-size: 14px;
          width: 100%;
          font-weight: 500;
          letter-spacing: 0;
        }
      }
    }

    .check_contact {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;

      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  &-title-header {
    text-align: center;
    font-size: 16px;
    color: #121212;
    opacity: 1;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-check {
    margin-right: 40px;
    width: 5rem;

    @include media-breakpoint-up(lg) {
      width: auto;
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
      justify-content: center;
    }

    &::before {
      left: -1.5rem;
      top: 0.4rem;
    }

    &::after {
      left: -1.5rem;
      top: 0.4rem;
    }
  }

  &-footer {
    padding: 0.5rem;
    border-top: 1px solid #e9ecef;

    .custom__close-box {
      color: $custom-link-color;
      font-size: 12px;
      text-decoration: none;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
