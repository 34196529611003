footer {
  background-color: $bg-color-footer;
  padding-top: 0.5em;
  font-family: $main-font;
  padding-bottom: 0;
  margin-top: 30px;

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .footer {
    &__logo {
      width: 72%;
      position: relative;
      margin-left: 20%;
      margin-top: 5%;

      @include respond-to-max-width($max-tablet-width) {
        width: 35%;
        margin-left: 5%;
        margin-top: 0;
      }
    }

    &-container {
      padding-top: 20px;

      @media screen and (min-width: $min-mobile-width) and (max-width: $max-tablet-width) {
        padding: 2rem 30px 0;
      }

      .footer__link-redirect-mobile {
        width: 100%;
        margin-left: -6px;

        @media screen and (min-width: $min-mobile-width) {
          width: 60%;
        }
      }

      .collapsible-md {
        button.title {
          font-weight: 400;
          font-family: $main-font;
          color: $font-gray;
          margin: 0;
          padding: 6px 12px;
        }

        .title {
          text-align: left;
          margin-bottom: 8px;
          font-weight: 400;
          font-family: $main-font;
          color: $font-gray;
          font-size: 18px;

          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }

        .menu-footer.content {
          li {
            height: 2rem;
            line-height: 1rem;
            font-weight: $font-weight-light;

            @include respond-to-max-width($max-mobile-width) {
              margin-left: 3%;
            }
          }
        }
      }

      ul {
        li {
          color: $text-color-footer;

          a {
            color: $text-color-footer;
          }
        }
      }

      .store {
        @include respond-to-max-width($max-tablet-width) {
          border: none;
        }

        .content-asset {
          width: 80%;
          margin: auto;

          img {
            width: 100%;
          }
        }
      }

      @include respond-to-max-width($max-tablet-width) {
        margin-bottom: 20px;
      }
    }

    &-button {
      @include respond-to-max-width($max-tablet-width) {
        border-top: none;
        margin-bottom: 20px;
        border-bottom: 0.5px solid #979797;
      }
    }

    &-item {
      @include respond-to-max-width($max-tablet-width) {
        border-bottom: 0.5px solid #979797;
        width: 90%;
        margin: auto;
        border-top: none;
        padding: 0;

        &__soacial-email-mobile {
          padding-top: 2rem;
          border-bottom: none;

          .col-5.pt-4.pb-1 {
            padding-left: 15px;
          }

          .btn.btn-repentance {
            margin-left: 6px;
          }
        }
      }

      &.store {
        .content-asset {
          width: 70%;
          margin: unset;
        }
      }
    }

    &-copy-row {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      overflow-x: hidden;
      height: unset;
      padding: 22px 40px;
      position: relative;
      background-color: #151515;

      @include respond-to-max-width($max-mobile-width) {
        padding: 22px 15px;
      }
    }

    &-locate-store-container {
      display: flex;
      align-items: center;
      width: 10%;

      @include respond-to-max-width(1208px) {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
        width: 13%;
      }
    }
  }

  .copyright {
    color: $text-color-footer-copy;
    margin: 0;
    width: 100%;
    font-size: 13px;
    font-weight: 300;

    &-notice {
      padding-left: 0;
      display: flex;
      align-items: center;
      margin: 0;

      @include respond-to-max-width($max-tablet-width) {
        padding: 15px;

        > div {
          width: 100%;
        }
      }
    }

    @include respond-to-max-width($max-tablet-width) {
      text-align: left;
      margin: auto;
    }
  }

  .social {
    display: flex;
    align-items: center;
    min-width: 15%;

    &-links {
      position: relative;
      left: 0;

      .fa {
        color: $social-links-color;
      }

      li {
        margin: 0.613em;

        @include respond-to-max-width($max-tablet-width) {
          width: unset;
        }
      }

      @include respond-to-max-width($max-tablet-width) {
        position: relative;
        width: 80%;
      }
    }

    @include respond-to-min-width(1210px) {
      max-width: 15%;
    }

    @include respond-to-max-width($max-tablet-width) {
      margin: 0 30px;
      padding: 0;
    }
  }

  .back-to-top {
    float: right;

    @include respond-to-max-width($max-mobile-width) {
      margin-left: 2%;
    }

    i.fa-arrow-up {
      color: $bg-color-footer;
    }
  }

  .fa {
    &-whatsapp::before {
      content: "\F232";
      font-weight: 600;
    }

    &-envelope-o::before {
      content: "\F003";
      font-weight: 600;
    }

    &-inverse {
      color: $primary-color-1;
    }
  }

  a.btn.btn-repentance {
    border: 1px solid;
    font-size: 13px;
    padding: 8px 16px;
    border-radius: 25px;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  @include respond-to-max-width($max-tablet-width) {
    padding-top: 0;
    margin-top: 30px;
  }
}

.hr__footer-redirect {
  border-top: 1px solid #979797;
  margin-bottom: 2px;
  margin-top: 2rem;
}

.footer-button {
  @include respond-to-max-width($max-tablet-width) {
    border-top: none;
    margin-bottom: 20px;
    border-bottom: 0.5px solid #979797;
  }
}

.whatsapp_icon,
.styles_button_main__2rvIi {
  transition: bottom 0.3s ease;
}
