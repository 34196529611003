.description-and-detail {
  margin-top: 20px;

  .description {
    margin-bottom: 0;
    margin-top: 15px;

    .title-collapse-container {
      button {
        border-bottom: 1px solid #cacaca;
        padding-left: 0;
        font-size: 16px;
        font-weight: 500;
        margin-right: -40px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        &:focus {
          box-shadow: none;
        }

        &[aria-expanded="true"] i {
          rotate: 180deg;
        }
      }

      .card-header {
        padding: 0;
        background-color: transparent;
        border-bottom: none;
        display: flex;
      }
    }

    .container-extended {
      margin-top: 1.5em;
      padding-left: 0;
    }
  }

  .title {
    font-family: $main-font;
    font-size: 16px;

    @include respond-to-max-width($max-mobile-width) {
      font-size: 16px;
      padding-left: 0;
      font-weight: $font-weight-medium;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    .label {
      font-weight: $font-weight-medium;
    }
  }

  @include respond-to-min-width($max-mobile-width) {
    padding-left: 0;
    padding-right: 0;
  }
}
