//ORDER LIST TABS
.my-orders__tabs {
  margin: 0 auto;
  padding: 0;

  li {
    width: 50%;

    &.nav-item {
      a {
        text-align: center;

        &:not(.btn-primary):not(.btn-outline-primary).my-orders__tab-link {
          &.active {
            border-bottom: 0.188em solid $border-tab-color;
          }

          color: $text-tab-color;
        }
      }
    }
  }
}

.orders-tabs {
  &.tab-content {
    min-height: 10rem;

    @include respond-to-max-width($max-mobile-width) {
      min-width: 3rem;
      min-height: 3rem;
    }
  }
}

header ~ #maincontent .container,
.container-fluid {
  &:not(.account-dashboard-container) {
    a:not(.btn-primary):not(.btn-outline-primary):not(.btn-cancel) {
      color: $text-color-footer;

      &.my-order-detail__link,
      &.modal-shipping-methods-availability-redirect {
        color: $custom-link-color;
      }

      &.my-orders__tab-link {
        color: $text-tab-color;
        font-weight: 500;
      }
    }
  }
}
