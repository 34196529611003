.product-in-stock-online-modal {
  .modal {
    &-header {
      border-bottom: none;
      padding-bottom: 0.3125rem;
      padding-top: 1.25rem;

      .fa-exclamation-circle {
        color: #fda806;
        font-size: 1.6rem;
      }
    }

    &-body {
      padding-top: 0;
      padding-bottom: 0;

      .product-in-stock-online{
        &-title {
          font-weight: bold;
        }

        &-content,
        &-title {
          color: #9f9f9f;
          text-align: center;
        }
      }
    }

    &-footer {
      border-top: 1px solid #dee2e6;
      padding: 0.5rem;

      .no-change-shipment-button,
      .change-shipment-button {
        font-weight: 500;
        font-size: 0.8rem;
      }

      .change-shipment-button {
        color: #187dd6;
      }

      .no-change-shipment-button {
        color: #000;
      }

      &-two-options {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
