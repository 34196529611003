.technological-product {
  margin-bottom: 5px;
  padding: 5px 0;
}

.tech-product,
.non-tech-product {
  .fa-exclamation-circle {
    color: #2e3092;
    transform: rotate(180deg);
  }

  .tech-product-tag,
  .tech-product-tag-text {
    color: #2e3092;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
  }

  &-message {
    color: #999;
    text-align: left;
    font-size: 12px;
  }
}

.tech-product {
  .fa-exclamation-circle {
    vertical-align: middle;
  }

  &-title {
    .tech-product-tag {
      font-size: 14px;
    }
  }

  &-message {
    margin-left: 3.5%;
  }
}

.non-tech-product {
  margin: 5px 0;

  .fa-exclamation-circle {
    vertical-align: 3px;
  }

  &-message {
    .tech-product-tag-text {
      font-size: 12px;
    }
  }
}
