$my-cart-font-size: 20px;
$my-cart-color: #333;
$go-to-cart-link-color: $custom-link-color;
$go-to-cart-font-size: 14px;
$product-name-font-size: 16px;
$product-name-color: $black-text;
$trash-icon-color: $primary-color-2;
$variation-attributes-color: $black-text;
$variation-attributes-font-size: 12px;
$price-n-quantity-font-size: 12px;
$price-n-quantity-color: $primary-color-2;
$total-price-font-size: 18px;
$total-price-color: $primary-color-2;

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.cart {
  h1 {
    font-family: $main-font;
    font-size: $my-cart-font-size;
    color: $my-cart-color;
    font-weight: $font-weight-medium;
  }

  .normal {
    font-family: $main-font;
    color: $go-to-cart-link-color;
    font-weight: $font-weight-medium;
    font-size: $go-to-cart-font-size;
  }

  .fa-trash {
    color: $trash-icon-color;
  }

  .line-item {
    &-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $product-name-color;
      font-family: $main-font;
      font-size: $product-name-font-size;
      font-weight: $font-weight-medium;
      margin: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    &-attributes {
      color: $variation-attributes-color;
      font-size: $variation-attributes-font-size;
      margin-bottom: 5px;
      font-weight: $font-weight-regular;
    }

    &-total-price {
      &-amount {
        font-size: $total-price-font-size;
        color: $total-price-color;
        font-weight: bold;
      }

      &-label {
        font-size: $price-n-quantity-font-size;
        font-weight: $font-weight-regular;
        color: $price-n-quantity-color;
      }
    }
  }

  .stamp-limited-product-sku-minicart {
    font-size: 16px;
    font-weight: bold;
    color: $secondary-color-1;
    display: none;
    margin-top: 10px;
    margin-right: 2%;
    max-width: 73%;
    margin-bottom: 4%;

    @include respond-to-max-width($max-mobile-width) {
      max-width: 96%;
    }
  }

  .price-and-quantity {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  }

  .strike-through {
    display: none;
  }

  .flex-item-price {
    &.price {
      font-size: 0;
    }
  }

  .quantity-label {
    font-size: $price-n-quantity-font-size;
    font-weight: $font-weight-regular;
    color: $price-n-quantity-color;
    width: 100%;
  }

  select {
    width: auto;
  }
}
