$swatch-colors: ('beige': #f5f5dc,
'black': $black,
'blue': $blue,
'brown': #a52a2a,
'green': $green,
'grey': #8f979d,
'navy': #000080,
'orange': #ffa500,
'pink': #fe249a,
'purple': #800080,
'red': #f00,
'white': $white,
'yellow': #ff0,
'Negro': #000,
'Gris': #808080,
'Blanco': #f8f6f6,
'Azul': #0071a2,
'Celeste': #6eb0ff,
'Aqua': #88eac9,
'Verde': #6da32f,
'Amarillo': #ffe736,
'Naranja': #ff8a29,
'Rojo': #ed2a1b,
'Rosa': #f792ac,
'Fucsia': #f72b6a,
'Lila': #d2b1d3,
'Violeta': #7d2a91,
'Marron': #7c4b2a,
'Beige': #e2b686);

@each $color in map-keys($swatch-colors) {
  $colorValue: #{map-get($swatch-colors, $color)};

  .swatch {
    &-circle-#{$color} {
      @include circle(2.5em, $colorValue);

      display: block;
      position: relative;

      &.disabled {
        opacity: 0.2;
      }
    }

    &-filter-#{$color} {
      @include circle(1.38em, $colorValue);

      background-color: $colorValue;
      display: block;
      position: relative;

      &.disabled {
        opacity: 0.2;
      }
    }
  }
}

.swatch {
  &-circle-Metalizados {
    display: block;
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, #efc981, #f2d78f, #f8f0e4, #c58511, #eec687);
  }

  &-circle-Otros {
    display: block;
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    background-color: #001;
    background-image: radial-gradient(white 20%, transparent 20%), radial-gradient(white 20%, transparent 20%);
    background-size: 12px 12px;
    background-position: 0 0, 30px 30px;
  }

  &-circle-miscellaneous {
    background:
      linear-gradient(
        0deg,
        rgba(130, 30, 145, 1) 0,
        rgba(130, 30, 145, 1) 25%,
        rgba(237, 209, 52, 1) 25%,
        rgba(255, 255, 0, 1) 50%,
        rgba(237, 209, 52, 1) 50%,
        #59ba00 50%,
        #59ba00 76%,
        #111 76%,
        #111 100%
      ),
      linear-gradient(
        0deg,
        rgba(14, 92, 209, 1) 0,
        rgba(14, 92, 209, 1) 50%,
        rgba(226, 11, 11, 1) 50%,
        rgba(226, 11, 11, 1) 100%
      );
    background-repeat: repeat-y, repeat;
    background-size: 50% 100%, 100% 100%;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    border-radius: 1.25em;
    display: block;
    height: 2.5em;
    position: relative;
    transform: rotate(35deg);
    width: 2.5em;

    &.disabled {
      opacity: 0.2;
    }

    &.selected::after {
      transform: rotate(-35deg);
    }
  }

  &-circle.selected::after {
    font-size: 16px;
  }
}

#refinement-color ul.values.content {
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  font-size: 12px;

  span {
    text-align: center;
  }

  .color-attribute {
    text-align: center;

    button {
      display: math;
    }
  }
}
