.card {
  width: 100%;

  .card-body {
    padding-bottom: 50px;

    .nav-item .active {
      border-bottom: 0.188em solid $secondary-color-1;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}
