$customMobileBreak: 1341px;

.nav-item.dropdown {
  .dropdown-toggle {
    @include respond-to-max-width($max-mobile-width) {
      &::after {
        margin-right: 15px;
        display: none;
      }
    }
  }

  .menu-items-container {
    display: none;

    &.show {
      display: block;
    }

    @include respond-to-min-width(1321px) {
      background-color: $light-gray;
      width: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;

      .dropdown-item {
        .dropdown-link {
          display: block;
          width: 130px;
        }
      }

      .options-container {
        height: 100%;

        .nav {
          &.navbar-nav {
            margin: auto;
          }
        }
      }
    }
  }
}

.menu-toggleable-left {
  .close-menu {
    padding: 15px;
    background-color: $close-menu-bg;
    border-bottom: 1px solid $grey3;
    flex: 0 0 100%;

    @include media-breakpoint-up($next-breakpoint) {
      display: none;
    }
  }
}

@include respond-to-max-width(769px) {
  .main-menu.menu-toggleable-left.navbar-toggleable-sm {
    display: none;

    &.in {
      display: block;
    }
  }
}

@include respond-to-max-width($customMobileBreak) {
  .multilevel-dropdown {
    .navbar > .close-menu {
      background-color: $bg-color-header;
      padding: 20px;

      div.back {
        &.pull-left {
          padding: 10px;
          display: block;

          img {
            height: 35px;
            width: 80%;
          }
        }
      }

      .close-button {
        span {
          font-size: 25px;
          color: $social-links-color;
        }
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: $slide-out-animation;
    display: block;
    max-width: 100%;

    &.in {
      min-width: 50%;
      left: 0;
    }
  }
}
