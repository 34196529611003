.order-product-summary {
  &.checkout {
    margin-bottom: 0;
    border: none;
    border-radius: 0 0 1px 1px;

    .product-summary-block {
      margin-top: 0;

      h3 {
        font-family: $main-font;
        font-size: 1rem;
      }

      .checkout {
        .product-line-item-details {
          position: relative;

          .price-and-quantity {
            .line-item-total {
              &-text {
                display: none;
              }

              &-price-amount {
                position: absolute;
                right: 0;
                bottom: 10px;
              }
            }
          }
        }
      }

      .store-details {
        .storelocator-phone {
          pointer-events: none;
        }
      }

      .shipment-block {
        h5 {
          margin-top: 15px;
          font-family: $main-font;
          font-size: 14px;
          font-weight: $font-weight-medium;
        }

        p {
          [class^="ship-to"] {
            font-size: 14px;
            font-family: $main-font;
            font-weight: $font-weight-regular;
          }
        }

        .leading-lines {
          p {
            font-size: 14px;
            font-family: $main-font;
            font-weight: $font-weight-regular;
          }
        }
      }
    }
  }
}

.product-line-item {
  .checkout {
    .line-item-divider {
      display: none;
    }
  }
}

.card-header {
  &.checkout {
    display: flex;
    align-items: center;
    background-color: white;

    .card-header-custom {
      font-family: $main-font;
      font-size: 20px;
    }

    .card-products-count {
      padding: 0 0 0 25px;
      font-size: 15px;
      margin: 0;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 14px;
      }
    }
  }
}

header ~ #maincontent .container a:not(.btn-primary):not(.btn-outline-primary).storelocator-phone {
  color: $black-text;
}

.card {
  .card-header {
    h2 {
      &.card-header-custom {
        font-size: 20px;
      }
    }
  }
}

