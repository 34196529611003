
[data-action="Account-PasswordReset"] {
  .hero.login-banner {
    display: none;
  }

  #maincontent {
    .container {
      padding-top: 25px;
      background-color: #fff;
      min-height: 500px;

      h3 {
        margin-bottom: 20px;
        color: $primary-color-2;
        font-weight: 500;
        font-family: $main-font;
      }

      form {
        .request-password-body {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $primary-color-2;
            font-family: $main-font;
          }

          .form-group {
            label {
              font-family: $main-font;
              font-weight: 400;
              font-size: 14px;
            }

            input {
              font-family: $main-font;
              height: 40px;
              font-size: 14px;
              border-color: $primary-color-2;

              &::placeholder {
                color: transparent;
              }
            }
          }
        }

        .send-email-btn {
          button {
            font-family: $main-font;
            height: 50px;
            background-color: $primary-color-2;
            font-size: 15px;
          }

          a {
            margin-top: 25px;
            height: 50px;
            background-color: $primary-color-2;
            font-size: 15px;
            padding: 10px;
          }
        }
      }
    }
  }
}
