.btn-storelocator-search-pickit {
  background-color: $carousel-indicator-active;
  color: $background;
  border: none;
  height: 40px;
  border-radius: 2px;
}

.store-locator-container-pickit {
  .results-card-pickit .card-body .results-pickit {
    @include media-breakpoint-up(md) {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 21.875em;
      margin-right: -0.938em;
    }
  }
}

.modal-body {
  padding: 30px;

  .container {
    width: 100%;
    padding: 0;
  }
}

.map {
  &-container {
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 35px;
    }

    &.map-pickit-desktop {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  &-canvas {
    height: 100%;

    &#map-canvas-pickit {
      height: 21.5em;
      display: block;

      @include media-breakpoint-up(md) {
        height: 100%;
      }
    }
  }
}

.store {
  &-locator {
    &-modal {
      .modal-content {
        max-width: 1140px;
        margin: 5% auto;

        .modal-title {
          font-family: $main-font;
        }
      }
    }

    &-container-pickit {
      .search-form-pickit {
        border: none;

        &.card {
          margin-bottom: 20px;
        }

        .card-body {
          padding: 0;

          .store-locator {
            > .row {
              align-items: flex-end;
              margin-bottom: 15px;

              > .col-8.form-group.required {
                margin: 0;
                padding: 0;

                label {
                  text-align: left;
                  font-weight: $font-weight-medium;
                  font-size: 16px;
                  margin-bottom: 15px;

                  @include respond-to-max-width($max-mobile-width) {
                    font-size: 14px;
                  }
                }

                input {
                  height: 40px;
                  border: 1px solid $light-black;
                  width: 101%;
                  border-radius: 2px;
                }
              }

              .btn-storelocator-search-pickit {
                background-color: #121212;
                color: $background;
                border: none;
                height: 40px;
                border-radius: 2px;
              }
            }
          }
        }
      }

      .select-store-pickit,
      .btn-primary.select-store-pickit:not(:disabled):not(.disabled):active {
        border-radius: 25px;
        opacity: 1;
        background: #121212;
        border: none;
        height: 50px;
        width: 100%;
        font-family: $main-font;
        cursor: pointer;

        &:disabled {
          background: #cacaca;
        }

        &:focus {
          outline: none;
        }

        &.btn-primary {
          @include respond-to-min-width(544px) {
            margin-top: 1rem;
          }
        }
      }

      .results {
        &-card-pickit {
          border: none;

          .card {
            &-header {
              display: none;
            }

            &-body {
              border: none;
              padding: 0;

              .store-locator-no-results {
                text-align: left;
              }

              .results-pickit {
                width: 100%;

                .results-quantity {
                  font-family: $main-font;
                }

                .card-body {
                  .form-check {
                    border-top: 1px dashed $regular-light-gray;
                    border-bottom: 1px dashed $regular-light-gray;

                    label {
                      font-family: $main-font;
                      font-size: 15px;
                      font-weight: $font-weight-regular;

                      .display-name {
                        margin-right: 5px;
                      }

                      .arrival-time {
                        color: $text-gray;
                      }

                      &::before {
                        content: " ";
                        display: inline-block;
                        position: relative;
                        top: 30px;
                        right: 2px;
                        width: 17px;
                        height: 17px;
                        border-radius: 11px;
                        border: 1px solid $primary-color-2;
                        background-color: $secondary-color-2;
                      }
                    }

                    input[type="radio"] {
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      background: $secondary-color-2;
                      cursor: pointer;

                      &:checked + label {
                        &::after {
                          border-radius: 11px;
                          width: 7px;
                          height: 7px;
                          position: absolute;
                          top: 35px;
                          left: 23px;
                          content: " ";
                          display: block;
                          background: white;
                          cursor: pointer;
                        }

                        &::before {
                          background-color: $primary-color-2;
                          cursor: pointer;
                        }
                      }

                      &:not(:checked) + label {
                        &::after,
                        &::before {
                          cursor: pointer;
                        }
                      }
                    }
                  }

                  .select-store-input {
                    margin-top: 1.5rem;
                    margin-left: 0.75rem;
                  }

                  .store-details-pickit {
                    margin: 0 0 15px 35px;

                    .result-number {
                      position: absolute;
                      left: 22px;
                      top: 4px;
                    }

                    .store-name {
                      font-weight: $font-weight-medium;
                      line-height: 2rem;
                    }

                    a {
                      text-decoration: underline;
                      line-height: 2rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
