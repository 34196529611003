$black-color: #000;
$gray-color: #999;
$white-color: #fff;

.order-change-form-container {
  justify-content: center;

  .btn {
    &-primary {
      background-color: $black-color;
      border: none;
      border-radius: 25px;
      font-size: 15px;
      height: 50px;
      font-weight: bold;
      font-family: $main-font;
    }
  }

  .order-change-paragraph {
    margin-top: 1.25rem;
    font-size: 13px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    &-bold {
      font-weight: 500;
    }
  }

  .order-change-span {
    color: $gray-color;
    font-size: 13px;

    &.italic-font-span {
      font-style: italic;
    }

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }

  .change-products-container {
    margin-top: 2rem;

    .change-products-header {
      background-color: transparent;
    }

    .order-product-summary-container {
      .order-product-summary {
        margin-top: 0;

        .product-line-item {
          opacity: 0.5;

          &.product-change-selected {
            opacity: 1;
          }
        }
      }
    }

    &-inactive {
      .order-product-summary-container {
        .order-product-summary {
          .product-line-item {
            opacity: 1;
          }
        }
      }
    }
  }

  .timeline-container {
    display: flex;

    .timeline {
      position: relative;
      padding: 20px 0;
      width: 50px;
      flex-shrink: 0;
    }

    .timeline::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 5%;
      width: 4px;
      background-color: $gray-color;
      transform: translateX(-50%);
    }
  }

  .step-content {
    margin-left: 20px;
    flex-grow: 1;

    .each-step-container {
      margin-bottom: 3rem;

      select:focus {
        box-shadow: none;
      }

      .change-reason-select {
        margin-bottom: 1rem;
      }

      .step-one-order-change-label,
      .step-two-order-change-label,
      .step-three-order-change-label,
      .order-number-change-label {
        font-weight: 500;
        margin-bottom: 5px;
      }

      .step-two-order-change-label,
      .step-three-order-change-label,
      .step-one-order-change-label {
        position: relative;

        .step-icon-span {
          position: absolute;
          top: -2px;
          left: -59px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $gray-color;
          color: $white-color;
          font-size: 14px;
          font-weight: bold;
        }
      }

      label.order-change-radio-button {
        font-family: $main-font;
        font-size: 0.9375rem;
        font-weight: $font-weight-regular;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::before {
          content: " ";
          display: inline-block;
          position: relative;
          width: 1.0625rem;
          height: 1.0625rem;
          margin-right: 0.5rem;
          border-radius: 0.6875rem;
          border: 0.0625rem solid $primary-color-2;
          background-color: $secondary-color-2;
          flex-shrink: 0;
        }
      }

      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $secondary-color-2;

        &:checked + label.order-change-radio-button::before {
          background-color: $primary-color-2;
        }

        &:checked + label.order-change-radio-button::after {
          border-radius: 0.6875rem;
          width: 0.4375rem;
          height: 0.4375rem;
          left: 0.3rem;
          position: absolute;
          content: " ";
          display: block;
          background: $white-color;

          @include media-breakpoint-up(md) {
            left: 0.35rem;
          }
        }
      }

      .product-change-checkbox-container {
        display: flex;
        width: 85%;

        &.custom-checkbox {
          margin: 0 0 0.75rem;
          max-width: 100%;

          .custom-control-input:checked ~ .each-change-product-label::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
            background-color: $primary-color-2;
          }

          label {
            padding-top: 0;

            &::after {
              cursor: pointer;
              background-color: $white-color;
              border: 1px solid $black-color;
              border-radius: 3px;
            }

            @include respond-to-min-width($max-mobile-width) {
              padding-left: 25px;
            }
          }
        }
      }

      .form-group {
        margin-top: 2rem;
        position: relative;
        width: 90%;

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid $gray-color;
          font-size: 16px;
          font-weight: 400;
          padding-block: 0;
          height: 44px;
          line-height: 44px;
          color: #000;

          &::placeholder {
            color: $gray-accent;
          }

          &:-webkit-autofill + .placeholder-label {
            position: absolute;
            top: 10px;
            left: 0;
            padding: 0 5px;
            transition: 0.3s;
            color: $gray-accent;
            transform: translateY(-30px);
          }

          &:focus + .placeholder-label,
          &:not(:placeholder-shown) + .placeholder-label {
            transform: translateY(-30px);
            color: $gray-accent;
          }
        }

        .invalid-feedback {
          position: absolute;

          &-products {
            top: 100%;
          }
        }
      }

      .customer-saved-address-container {
        padding: 1rem 0 0 1.25rem;

        .radio-button-customer-address {
          margin-bottom: 1rem;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .step-two-address-accordion-container {
        padding: 0.625rem 0 0 1.25rem;
      }

      .step-three-address-accordion-container {
        padding: 0.625rem 0 0 1.25rem;
      }
    }
  }
}

.submit-order-change-button-container {
  display: flex;
  width: 90%;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;

  @include media-breakpoint-up(lg) {
    width: 21.375rem;
  }
}

.order-change-return-link {
  text-align: center;

  a {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: $black-color;
    text-decoration: underline;
  }
}

.order-change-information-container {
  padding-top: 1.875rem;

  .order-change-paragraph {
    margin-top: 1.25rem;
    font-size: 13px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    &-bold {
      font-weight: 500;
    }
  }

  .order-change-additional-information-span {
    font-size: 13px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
}
