$select-icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=";
$check-icon: "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E";

.address-book,
.addresses {
  .card {
    &-header {
      background-color: $light-gray;
      border-bottom: none;

      h2 {
        font-size: 20px;
      }
    }

    &-body {
      .remove-btn {
        background-color: transparent;
        bottom: unset;
      }
    }
  }

  .add-new {
    a {
      padding: 15px 20px;
      border-radius: 3px;
      font-size: 16px;
      background-color: $secondary-color-1;
      color: $background;
      border: none;
      outline: 0;
      font-weight: $font-weight-bold;
      height: 50px;
      display: block;
      margin: 0 auto;
      font-family: $main-font;

      &:hover {
        background-color: $secondary-color-1;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .back {
    margin-top: 20px;
    margin-bottom: 30px;

    a {
      font-weight: $font-weight-medium;
      text-decoration: underline;

      p::before {
        content: "<";
      }
    }
  }
}

.address-book {
  .card-body {
    max-width: 560px;
    margin: auto;

    .address-form {
      .custom-radio {
        display: none;
      }

      select {
        border-color: $black-text;
        font-size: 16px;
        font-weight: $font-weight-regular;
        font-family: $main-font;
        color: $primary-color-2;
        border-radius: 3px;
        -webkit-appearance: none;
        appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url($select-icon);

        &:invalid {
          color: $dark-gray;
        }
      }

      input {
        border-color: $black-text;
      }

      .custom-checkbox {
        margin-bottom: 10px;
        margin-top: 10px;

        .custom-control-input:checked ~ .custom-control-label::after {
          background-image: url($check-icon);
          background-color: $primary-color-2;
        }

        label {
          &::before,
          &::after {
            @include respond-to-min-width($max-mobile-width) {
              left: 5px;
            }
          }

          &::after {
            cursor: pointer;
            background-color: white;
            border: 1px solid black;
            border-radius: 3px;
          }

          @include respond-to-min-width($max-mobile-width) {
            padding-left: 25px;
          }
        }

        @include respond-to-min-width($max-mobile-width) {
          padding-left: 0;
        }
      }

      button,
      a {
        padding: 15px 20px;
        border-radius: 3px;
        font-size: 15px;
        background-color: $primary-color-2;
        color: $background;
        border: none;
        outline: 0;
        font-weight: $font-weight-bold;
        height: 50px;
        display: block;
        margin: 0 auto;

        &:hover {
          background-color: $primary-color-2;
        }

        &:focus {
          box-shadow: none;
        }

        &.btn-save {
          background-color: $primary-color-1;

          &:hover {
            background-color: $primary-color-1;
          }
        }
      }
    }
  }

  &-header {
    max-width: 100%;
    margin: auto;

    @import "../components/checkout/miniCartRemoveProductModal";

    .breadcrumb {
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 20px;
      font-size: 14px;

      a {
        color: $dark-gray;
      }

      &-item {
        &:last-child {
          a {
            color: $primary-color-2;
          }
        }
      }
    }

    .checkout-step {
      &-header {
        &-main {
          margin-bottom: 0;
          margin-top: 0;
          padding-left: 0;
        }

        &-subheader {
          &-main,
          &-phone-container {
            display: none;
          }
        }

        &-container {
          margin: 0 20px;
          padding: 0;

          @include respond-to-min-width(1342px) {
            margin: 0 31px;
          }

          @include respond-to-between(545px, 1341px) {
            margin: 0 44px;
          }
        }
      }
    }
  }
}

.card {
  .add-new-address-profile-btn {
    text-align: center;
    padding: 20px 0;
  }
}
