#giftWrap {
  top: 25%;

  .modal {
    &-header {
      padding: 5px;

      .close {
        span {
          font-size: 1.5rem;
        }
      }
    }

    &-title {
      color: $primary-color-2;
      font-weight: 500;
      font-size: 15px;
      margin: 8px;
      font-family: $main-font;
    }

    &-body {
      padding: 15px;

      img {
        width: 100%;
      }

      table {
        text-align: center;

        thead {
          th {
            font-weight: $font-weight-regular;
            background-color: $primary-color-2;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $primary-color-2;
        font-family: $main-font;

        &.title {
          font-weight: bold;
        }
      }
    }

    &-dialog {
      max-width: 700px;
    }
  }
}

.modal-show-detail {
  color: $custom-link-color;
  cursor: pointer;
  display: inline;
  margin-left: 1em;

  @include respond-to-max-width($max-mobile-width) {
    display: block;
    margin-top: 5%;
    margin-left: 0;
  }
}

#add-gift-wrap {
  @include respond-to-max-width($max-mobile-width) {
    display: block;
    margin-top: -40%;
  }
}
