$card-header-font-size-desktop: 20px;
$card-header-font-size-mobile: 16px;

#wallet-container-mc {
  button {
    background-color: #3ab598 !important;

    &:hover,
    .progress-bar-IFJqas.svelte-16x6ay9 {
      background-color: darken(#3ab598, 10%) !important;
    }
  }

  .text-1XN644.svelte-16x6ay9 {
    font-size: 0;

    &::after {
      content: 'Pagar con Mercado Credito';
      font-size: 16px;

      &:nth-child(17) {
        letter-spacing: 0.2em;
      }
    }
  }
}

.card {
  &.payment-summary {
    margin-bottom: 20px;
    font-family: $main-font;

    .card-header {
      padding: 12px 20px;
      background-color: $medium-light-gray;

      &-custom {
        font-family: $main-font;
        font-size: $card-header-font-size-desktop;
        font-weight: $font-weight-medium;
        color: $black-text;

        @include respond-to-max-width($max-mobile-width) {
          font-size: $card-header-font-size-mobile;
          font-weight: bold;
          color: $primary-color-2;
        }
      }

      .edit-button {
        font-family: $main-font;
        font-weight: $font-weight-medium;
        color: $custom-link-color;
        text-decoration: underline;
        font-size: 14px;
      }

      @include respond-to-max-width($max-mobile-width) {
        padding: 10px;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      margin-bottom: 15px;
    }
  }
}

.product-summary-block {
  .shipment-block {
    h3 {
      font-family: $main-font;
      font-weight: $font-weight-medium;
      font-size: 14px;
      color: $text-light-gray;
      margin-top: 10px;
    }
  }
}
