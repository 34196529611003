.slider {
  &-single {
    margin-bottom: 14px;
    height: auto;
    display: flex;
    justify-content: center;

    .slick-list.draggable {
      width: 100%;
      height: 100% !important;
    }

    > div:nth-child(1n + 2) {
      display: none;
    }

    &.slick-initialized > div:nth-child(1n + 2) {
      display: block;
    }

    .slick-slide h3 img {
      margin: auto;
    }

    h3 {
      line-height: 10rem;
    }
  }

  &-nav {
    .slick-list.draggable {
      width: 100%;
      height: 100% !important;

      .slick-track {
        margin-left: 13px;

        .slick-slide {
          max-width: 56px !important;
          cursor: pointer;
          border-radius: 7px;
          margin-right: 10px;
          background: #fff 0% 0% no-repeat padding-box;
        }
      }
    }

    h3 {
      &::before {
        content: "";
        display: block;
        padding-top: 0;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .slick-slide h3 img {
      border-radius: 7px;
    }
  }
}

h3 {
  position: relative;
  text-align: center;
  margin: 0;
}

.slick-slide {
  &.is-active h3 {
    background: #fff 0 0 no-repeat padding-box;
    height: 100%;
    border: 2px solid #121212;
    border-radius: 7px;
  }

  h3 {
    height: 100%;
  }
}

#carouselRecommendedProducts {
  .carousel {
    &-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      right: 0;
    }

    &-item {
      .slick-slide {
        padding: 0 5px;
      }
    }
  }

  .slick-arrow {
    width: 50px;
    height: auto;
    z-index: 1;
    transform: none;
    top: 25%;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    @include respond-to-between(991px, 1024px) {
      top: 20%;
    }

    @include respond-to-between(1024px, 1640px) {
      top: 25%;
    }

    @include respond-to-min-width(1640px) {
      top: 30%;
    }
  }

  .product {
    max-width: 32.625rem;
  }
}

.label-recommendation {
  text-align: left;
  margin-bottom: -10px;
}

@include respond-to-between(769px,950px) {
  #carouselRecommendedProducts .carousel-item {
    height: 632px;
  }
}

@include respond-to-between(950px,991px) {
  #carouselRecommendedProducts .carousel-item {
    height: 650px;
  }
}

@include respond-to-between(991px,1300px) {
  #carouselRecommendedProducts .carousel-item {
    height: 520px;
  }
}

@include respond-to-between(1300px,1920px) {
  #carouselRecommendedProducts .carousel-item {
    height: 650px;
  }
}

@include respond-to-min-width (1920px) {
  #carouselRecommendedProducts .carousel-item {
    height: 750px;
  }
}

#carouselRecommendedProducts .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item{
  right: 0;
}

.product-recommendation-listing .slick-disabled {
  display: none !important;
}
