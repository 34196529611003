$subtotal-font-size: 20px;
$subtotal-color: $primary-color-2;
$checkout-button-bg-color: $secondary-color-1;
$checkout-button-font-size: 15px;

.minicart-footer {
  border-top: 1px solid #cacaca;
  position: fixed;
  width: 418px;
  background-color: #fff;
  bottom: -16px;
  margin: 15px 0;
  padding: 15px;

  a {
    height: 50px;
    border-radius: 25px;
    line-height: 2;
  }

  .estimated-total {
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: $subtotal-font-size;
    color: $subtotal-color;

    &.sub-total {
      font-weight: bold;

      &-label {
        font-weight: $font-weight-medium;
      }
    }
  }

  .btn-primary {
    color: #fff;
    background-color: $checkout-button-bg-color;
    border-color: $checkout-button-bg-color;
    font-size: $checkout-button-font-size;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2.3;
    font-family: $main-font;

    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: $checkout-button-bg-color;
      border-color: $checkout-button-bg-color;
    }
  }

  @include respond-to-max-width(769px) {
    position: relative;
    width: calc(100% + 45px);
    right: 5px;
    bottom: 15px;
    border-bottom: 1px solid #cacaca;
  }
}
