@import "../variables";

header ~ #maincontent .page-designer-reference {
  margin-bottom: 50px;

  #preguntas-frecuentes {
    .editorialRichText-component-container {
      padding: 50px 0 0;

      @media screen and (max-width: 768px) {
        padding-left: 10px;
      }
    }

    div.question-container {
      @media screen and (max-width: 768px) {
        padding: 0 10px;
      }

      a.question {
        padding: 15px 10px 15px 0;

        i.fa-chevron-down {
          transition: none;
          display: flex;
          align-items: center;
        }

        &[aria-expanded="true"] {
          .fa-chevron-down {
            rotate: 180deg;
            transform: none;
          }
        }

        span{
          margin-right: 12px;
        }
      }

      div.answer {
        padding: 0;
      }
    }
  }

  .editorialRichText-component-container {
    border-bottom: 0 solid $regular-light-gray;

    h4 {
      font-size: 20px;
      font-weight: $font-weight-medium;
      margin-top: 15px;
      margin-bottom: 0;
      font-family: $main-font;
      text-align: left;
    }

    h2 {
      margin-bottom: 10px;
      max-width: 800px;
      margin-inline: auto;
    }
  }
}
