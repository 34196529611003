.card {
  &.order-product-summary {
    margin-bottom: 0;

    .product-line-item,
    .multi-shipping {
      + .product-line-item {
        &::before {
          content: "";
          display: none;
        }
      }
    }

    .product-line-item::after,
    .multi-shipping + .product-line-item::after {
      content: "";
      border-top: 1px dashed $regular-light-gray;
      margin: 15px -20px;
      display: block;

      @include respond-to-max-width($max-mobile-width) {
        margin: 13px -10px;
      }
    }

    .product {
      &-line-item:last-child::after {
        margin-bottom: 0;

        @include respond-to-max-width($max-mobile-width) {
          margin-bottom: 0;
        }
      }

      &-summary-block {
        margin-bottom: 0;
      }
    }

    .card-body {
      padding-bottom: 0;
    }
  }

  &-body {
    &.order-total-summary {
      border-top: 1px dashed $regular-light-gray;

      .hide-shipping-discount {
        display: none;
      }
    }
  }
}

