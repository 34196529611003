.mercadopago-form,
.modo-form {
  display: none;
}

.openChart-btn {
  @include font($font-weight-bold, 16px, 24px);

  display: flex;
  border: none;
  padding: 0 !important;
  height: unset;
  margin: 15px 40px 0 0 !important;
  text-align: left;
  -ms-flex-pack: left;
  justify-content: left;
  text-decoration: underline;
  width: -webkit-fit-content;
  width: fit-content;
  text-transform: unset;
  background-color: transparent;

  &::before {
    display: inline-flex;
    filter: brightness(0);
    height: 20px;
    width: 20px;
    content: "";
    margin-right: 5px;
    background: url(https://static.sizebay.technology/1403/reguaBoldred.svg) 50%/100% 100% no-repeat;
  }
}

.mercadopago-button,
.modo-button {
  @include font($font-weight-bold, 16px, 24px);

  width: 100%;
  height: 50px;
  font-family: $main-font;
  background: $primary-color-1;

  @include respond-to-max-width($max-mobile-width) {
    padding-left: 0;
    padding-right: 0;
  }
}

.open-ccAvailability-btn,
.open-shipping_methods_availability-btn {
  @include font($font-weight-bold, 16px, 24px);

  border: none;
  padding: 0 !important;
  height: unset;
  text-align: left;
  font-family: $main-font;
  color: #212529;
  text-decoration: underline;
  text-transform: unset;
  background-color: transparent;
  outline: none !important;

  .icon-store {
    width: 13px;
    display: inline-block;
    margin-right: 7px;
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
