$order-details-header-font-size-desktop: 20px;
$order-details-header-font-size-mobile: 16px;
$summary-label-font-size-desktop: 16px;
$summary-label-font-size-mobile: 14px;
$summary-value-font-size: 14px;
$continue-shopping-font-size: 15px;

.order-details-container {
  width: 60%;
  padding: 0 6px;

  .order-status-label,
  .order-status,
  .shipping-method-status,
  .dashboard-order-card-status {
    display: none;
  }

  .btn-primary {
    background-color: #121212;
    border-color: #121212;
    font-family: $main-font;
    font-weight: bold;
    height: 50px;
    font-size: $continue-shopping-font-size;
    padding: 15px;
    border-radius: 25px;
  }

  @include respond-to-max-width($max-mobile-width) {
    padding: 0;
    width: 100%;
  }

  @import "../../account/order-details/your-cart-card.scss";
  @import "../../account/order-details/order-details-card";
}
