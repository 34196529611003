.product-detail {
  .carousel {
    a[class^="carousel-control-"] {
      .icon-prev,
      .icon-next {
        background-color: transparent;
      }

      @include respond-to-max-width($max-mobile-width) {
        display: none;
        width: 1%;
      }
    }

    &-inner {
      .product-image-container {
        position: relative;

        fieldset {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-right: 60px solid transparent;

          legend {
            font-size: 15px;
            transform: rotate(-45deg);
            color: white;
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        max-height: 580px;
      }
    }

    &-indicators {
      &.custom-top {
        top: 98%;

        @include respond-to-max-width($max-mobile-width) {
          top: 87%;
        }
      }

      li {
        background-color: #21212180;
        height: 11px;
        width: 11px;

        &.active {
          background-color: $light-black;
          height: 14px;
          width: 14px;

          @include respond-to-max-width($max-mobile-width) {
            height: 9px;
            width: 9px;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          height: 7px;
          width: 7px;
        }
      }
    }
  }
}
