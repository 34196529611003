$black-text: #121212;
$font-weight-bold: 700;
$font-weight-medium: 500;

header ~ #maincontent .container {
  .checkout-btn-wishlist-add {
    &:focus {
      background-color: #ed3237;
    }
  }

  .header-wishlist {
    border-bottom: 1px solid $regular-light-gray;
    margin: 0 20px 30px;
    width: auto;

    @include respond-to-min-width(1342px) {
      margin: 0 30px 30px;
    }

    @include respond-to-between(545px, 1341px) {
      margin: 0 44px 30px;
    }

    h2 {
      font-family: $main-font;
    }
  }

  .wishlistItemCards {
    border-radius: 3px;

    .product {
      &-image {
        border: 1px solid rgba(0, 0, 0, 0.125);
        display: block;
        margin: 0 auto;
        width: 100%;
      }

      &-info {
        .remove-from-wishlist {
          background: transparent;
          border: none;
          cursor: pointer;
          font-size: 26px;
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 1;

          @include respond-to-min-width(769px) {
            right: 15px;
            top: 15px;
            z-index: 1;
          }
        }
      }

      &-item-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;

        &-mobile {
          padding-bottom: 10px;
          font-size: 16px;
          font-weight: 700;
          color: $black-text;
          display: block;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          text-align: left;
        }

        @include respond-to-min-width(769px) {
          display: block;
          padding-bottom: 10px;
          margin: 0;
          font-size: 18px;
          font-weight: $font-weight-bold;
          color: $black-text;

          &-mobile {
            display: none;
          }
        }
      }
    }

    .add-to-cart {
      &-text {
        font-size: 12px;
        color: $black-text;
        text-align: center;

        &-button-container {
          .visit-product-detail {
            text-decoration: none;
            padding: 15px 20px;
            border-radius: 3px;
            font-size: 15px;
            border: none;
            outline: 0;
            font-weight: $font-weight-bold;
            width: 95%;
            display: block;
            margin: 0 auto;
            text-align: center;

            &:focus {
              box-shadow: none;
            }

            @include respond-to-min-width(769px) {
              width: 246px;
              padding: 15px 20px;
              margin: unset;
              text-align: center;
            }
          }

          .btn-checkout-container {
            .checkout-btn-add {
              color: #fff;
              border-color: $secondary-color-1;
              height: 50px;
              background: $secondary-color-1;
              border-radius: 26px;
              text-transform: uppercase;
              font-weight: 700;
              width: 100%;
              font-family: $main-font;

              @include media-breakpoint-up(lg) {
                max-width: 280px;
              }
            }

            @include media-breakpoint-up(lg) {
              width: 100%;
              margin: 0 auto 15px;
            }

            @include respond-to-between(769px, 991px) {
              margin: 10px 0 0;
            }
          }

          @include respond-to-max-width(770px) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          @include respond-to-max-width(991px) {
            padding: 0 !important;
            display: flex;
            justify-content: center !important;
            width: 100%;
          }

          @include respond-to-max-width(1200px) {
            padding: 0 !important;
            margin-right: 7px;
          }

          @include respond-to-min-width(769px) {
            display: flex;
            flex-flow: row wrap;
            padding-right: 15px;
            align-items: baseline;
            justify-content: flex-end;

            .btn-checkout-container {
              width: 280px;
              margin: 0 0 0 10px;

              .checkout-btn-add {
                width: 280px;
              }
            }
          }
        }

        @include respond-to-min-width(769px) {
          font-size: 14px;
          width: 246px;
          text-align: left;
        }
      }

      &-btn {
        width: 280px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #121212;
        color: #fff;
        text-align: center;
        font-size: 1rem;
        border-radius: 1.5rem;
        height: 50px;
        border: none;

        @include respond-to-max-width(769px) {
          margin-bottom: 15px;
        }

        @include respond-to-min-width(769px) {
          font-weight: 700;
          width: 280px !important;
        }
      }

      &-wishList {
        margin-bottom: 10px;
      }
    }

    .card-container {
      padding: 15px 15px 0;

      .line-item {
        &-attributes {
          font-size: 14px;
        }

        &-price-info {
          margin-bottom: 0;
        }
      }

      .wishlist-price-quantity-container {
        height: 100%;
        justify-content: flex-end;

        .line-item {
          &-quantity {
            .quantity-form-wishlist {
              display: grid;

              label {
                margin-bottom: 5px;
              }

              .quantity {
                width: 60px;
              }

              @include respond-to-min-width(769px) {
                display: block;
                justify-content: unset;
              }
            }
          }

          &-price-container {
            .sales {
              display: flex;

              .value {
                margin-right: 8px;
                line-height: 1.6;
              }

              .strike-through {
                .value {
                  font-size: 20px;
                  margin: 0;

                  @include respond-to-max-width(768px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        @include respond-to-min-width(769px) {
          justify-content: unset;
          align-items: center;
          margin-top: 14px;
          font-size: 22px;
        }
      }

      .col-8.col-md-7 {
        text-align: left;

        .line-item-attributes {
          width: 200px;
        }

        .product-card-price {
          width: fit-content;
        }
      }

      @include respond-to-min-width(769px) {
        padding: 15px 15px 0;

        .edit-add-to-wishlist {
          .edit {
            font-size: 14px;
          }
        }
      }
    }

    @include respond-to-max-width(770px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .card.product-info {
        min-width: 500px;
      }
    }

    @include respond-to-max-width(544px) {
      .card.product-info {
        min-width: 0;
      }
    }
  }
}

.my-account {
  .more-wl-items {
    border: none;
    color: $black-text;
    font-size: 14px;
    text-decoration: underline;
    background-color: transparent;
    font-weight: $font-weight-medium;

    &:hover {
      background: none;
      color: $black-text;
      text-decoration: underline;
    }

    &:active {
      color: $black-text;
      background: none;
      outline: none;
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    background: none;
    color: $black-text;
  }
}

@include respond-to-max-width(770px) {
  .btn-inner {
    .checkout-btn-add {
      width: 280px !important;
    }
  }
}
