.pagination-container {
  .page {
    padding-right: 1em;
  }

  &.pagination-sm {
    display: none;
  }

  button {
    box-shadow: none;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .btn {
    box-shadow: none;

    &:hover {
      font-weight: bold;
    }

    &-padding-custom {
      padding: 0 1.5rem;
    }

    &-active {
      border-color: $primary-color-2;
      border-radius: 50%;
      padding: 0.11rem 0.5rem;
    }
  }

  .ellipsis-padding-custom {
    padding: 1rem;
    vertical-align: text-bottom;
  }

  @include respond-to-max-width($max-mobile-width) {
    &.pagination-lg {
      display: none;
    }

    &.pagination-sm {
      display: inline;
    }

    #previous {
      padding: 0 1.5rem;
      font-size: 1rem;
    }

    #next {
      padding: 0 1.5rem;
      font-size: 1rem;
    }

    .btn-padding-custom {
      padding: 0 1rem;
    }
  }
}
