.minicart {
  .popover-bottom {
    &.show {
      min-height: 7.5rem;
    }
  }

  .big-volume {
    &-product {
      margin-bottom: 12px;
    }

    &-title {
      .fa-exclamation-circle {
        color: #fda806;
        transform: rotate(180deg);
      }
    }

    &-tag {
      color: #fda806;
      text-align: left;
      text-decoration: underline;
      font-size: 14px;
    }

    &-message {
      color: #999;
      text-align: left;
      font-size: 12px;
      margin-left: 4%;
    }
  }
}

.minicart .popover-bottom,
.cart-page {
  @import "./miniCartEmpty";
  @import "./productCardMiniCart";
  @import "./miniCartFooter";
}

@import "./miniCartRemoveProductModal";
