.card {
  &.product-info {
    margin-bottom: 20px;
    font-family: $main-font;

    .item-image {
      margin-right: 0;
    }

    @import "../product/stamps";

    .stamps-container {
      margin-bottom: 10px;

      .product-stamp {
        font-size: 12px;
        display: none;

        &:first-child,
        &:nth-child(2) {
          display: block;
        }

        &:first-child {
          padding-left: 0 !important;
          margin-left: 0 !important;
        }

        @include respond-to-max-width(991px) {
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }

    .line-item {
      &-name {
        font-size: 18px;
        color: $primary-color-2;
        font-weight: $font-weight-medium;

        a {
          text-decoration: none;
          color: $primary-color-2 !important;
        }

        &:hover {
          a {
            color: #999 !important;
          }
        }
      }

      &-header {
        .remove-line-item {
          button {
            top: unset;
            background-color: transparent;
          }
        }
      }
    }

    .right-container {
      padding-left: 7px;

      .item-attributes {
        .line-item-availability {
          display: none;
        }

        .edit-product {
          margin-top: auto;
          font-weight: $font-weight-medium;

          a {
            font-size: 14px;
            text-decoration: underline;
          }

          @include respond-to-max-width($max-mobile-width) {
            margin-top: 7px;
          }

          @include respond-to-max-width(991px) {
            display: none;
          }
        }
      }

      .product-card-footer {
        [class*="line-item-price"] {
          display: none;
        }

        .line-item {
          &-quantity {
            margin-left: auto;

            @include respond-to-between(991px, 1200px) {
              margin-right: 14px;
            }

            @include respond-to-max-width(991px) {
              padding-left: 0;
              margin-left: 0;
            }
          }

          &-total-price {
            [class*="item-total"] {
              color: $primary-color-2;
              width: 100px;
              display: block;
              text-align: right;

              @include respond-to-max-width(991px) {
                width: unset;
              }
            }

            .pricing {
              font-size: 18px;
              color: $primary-color-2;
              font-family: $main-font;
              font-weight: bold;

              @include respond-to-max-width(991px) {
                font-size: 16px;
              }
            }

            .strike-through {
              font-size: 16px;
              margin-right: 0;

              @include respond-to-max-width(991px) {
                font-size: 14px;
                margin-left: 15px;
              }
            }

            @include respond-to-max-width(991px) {
              padding-left: 0;
              margin-top: 15px;

              .line-item-total-text {
                margin-right: 5px;
              }

              .price {
                display: flex;
              }
            }
          }
        }

        @include respond-to-max-width(991px) {
          margin-top: 0;

          .row {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .quantity-form.quantity-for-minicart {
        .quantity {
          width: 60px;
          min-width: auto;
          border-color: $primary-color-2;
          -webkit-appearance: none;
          appearance: none;
          background-position: 30px;
          background-repeat: no-repeat;
          background-size: 35% 55%;
          fill: $primary-color-2;
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0xNC43NywyMy43OTVMNS4xODUsMTQuMjFjLTAuODc5LTAuODc5LTAuODc5LTIuMzE3LDAtMy4xOTVsMC44LTAuODAxYzAuODc3LTAuODc4LDIuMzE2LTAuODc4LDMuMTk0LDAgIGw3LjMxNSw3LjMxNWw3LjMxNi03LjMxNWMwLjg3OC0wLjg3OCwyLjMxNy0wLjg3OCwzLjE5NCwwbDAuOCwwLjgwMWMwLjg3OSwwLjg3OCwwLjg3OSwyLjMxNiwwLDMuMTk1bC05LjU4Nyw5LjU4NSAgYy0wLjQ3MSwwLjQ3Mi0xLjEwNCwwLjY4Mi0xLjcyMywwLjY0N0MxNS44NzUsMjQuNDc3LDE1LjI0MywyNC4yNjcsMTQuNzcsMjMuNzk1eiIgZmlsbD0iIzUxNTE1MSIvPjwvc3ZnPg==);
        }

        @include respond-to-max-width(991px) {
          width: 210px;
          margin-top: 30px;

          .quantity-selector-title {
            font-size: 14px;

            p {
              font-family: $main-font;
            }
          }
        }
      }

      .line-item-promo {
        display: none;
      }

      @include respond-to-max-width(991px) {
        padding-left: 15px;
      }
    }

    .personalization {
      .values {
        padding-top: 10px;

        h3 {
          font-size: 18px;
          text-align: left;
          font-weight: $font-weight-medium;
          font-family: $main-font;
        }

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-family: $main-font;
          font-weight: $font-weight-regular;

          .mb-hide {
            @include respond-to-max-width($max-mobile-width) {
              display: none;
            }
          }
        }
      }

      .price {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;

        button {
          background-color: transparent;
          position: initial;
          padding-top: 0;

          @include respond-to-max-width($max-mobile-width) {
            padding: 0;
          }
        }

        p {
          align-self: center;
          text-align: left;
          margin-bottom: 0;
          font-family: $main-font;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    @include respond-to-max-width(991px) {
      margin-bottom: 40px;
    }
  }
}

.icon-limitedProduct {
  path,
  .st9 {
    fill: $secondary-color-1;
  }
}

.stamp-limited-product {
  &-sku {
    .icon-limitedProduct {
      width: 4%;
    }

    &-minicart {
      .icon-limitedProduct {
        width: 8%;
      }
    }
  }
}
