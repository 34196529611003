.payment {
  &-information {
    .payment-options {
      .payment-radio-section {
        .nav-item {
          .credit-card-tab,
          .mercado-pago-tab {
            text-align: left;

            &.active {
              border-bottom: 0.188em solid $secondary-color-1;
            }
          }

          .credit-card-tab {
            .credit-card-option {
              width: 34px;
              height: 31px;
            }
          }

          .mercado-pago-tab {
            .mercadopago-option {
              width: 32px;
              height: 22px;
            }
          }

          .pago-facil-tab {
            .pagofacil-option {
              width: 43px;
              height: 36px;
            }
          }

          .modo-tab {
            text-align: left;
          }
        }

        label.paymentMethod-radio-button {
          font-family: $main-font;
          font-size: 15px;
          font-weight: $font-weight-regular;
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            content: " ";
            display: inline-block;
            position: relative;
            width: 17px;
            height: 17px;
            border-radius: 11px;
            border: 1px solid $primary-color-2;
            background-color: $secondary-color-2;
          }
        }

        input[type="radio"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: $secondary-color-2;

          &:checked + label.paymentMethod-radio-button::after {
            border-radius: 11px;
            width: 7px;
            height: 7px;
            position: absolute;
            content: " ";
            display: block;
            background: white;
          }

          &:checked + label.paymentMethod-radio-button::before {
            background-color: $primary-color-2;
          }
        }

        li {
          width: 100%;

          .nav-link {
            padding: 0 12px;
            border-bottom: none;
          }
        }
      }

      .nav-item {
        .active {
          border-bottom: 0;
        }
      }
    }
  }

  &-form-fields {
    .credit-card {
      &-options-payment {
        font-size: 14px;
        font-weight: $font-weight-medium;
        margin-top: 15px;

        &::before {
          content: "* ";
          color: #c00;
        }
      }

      &-policies-message {
        font-size: 12px;
        color: $black-text;
        padding-top: 15px;
      }
    }

    .horizontal-dashed-payment-line {
      position: absolute;
      border-bottom: 1px dashed $regular-light-gray;
      left: 0;
      width: 100%;
    }

    .user-payment-instruments {
      .add-payment {
        border: none;
        text-decoration: underline;
        font-size: 14px;
        color: $custom-link-color;
        margin: 0;
        font-weight: $font-weight-medium;
        text-transform: initial;

        &:hover {
          background-color: transparent;
        }

        &:active {
          color: $custom-link-color;
          background-color: transparent;
          border-color: transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.credit-card-form {
  .card-number-wrapper {
    &::after {
      content: '';
      background-image: none;
    }

    &[data-type="visa"],
    &[data-type="mastercard"],
    &[data-type="amex"],
    &[data-type="discover"],
    &[data-type="maestro"] {
      &::after {
        background-image: url('../../images/payment-types.png');
        background-size: auto;
      }
    }

    &[data-type="visa"]::after {
      background-position: -162px -110px;
    }

    &[data-type="mastercard"]::after {
      background-position: -295px -110px;
    }

    &[data-type="amex"]::after {
      background-position: -230px -15px;
    }

    &[data-type="discover"]::after {
      background-position: -95px -110px;
    }

    &[data-type="maestro"]::after {
      background-position: -95px -156px;
    }

    &[data-type="naranja"]::after {
      position: absolute;
      right: 3px;
      background-repeat: no-repeat;
      background-image: url('../../images/tarjeta-naranja.png');
      background-size: contain;
      width: 48px;
      height: 30px;
      top: 5px;
    }

    &[data-type="cabal"]::after {
      position: absolute;
      right: 3px;
      background-repeat: no-repeat;
      background-image: url('../../images/tarjeta-cabal.png');
      background-size: contain;
      width: 48px;
      height: 30px;
      top: 5px;
    }
  }

  button.info-icon {
    .icon {
      width: 15px;
      height: 15px;
      font-size: 11px;
      background-color: $custom-link-color;
    }
  }

  .cancel-new-payment {
    display: block;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    margin-top: 0;
    outline: 0;
    font-weight: $font-weight-medium;
  }

  .button-save-new-payment {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .save-new-payment {
      display: block;
      color: $custom-link-color;
      text-decoration: underline;
      width: unset;
      text-transform: initial;
      font-weight: $font-weight-medium;
      font-size: 14px;
    }
  }
}

.flex-container-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content {
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }
}

.right-content {
  margin-left: auto;
  margin-right: 10px;
}

.modo-option {
  height: 29px;
  border-radius: 10px;
}

.account-payment-methods .card-body .payment-form .decidir-form {
  a,
  button {
    border-radius: 25px;
    text-transform: uppercase;
    font-family: $main-font;
  }
}

@include respond-to-max-width(348px) {
  .nav-item a.nav-link {
    padding: 15px 0;
  }
}

@include respond-to-max-width(543px) {
  .modo-tab {
    padding-top: 44px;
    padding-bottom: 15px;
  }

  .mercado-pago-tab {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .credit-card-tab {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  li.nav-item.nav-item-payment {
    width: 72px;
  }
}

@include respond-to-min-width(420px) {
  header ~ #maincontent .container .card-body.shipping-content .shipping-form .shipping-address .tab-container .nav-tabs .nav-item a.nav-link {
    padding-left: 0;
    padding-bottom: 22px;
  }
}

@include respond-to-min-width(407px) {
  header ~ #maincontent .container .card-body.shipping-content .shipping-form .shipping-address .tab-container .nav-tabs .nav-item a.nav-link {
    padding-left: 2px;
    padding-bottom: 22px;
  }
}

@include respond-to-between(769px, 991px) {
  .nav.nav-tabs.nav-fill.payment-options {
    display: flex;
    flex-wrap: nowrap;
  }
}
