
.image-grid-container {
  border: 1px solid #cacaca;
}

.odd-image-grid {
  max-width: 49%;
  margin: auto;
}

#viewMoreImageGrid {
  margin: auto;
  margin-top: 10px;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #222;
  border-radius: 1.56rem;
  background: #fff;
  text-decoration: none;
  transition-duration: 0.2s;
  width: 13.75rem;
  height: 3.125rem;
}

#viewMoreImageGrid:hover{
  background: #222;
  color: #fff;
  text-decoration: none;
}

.view-more-i {
  font-size: 12px;
  margin-left: 5px;
}

.product-image-container-grid.col-6 {
  position: relative;
  padding: 0;
  line-height: 0;

  fieldset {
    position: absolute;
    left: 20px;
    border: none;
    width: 40px;
    height: 40px;
    background-color: $brand-identity;
    top: 0;
    border-radius: 0 0 12px 12px;

    @include respond-to-max-width(991px) {
      left: 8px;
      width: 30px;
      height: 30px;
    }

    legend {
      transform: unset;
      display: flex;
      justify-content: center;
      position: relative;
      top: 18px;
      line-height: 0.5px;
      font-size: 15px;
      color: white;
      margin: 0;

      @include respond-to-max-width(991px) {
        font-size: 12px;
        top: 14px;
      }
    }
  }
}
