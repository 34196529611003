$min-tablet-width:768px;
$max-tablet-width:992px;

.quick-view-dialog,
.choose-bonus-product-dialog {
  .modal {
    &-body.quickView-body {
      padding: 0;
      max-height: 700px;
      height: 100%;

      @include media-breakpoint-up(sm) {
        padding: 30px 25px;
      }
    }

    &-content {
      margin-top: 5%;
    }
  }
}

.quantity-selector {
  width: 100px;
  height: 37px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #cacaca;
  border-radius: 19px;
  padding: 3px 4px 4px;
  min-width: 100px;

  input[type="number"] {
    border: none;
    height: 100%;
    padding: 4px 0 0;
    text-align: center;
    appearance: textfield;
    color: #121212;
    pointer-events: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn-quantity,
  .btn-qty {
    display: flex;
    color: #fff;
    font-weight: bold;
    border: none;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }

  .sub,
  .add {
    background-color: #121212;

    &.btn-qty-disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  > .qty {
    background-color: transparent;
    user-select: none;
    color: #121212;
    border: none;
    -webkit-appearance: textfield;
    appearance: textfield;
    margin: auto;
    display: flex;

    .btn-quantity,
    .btn-qty {
      & + .btn-quantity,
      .btn-qty {
        margin-top: 2px;
      }
    }
  }

  &-wrapper {
    display: flex;
    margin-top: 24px;
  }

  &-title {
    margin-right: 8px;

    p {
      margin: 5px 0 0;
      font-family: $main-font;
    }
  }
}

.quick-view-dialog {
  max-width: 962px;

  .product-detail {
    padding: 0 !important;

    .quick-view-content {
      width: 100%;
    }

    .quick-view {
      &-carousel {
        padding: 0 !important;

        .slider {
          &-single {
            margin-bottom: 14px;
            height: auto;
            display: flex;
            justify-content: center;
            border: 1px solid #cacaca;

            .slick-list.draggable {
              width: 100%;
              height: 100% !important;
            }

            @include media-breakpoint-up(sm) {
              max-height: 490px;
              height: 100%;
              max-width: 490px;
              width: 100%;
            }
          }

          &-nav {
            .slick {
              &-slide {
                max-width: 56px !important;
                cursor: pointer;
                border-radius: 7px;
                margin-right: 10px;
                background: #fff 0% 0% no-repeat padding-box;
                border: 1px solid #cacaca;

                @include respond-to-between(400px, 544px) {
                  max-width: 100% !important;
                }

                @include media-breakpoint-up(sm) {
                  max-width: 100% !important;
                }

                @include media-breakpoint-up(lg) {
                  max-width: 80px !important;
                }
              }

              &-track {
                margin-left: 13px;

                @include media-breakpoint-up(sm) {
                  margin-left: 0;
                }
              }

              &-list.draggable {
                width: 100%;
                height: 100% !important;
              }
            }

            @include media-breakpoint-up(sm) {
              max-width: 490px;
              width: 100%;
            }
          }
        }

        @include media-breakpoint-up(md) {
          padding: 0;
        }

        @include media-breakpoint-up(lg) {
          max-width: 490px;
        }
      }

      &-info {
        padding-right: 15px;
        padding-bottom: 15px;
        margin-left: auto;

        .button-container {
          margin-top: 23px;
          border-top: 1px solid #cacaca;
          padding-top: 30px;

          div.btn-checkout-container {
            margin-top: 12px;
            padding: 0;

            .btn-container {
              padding: 0;
            }
          }

          div.btn-checkout-container,
          div.cart-and-ipay {
            button {
              border-radius: 25px;
              text-transform: uppercase;
              height: 50px;
              font-family: $main-font;
              font-weight: bold;
            }
          }

          div.cart-and-ipay {
            padding-bottom: 0;

            @include respond-to-max-width($max-mobile-width) {
              margin-bottom: 0;
            }

            .pdp-checkout-button {
              padding: 0;
            }

            button {
              background: #121212;
              border-color: #121212;
              margin: 0;
            }
          }
        }

        .stamps-and-product-id-container {
          margin: 15px auto 10px;

          @include media-breakpoint-up(sm) {
            margin-top: 0;
          }
        }

        .detail-panel {
          border-top: none;

          .prices {
            padding: 0;
          }
        }
      }
    }
  }
}

.slider {
  &-single {
    > div:nth-child(1n + 2) {
      display: none;
    }

    &.slick-initialized > div:nth-child(1n + 2) {
      display: block;
    }

    .slick-slide {
      h3 {
        position: relative;
        text-align: center;
        margin: 0;
        line-height: 10rem;

        img {
          margin: auto;
          border-radius: 7px;
        }
      }
    }
  }

  &-nav {
    h3 {
      &::before {
        content: "";
        display: block;
        padding-top: 0;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.slick-slide {
  &.is-active h3 {
    background: #fff 0 0 no-repeat padding-box;
    height: 100%;
    border: 2px solid #121212;
    border-radius: 7px;
  }

  h3 {
    height: 100%;
  }
}
