$modal-width: 375px;
$modal-header-font-size: 20px;
$modal-header-color: $black-text;
$close-modal-button-font-size: 20px;
$modal-header-border-color: #dee2e6;
$modal-body-font-size: 14px;
$modal-product-to-remove-font-size: 16px;
$modal-body-color: $black-text;
$delete-button-bg-color: $primary-color-2;
$delete-button-height: 50px;
$delete-button-font-size: 15px;
$cancel-button-color: $custom-link-color;
$cancel-button-font-size: 14px;
$bootstrap-media-modal-sm: 576px;

.modal {
  &-header {
    &.delete-confirmation-header,
    &.delete-coupon-confirmation-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $modal-header-border-color;
      background-color: unset;

      .close {
        font-size: unset;
        margin: 0;
        padding: 0;

        span {
          margin-right: 0;
          font-size: $close-modal-button-font-size;
        }

        img.close-img{
          width: 16px;
        }
      }
    }
  }

  &-body {
    &.delete-confirmation-body,
    &.delete-coupon-confirmation-body {
      font-family: $main-font;
      font-size: $modal-body-font-size;
      font-weight: $font-weight-regular;
      color: $modal-body-color;
    }
  }

  &-title {
    font-family: $main-font;
    font-size: $modal-header-font-size;
    font-weight: $font-weight-medium;
    color: $modal-header-color;
  }

  &-footer {
    border-top: none;
    padding: 0 1rem 1rem;
    flex-direction: column-reverse;

    .cart-delete-confirmation-btn,
    .delete-coupon-confirmation-btn,
    .delete-confirmation-btn {
      background: $delete-button-bg-color 0% 0% no-repeat padding-box;
      border-radius: 25px;
      border: none;
      height: $delete-button-height;
      width: 100%;
      margin-left: 0;
      font-size: $delete-button-font-size;
      margin-bottom: 10px;

      &:active {
        background: $delete-button-bg-color 0% 0% no-repeat padding-box;
        border-radius: 25px;
        border: none;
        height: $delete-button-height;
        width: 100%;
        margin-left: 0;
        font-size: $delete-button-font-size;
        margin-bottom: 10px;
      }
    }

    .btn {
      &-outline-primary {
        margin-right: 0;
        color: $cancel-button-color;
        border: none;
        text-decoration: underline;
        font-weight: $font-weight-medium;
        font-size: $cancel-button-font-size;
        padding: 0;

        &:hover {
          background-color: inherit;
          border: none;
        }

        &:active {
          border: none;
        }
      }
    }
  }
}

#removeProductModal,
#deletePaymentModal,
#deleteAddressModal {
  .modal-dialog {
    @include respond-to-min-width($bootstrap-media-modal-sm) {
      max-width: $modal-width;
    }
  }

  .payment-to-remove,
  .coupon-to-remove,
  .product-to-remove {
    font-family: $main-font;
    font-size: $modal-product-to-remove-font-size;
    font-weight: $font-weight-medium;
    color: $modal-body-color;
    margin-bottom: 0;
  }
}
