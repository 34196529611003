$robotoFont: "Roboto", sans-serif;

[data-action="Account-EditProfile"] {
  .breadcrumb {
    &.wishlist-breadcrumbs {
      padding-left: 10px;
    }

    &-container {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      padding: 0.75rem 2.5rem 0;
      font-family: $main-font;

      @include respond-to-max-width(991px) {
        display: block;
      }

      @include respond-to-max-width(1200px) {
        padding: 0.75rem 2rem 0;
      }

      @include respond-to-max-width(991px) {
        padding: 0.75rem 1.5rem 0;
      }

      @include respond-to-max-width(769px) {
        padding: 0.75rem 0.75rem 0;
      }
    }
  }
}

.card {
  &-header {
    background-color: #f9f9f9;

    h2 {
      color: #121212;
      font-family: $main-font;
      font-size: 1.25rem;
    }
  }
}

.edit-profile-form,
.change-password-form {
  width: 95%;
  display: block;
  margin: auto;

  .row {
    align-items: baseline;

    &.birth-edit {
      font-size: 14px;
      margin-bottom: 0;
      color: #999;

      .required-asterisk {
        color: #999;
      }
    }
  }

  input[type="text"],
  select,
  .form-control {
    border: none;
    border-bottom: solid 1px #999;
    border-radius: 0;
    font-size: 14px;
  }

  .form {
    &-control {
      &:has(~ .form-control-label) {
        margin-block: 20px;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &-label {
        &:has(~ input.form-control:focus),
        &:has(~ input.form-control:valid),
        &:has(~ input.form-control.is-valid),
        &:has(~ input.form-control:-webkit-autofill),
        &:has(~ input.form-control[readonly]),
        &:has(~ select.form-control) {
          transform: translateY(-30px);
          color: #999;
        }

        &:has(~ .form-control) {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0 5px;
          transition: 0.3s;
          color: #999;
          pointer-events: none;
          z-index: 30;
          font-size: 14px;
        }
      }
    }

    &-group:not(.birth-edit) {
      position: relative;
      padding-top: 30px;
    }
  }

  .col-4 .form-group {
    padding-top: 0;
  }
}

.edit-profile {
  &-cancel,
  &-save {
    color: $background;
    border: none;
    border-radius: 25px;
    padding: 10px;
    height: 50px;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: bold;

    &:hover {
      box-shadow: none !important;
      color: $background;
      border: none;
    }
  }

  &-cancel {
    line-height: 1.8;
    display: inline-block;
    background-color: $black-text;

    &:hover {
      background-color: $black-text;
    }
  }

  &-save {
    background-color: $secondary-color-1;

    &:hover {
      background-color: $secondary-color-1;
    }
  }

  &-container {
    max-width: 100%;
  }

  &-title {
    border-bottom: 1px solid #cacaca;
    color: $black-text;
    font-size: 30px;
    font-weight: $font-weight-medium;
    margin: 0 13px 30px;
    padding-bottom: 10px;
    position: relative;

    &-container {
      padding: 0 20px;
    }

    @include respond-to-between(545px, 1341px) {
      margin: 0 38px 30px;
    }

    @include respond-to-min-width(1342px) {
      margin: 0 25px 30px;
    }
  }

  &-form {
    @include respond-to-min-width($max-mobile-width) {
      width: 80%;
    }
  }

  &-form-container {
    margin: 0 13px;

    @include respond-to-min-width($max-mobile-titles-width) {
      margin: 0;
    }
  }
}
