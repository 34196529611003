
div[data-action="Account-SetNewPassword"],
div[data-action="Account-SaveNewPassword"] {
  background-color: #fff;

  .hero.login-banner {
    display: none;
  }

  #maincontent {
    .container {
      min-height: 450px;

      .card {
        border: none;
        margin-top: 15px;

        &-header {
          text-align: center;
          border: none;

          h4 {
            font-size: 24px;
            font-family: $main-font;
            font-weight: 500;
          }
        }

        &-body {
          form {
            .form-group {
              label {
                font-family: $main-font;
                font-weight: 400;
                font-size: 14px;
              }

              input {
                font-family: $main-font;
                height: 40px;
                border-color: $primary-color-2;
                font-size: 14px;

                &::placeholder {
                  color: transparent;
                }
              }
            }

            > .row {
              margin-top: 25px;

              button {
                font-weight: 600;
                border: none;
                font-family: $main-font;
                height: 50px;
                background-color: $secondary-color-1;
                font-size: 15px;
              }

              a {
                font-weight: 600;
                border: none;
                color: white;
                height: 50px;
                background-color: $primary-color-2;
                font-size: 15px;
                padding: 12.5px;
              }
            }
          }
        }

        @include respond-to-min-width($max-mobile-width) {
          max-width: 550px;
          border: 1px solid $regular-light-gray;
          padding: 20px 80px 25px;
          margin-top: 90px;
          margin-bottom: 150px;
        }
      }
    }
  }
}
