.multi-shipping {
  .card {
    &-header {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
    }

    &-footer {
      display: none;
      border-top: 1px dashed rgba(0, 0, 0, 0.125);
      border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 0 0 20px;

      .gift-message-block {
        padding: 0;

        .form-group {
          margin-bottom: 0;

          .custom-checkbox {
            margin-bottom: 10px;
            margin-top: 10px;

            .custom-control-input:checked ~ .custom-control-label::after {
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
              background-color: $primary-color-2;
            }

            label {
              padding-top: 2px;
              font-family: $main-font;
              font-size: 14px;

              &::before,
              &::after {
                @include respond-to-min-width($max-mobile-width) {
                  left: 5px;
                }
              }

              &::after {
                cursor: pointer;
                background-color: white;
                border: 1px solid black;
                border-radius: 3px;
              }

              i {
                margin-left: 5px;
                margin-right: 5px;
                font-size: 18px;
              }

              @include respond-to-min-width($max-mobile-width) {
                padding-left: 25px;
              }
            }

            &.privacy-policy {
              a {
                font-weight: $font-weight-medium;
                color: $black-text;
                text-decoration: underline;
              }
            }

            @include respond-to-min-width($max-mobile-width) {
              padding-left: 0;
            }
          }
        }
      }
    }

    .save-address-container {
      display: flex;
      justify-content: center;

      .save-address {
        text-decoration: underline;
      }
    }

    .new-shipping-address {
      margin-top: 10px;
      justify-content: center;
      display: flex;

      .cancel-new-shipping-address {
        border: 0;
        background-color: transparent;
        color: $primary-color-2;
        text-decoration: underline;
        display: block;
        margin: 0 auto;
      }
    }

    .address-validation-btn-container {
      text-align: center;

      .address-validation-btn {
        max-width: 370px;
        margin: 20px auto auto;
        background-color: $primary-color-2;
        border-color: $primary-color-2;
        height: 50px;
        font-size: 15px;
        font-weight: $font-weight-bold;
        color: white;

        &.validate {
          .validated {
            display: none;
          }
        }

        &.validated {
          background-color: $green-color;
          border-color: $green-color;
          pointer-events: none;

          .validate {
            display: none;
          }
        }
      }
    }
  }

  h3,
  h5 {
    margin-top: 15px;
    font-size: 14px;
    font-family: $main-font;
    font-weight: $font-weight-medium;
  }

  p {
    [class^="ship-to"] {
      font-size: 14px;
      font-family: $main-font;
      font-weight: $font-weight-regular;
    }
  }
}
