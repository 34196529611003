$customMobileBreak: 1341px;

.container-insert-postal {
  color: #fff;
  margin-top: -1.5rem;

  @include respond-to-max-width($customMobileBreak) {
    border-top: 1px solid #fff;
    height: 46px;
    display: flex;
    padding-left: 10px;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 0;
  }

  @include respond-to-min-width($customMobileBreak) {
    width: max-content;
  }
}

.modal-before-header-postal-code {
  @include font(700, 16px, 24px);

  font-family: $main-font;
}

.header__cart-page {
  + .container-insert-postal {
    position: relative;

    @include respond-to-min-width($customMobileBreak) {
      padding-left: 17px;
    }

    @include respond-to-max-width($customMobileBreak) {
      padding-left: 0;
    }
  }
}

.postal-code-insert {
  cursor: pointer;

  b {
    text-transform: capitalize;
  }
}

.postal-code-text {
  padding-left: 10px;
  font-size: 0.875rem;
}

.arrow-up {
  display: none;
}

.simple-modal {
  --gutter: 14px;
  --modal-color: #800000;
  --soft-color: #fafafa;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);

  @include respond-to-max-width($customMobileBreak) {
    display: flex;
  }

  &__content {
    width: 90vw;
    height: fit-content;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    @include respond-to-max-width($customMobileBreak) {
      align-self: center;
      justify-self: center;
      margin: auto;
    }

    header {
      border-bottom: 2px solid #cacaca;
      display: flex;
      padding: 1rem;
      -ms-flex-align: center;
      align-items: center;

      h4 {
        margin: 0;
        text-align: left;
        font-family: $main-font;
        font-weight: 900;
        font-size: 1.25rem;
      }

      .header-desktop {
        display: none;
      }

      span {
        font-family: $main-font;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 1.87rem;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      .close-img {
        cursor: pointer;
        width: 16px;
        right: 1rem;
        position: absolute;
      }
    }

    .modal-main {
      flex: 1;
      text-align: left;
      overflow: auto;
      padding: var(--gutter);
    }

    .modal-location {
      font-family: $main-font;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 900;
    }

    .modal-location-mobile {
      display: block;
      font-size: 14px;

      b span {
        text-transform: capitalize;
      }
    }

    .modal-subtitle {
      font-family: $main-font;
      font-size: 0.87rem;
    }

    .form-group {
      margin-bottom: 0.625rem;
    }

    .form-group label {
      @include font(700, 14px, 20px);

      font-family: $main-font;
      display: inline-block;
      font-weight: 500;
      margin-bottom: 0;
    }

    .input-group {
      flex-wrap: nowrap;
    }

    .input-group input {
      font-family: $main-font;
      color: #121212;
      width: 100%;
      height: 38px;
      font-size: 16px;
      background-color: transparent;
      border: 1px solid #000;
      padding: 12px;
      text-align: left;
      border-radius: 3px 0 0 3px;
    }

    .input-group button {
      font-family: $main-font;
      font-weight: 500;
      display: inline-block;
      width: 108px;
      height: 38px;
      background-color: #121212;
      color: #fff;
      border: none;
      border-radius: 0 3px 3px 0;
      outline: none;
    }

    .postal-code-find a {
      font-family: $main-font;
      margin-top: 12px;
      color: #0070d2;
      font-size: 0.875rem;
      font-weight: 500;
      display: block;
    }

    .postal-code-amenities {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 0.875rem;
    }

    .package-svg,
    .sended-svg {
      height: 45px;
      width: 45px;

      path {
        fill: #000;
      }
    }

    .fa-map-marker {
      font-size: 34px;
      margin-top: 5px;
      margin-bottom: 6px;
    }

    .title-amenities {
      font-family: $main-font;
      font-weight: 500;
      font-size: 0.87rem;
      padding: 0;
    }
  }
}

@include respond-to-min-width($customMobileBreak) {
  .arrow-up {
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 18px solid #fff;
    margin-left: 130px;
    margin-top: -15px;
    text-shadow: 2px 2px 6px #00000029;
    opacity: 1;
  }

  .simple-modal {
    --gutter: 16px;
    --modal-color: #800000;
    --soft-color: #fafafa;

    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    padding-top: 115px;
    background-color: transparent;

    .modal-main {
      padding-top: 14px;
    }

    &__content {
      margin: 0 40px 40px 45px;
      height: fit-content;
      max-width: 332px;
      background: #fff 0% 0% no-repeat padding-box;
      box-shadow: 2px 2px 6px #00000029;
      border-radius: 6px;

      header {
        border: none;
        padding-top: 19.94px;
        padding-left: 16px;
        padding-bottom: 0;

        .header-desktop {
          display: block;
        }

        .location-desktop {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 14px;

          b span {
            text-transform: capitalize;
            font-size: inherit;
            display: unset;
          }
        }

        .mobile {
          display: none;
        }

        h4 {
          font-size: 1rem;
        }

        img {
          display: none;
        }
      }
    }

    .modal-location {
      font-family: $main-font;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 900;
    }

    .modal-location-mobile {
      display: none;
    }

    .modal-subtitle {
      margin-top: 0;
    }

    .postal-code-amenities {
      display: none;
    }
  }
}
