$saving-and-installments-font-size-desktop: 14px;
$saving-and-installments-font-size-mobile: 12px;
$saving-and-installments-color: $breadcrumb-active-svg;
$saving-and-installments-color-cc: $product-cc-pdp-msg;

.saving-and-installments-container {
  font-family: $main-font;
  font-size: $saving-and-installments-font-size-desktop;
  font-weight: $font-weight-regular;
  color: $saving-and-installments-color;

  .low-stock {
    font-size: 16px;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
  }

  @include respond-to-max-width($max-mobile-width) {
    font-size: $saving-and-installments-font-size-mobile;
  }

  .highlight-installment-price-pdp {
    color: $saving-and-installments-color;
    font-weight: bold;
    margin: 5px 0;
  }

  &.product-cc-msg {
    color: $saving-and-installments-color-cc;
    align-items: center;
    display: flex;

    .fa-exclamation-circle {
      margin-right: 5px;
      transform: rotate(180deg);
    }

    .low-stock {
      font-size: 16px;
      margin: 0;
    }
  }
}
