.b-wishlist_modal {
  text-align: center;

  &-title {
    @include font($font-weight-bold, 16px, 20px);

    color: $black-dabra-color;
    font-family: $main-font;
  }

  &-text {
    @include font($font-weight-regular, 14px, 20px);

    color: $black-dabra-color;
    font-family: $main-font;
  }

  &-cancel_btn {
    @include font($font-weight-regular, 12px, 16px);

    background: transparent;
    border: none;
    color: $blue-dabra-color;
    cursor: pointer;
    font-family: $main-font;
    outline: none;
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 16px;
    padding: 0 30px;
  }

  &-save_btn {
    @include font($font-weight-regular, 11px, 16px);

    background: $black-dabra-color;
    border: none;
    border-radius: 25px;
    color: $white-dabra-color;
    cursor: pointer;
    font-family: $main-font;
    outline: none;
    padding: 10px 15px;
    width: 150px;

    &:hover {
      color: $white-dabra-color;
    }
  }

  .modal-dialog .modal-content {
    max-width: 355px;
    margin-inline: auto;
  }
}
