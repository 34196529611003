.modal-stores-availability {
  .modal-header {
    border-bottom-color: #cacaca !important;
    position: relative;

    .close {
      padding: 0;
      font-size: unset !important;
      position: absolute;
      right: 14px;
      top: 14px;
      margin: 0;
    }

    .close-img {
      width: 16px;
      vertical-align: bottom;
    }

    .modal-title {
      font-size: 20px;
      font-family: $main-font;
      font-weight: bold;
      line-height: 26px;
    }
  }

  .modal-body {
    padding: 15px;
    border: none;

    .zone {
      margin-bottom: 10px;

      label {
        color: #b3b3b3;
        font-size: 14px;
        font-weight: 400;
        font-family: $main-font;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .form-control {
        padding: 0;
        border: none;
        border-bottom: solid 1px #cacaca;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .results {
      margin-bottom: 20px;

      p {
        font-weight: bold;
        font-size: 14px;
        font-family: $main-font;
        line-height: 20px;
        margin: 0;
      }
    }

    .product-availaiblity {
      gap: 15px;
      display: flex;
      flex-direction: column;
      max-height: 550px;
      overflow-y: hidden;

      &.see-more {
        overflow-y: auto;
      }
    }

    .store-item {
      margin: 0;
      border: none;
      padding-block-end: 15px;
      border-bottom: dashed 1px #cacaca;

      p {
        margin: 0;
        font-family: $main-font;
        font-size: 14px;
        line-height: 22px;
        font-weight: medium;
      }

      .card-header {
        background: none;
        padding: 0;
        border: none;

        h6 {
          margin: 0;
          font-family: $main-font;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
      }

      .card-body {
        padding: 0;
      }
    }

    .sizes-title {
      margin-top: 12px;

      button {
        padding: 0;
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: bold;
        font-family: $main-font;
        color: #121212;
        outline: none;
        border-color: transparent;

        i {
          margin-left: 15px;
        }

        &[aria-expanded="true"] {
          i {
            rotate: 180deg;
            transition: all 0.333s ease;
          }
        }
      }
    }

    .custom-table {
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 14px;
      font-family: $main-font;
      margin-top: 12px;
    }

    .table {
      &-title {
        font-size: 14px;
        font-weight: bold;
        font-family: $main-font;
        border-bottom: solid 1px #cacaca;
        padding-bottom: 8px;
        margin-bottom: 8px;
      }

      &-row {
        font-size: 14px;
        font-weight: medium;
        font-family: $main-font;
        padding-bottom: 4px;
        color: #121212;

        &:last-child {
          padding-bottom: 0;
        }

        &.no-stock {
          color: #b3b3b3;
        }
      }
    }

    .no-results-stores {
      i {
        margin: 10px auto;
        display: block;
        text-align: center;
        font-size: 50px;
      }

      p {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        font-family: $main-font;
        line-height: 22px;
      }
    }
  }

  .modal-footer {
    padding-top: 0;
    border: none;

    .show-all-stores {
      padding: 0;
      border: none;
      background: transparent;
      font-size: 14px;
      font-weight: bold;
      font-family: $main-font;
      margin: 0 auto;
      color: #121212;
      outline: none;

      i {
        margin-left: 15px;
      }

      &[aria-expanded="true"] {
        i {
          rotate: 180deg;
          transition: all 0.333s ease;
        }
      }

      &.hide {
        display: none;
      }
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(75deg, #cfcfcf 14%, #d1d1d1 64%);
      border-radius: 9px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(13deg, #ccc 5%, #d6d6d6 74%);
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 9px;
      box-shadow: inset 7px -4px 12px #f0f0f0;
    }
  }
}
