.account-wishlist-item {
  &.card {
    border: none;

    .line-item-header {
      position: relative;

      .remove-from-wishlist {
        background-color: transparent;
        z-index: 1;
        border: none;
        position: absolute;
        right: 0;
        padding: 0;
      }
    }
  }
}
