@import "../../utilities/mixins";

.slider-mobile-PDP-modal {
  touch-action: auto;
}

.slider-mobile-PDP,
.nav-mobile-PDP {
  &.slick-initialized.slick-slider {
    .slick-list.draggable {
      width: 100%;
      height: 100% !important;
    }
  }
}

.slider-mobile-PDP,
.slider-mobile-PDP-modal {
  &.slick-initialized.slick-slider {
    margin-bottom: 14px;
    height: auto;
    display: flex;
    justify-content: center;
    border: none;
    width: 100%;

    > div:nth-child(1n + 2) {
      display: none;
    }

    > div:nth-child(1n + 2) {
      display: block;
    }

    h3 {
      position: relative;
      text-align: center;
      margin: 0;
      line-height: 10rem;

      img {
        margin: auto;
      }
    }

    .slick {
      &-slide {
        background: #fff 0% 0% no-repeat padding-box;

        h3 {
          height: 100%;
          background: #fff 0 0 no-repeat padding-box;
        }
      }

      &-track {
        line-height: 0;
      }

      &-list.draggable {
        width: 100%;
        height: 100% !important;
        border: none;
      }
    }
  }
}

.slider-mini-mobile-PDP-modal,
.nav-mobile-PDP {
  .slick-initialized.slick-slider {
    margin-left: -10px;

    .slick-list.draggable {
      width: 100%;
      height: 100% !important;

      .slick-track {
        .slick-slide {
          max-width: 58px !important;
          cursor: pointer;
          border: none;
          border-radius: 4px;
          margin-right: 10px;
          background: #fff 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}

.nav-mobile-PDP {
  &.slick-initialized.slick-slider {
    margin-left: -10px;

    .slick-list.draggable {
      width: 100%;
      height: 100% !important;

      .slick-track {
        .slick-slide {
          img {
            border-radius: 4px;
          }
        }
      }
    }
  }

  h3 {
    span {
      @include center;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 75%;
    }
  }
}

.h3-pdp-video-item {
  line-height: 0;
}

.slider-mobile-PDP h3 {
  line-height: 10rem;
}

.slider-mobile-PDP-modal .slick-slide h3 img {
  margin: auto;
}

.slider-mobile-PDP-modal h3 {
  line-height: 10rem;
}

.nav-mobile-PDP h3::before {
  content: "";
  display: block;
  padding-top: 75%;
}

.nav-mobile-PDP h3 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-mini-mobile-PDP-modal h3::before {
  content: "";
  display: block;
  padding-top: 75%;
}

.slider-mini-mobile-PDP-modal h3 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-slide.is-active h3 {
  background: #fff 0 0 no-repeat padding-box;
  height: 100%;
  border: 2px solid #121212;
  border-radius: 4px;
}

.slick-slide h3 {
  height: 100%;
}

.nav-mobile-PDP h3::before {
  padding-top: 0;
}

.nav-mobile-PDP .slick-slide h3 img {
  border-radius: 4px;
}

.slider-mini-mobile-PDP-modal h3::before {
  padding-top: 0;
}

.slider-mini-mobile-PDP-modal .slick-slide h3 img {
  border-radius: 4px;
}

.h3-pdp-video-item {
  line-height: 0;
}

.videoLink {
  &-content {
    position: relative;
    width: 100%;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
    pointer-events: none;
    z-index: 1;

    i {
      @include center;

      font-size: 26px;
      color: #eee;
      z-index: 2;
    }
  }
}

.slider-mobile-PDP-modal .slick-track {
  line-height: 0;
}

.pastel fieldset {
  position: absolute;
  left: 20px;
  border: none;
  width: 40px;
  height: 40px;
  background-color: $brand-identity;
  top: 0;
  border-radius: 0 0 12px 12px;
  font-family: $main-font;
  font-weight: 400;

  legend {
    transform: unset;
    display: flex;
    justify-content: center;
    position: relative;
    top: 18px;
    line-height: 0.5px;
    font-size: 15px;
    color: white;
    margin: 0;

    @include respond-to-max-width(991px) {
      font-size: 12px;
      top: 14px;
    }
  }

  @include respond-to-max-width(991px) {
    left: 8px;
    width: 30px;
    height: 30px;
  }
}

.expand-pdp-img-mobile {
  font-size: 1.5rem;
}

header ~ #maincontent .container.product-detail {
  .modal-header.custom-pdp-modal-header {
    background-color: #fff;
    border-bottom: none;
    height: 3rem;
    position: absolute;
    top: 0;
    right: 0;

    .close-img-expand-modal {
      font-weight: bolder;
      font-size: 1rem;
      width: 1rem;
    }
  }

  .modal-content.modal-content-carousel-pdp-modal {
    border: none;
    scale: 1.05;
  }

  #carousel-pdp-modal.modal.fade {
    background-color: #fff;
    opacity: 1;
  }

  .modal-body.pdp-expand-modal-body {
    padding-top: 0;
  }
}

