/*
  Base variables
  These variables are the default variables of our site.
  If we need them to be different we will override them in our custom cartridge.
  All variables declared here needs the '!default' tag. This will allow other cartridges to override them.
  See docs for more info: https://sass-lang.com/documentation/variables#configuring-modules
*/

$family: 'Poppins' !default;

@mixin google-font($family) {
  @import url(https://fonts.googleapis.com/css2?family=#{$family}:wght@300;400;500;700&display=swap);
}

@include google-font($family);

// Main colors
$primary-color-1: blue !default;
$primary-color-2: gray !default;
$secondary-color-1: lightblue !default;
$secondary-color-2: #333 !default;
$background: #fff !default;

// Gray color for multiple purposes
$light-black: #212121 !default;
$dark-gray-text: #393c3e !default;
$dark-gray: #999 !default;
$light-gray: #f2f2f2 !default;
$medium-light-gray: #f2f2f2 !default;
$regular-light-gray: #cacaca !default;

// Utility colors
$border-gray: #979797 !default;
$carousel-indicators: #ccc !default;
$carousel-indicator-active: #333 !default;
$filters-close: #ffffde !default;
$custom-link-color: #0070d2 !default;
$green-color: #27ae60 !default;
$icon-color: white !default;

//Spacing units
$margin-big: 60px !default;
$margin-small: 30px !default;

//Fonts
$main-font: $family, sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

//Text
$black-text: #222 !default;
$text-gray: #666 !default;
$text-light-gray: #333 !default;
$font-gray: #444 !default;

// Media query
$min-mobile-width: 540px !default;
$max-mobile-width: 768px !default;
$max-tablet-width: 992px !default;

// Media query small devices
$max-small-device: 750px !default;

// Breadcrumb Media Query
$max-mobile-titles-width: 544px !default;
$min-tablet-titles-width: 545px !default;
$max-tablet-titles-width: 1341px !default;
$min-desktop-titles-width: 1342px !default;

// Site Palette
$black-dabra-color: #121212 !default;
$white-dabra-color: #fff !default;
$green-dabra-color: #559550 !default;
$blue-dabra-color: #0070d2 !default;
$gray1-dabra-color: #999 !default;
$gray2-dabra-color: #cfcfcf !default;
$gray3-dabra-color: #d1d1d1 !default;
$gray4-dabra-color: #cacaca !default;
$gray5-dabra-color: #ccc !default;
$gray6-dabra-color: #f0f0f0 !default;
$gray7-dabra-color: #d6d6d6 !default;
$gray8-dabra-color: #adadad !default;

// Colors Section
$promotional-banner: #fff !default;
$bg-color-header:#121212 !default;
$background-nav: #fff !default;
$bg-color-footer:#dbdbdb !default;
$text-color-footer: #000 !default;
$text-color-footer-copy: #ededed !default;
$social-links-color: #000 !default;
$bg-color-navbar-menu-mobile: #121212 !default;
$bg-color-navbar-menu-bg-mb: #fff !default;
$border-bottom-color: #f9f9f9 !default;
$btn-subscribe: #e31523 !default;
$brand-identity: #ed3237 !default;
$bg-color-btn-login: #121212 !default;

// Color Stamps
$product-promotion-stamp-color: #ef4011 !default;
$order-promotion-stamp-color: $green-color !default;
$shipping-promotion-stamp-color: $green-color !default;
$is-launch-stamp-color: $green-color !default;
$is-customizable-stamp-color: $green-color !default;
$product-editable-stamp-color:#ef4011 !default;
$product-cc: #dc4f2b;
$product-cc-pdp-msg: #fda806;

// Color Breadcrumb
$breadcrumb-active-border: #559550 !default;
$breadcrumb-active-background: #fff !default;
$breadcrumb-inactive-background: $medium-light-gray !default;
$breadcrumb-inactive-svg: #2b2b2b !default;
$breadcrumb-active-svg: #559550 !default;
$inforbar-border-buttom: #979797;
//Color Order Tabs
$text-tab-color: $black-text !default;
$border-tab-color: $primary-color-1 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1320px
) !default;
