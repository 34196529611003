$menu-popover-desktop-width: 360px;
$menu-popover-item-font-size: 18px;
$customMobileBreak: 1341px;

[data-action="Cart-Show"]{
  nav[role="navigation"] {
    .navbar-header {
      &.brand {
        position: relative;
        left: 86px;
        top: 6px;

        .logo-home {
          @include respond-to-min-width($max-mobile-width) {
            text-align: left;

            img {
              width: 120px;
            }
          }
        }

        @include respond-to-max-width( $customMobileBreak) {
          top: 10px;
        }
      }
    }
  }
}

nav[role="navigation"] {
  font-family: $main-font;
  // Copy styles from .user div in order to preserve styles
  // for wishlist heart icon but avoid the
  // popover show that is attached on that class
  div {
    &.wishlist-header-icon {
      line-height: 2.25em;
      height: auto;
      display: inline-block;
      position: relative;

      i {
        color: $icon-color;
        font-size: 22px;
      }
    }
  }

  .main-menu {
    background-color: $background-nav;

    @include respond-to-min-width($max-mobile-width) {
      border-bottom: 0.5px solid #979797;
    }

    @include respond-to-max-width($max-mobile-width) {
      background-color: $bg-color-navbar-menu-bg-mb;
    }
  }

  .header {
    &.container {
      > .row {
        .navbar-header {
          .navbar-toggler {
            color: $icon-color;

            &:focus {
              outline: none;
            }
          }

          .user {
            i {
              color: $icon-color;
            }
          }

          .minicart {
            .minicart-link {
              color: $icon-color;
              height: 42px;
              width: 24px;
            }
          }
        }

        .menu-mobile {
          @include respond-to-min-width(1342px){
            display: none;
          }
        }
      }
    }

    .navbar-header {
      &.brand {
        position: relative;
        left: 86px;

        .logo-home {
          @include respond-to-min-width($max-mobile-width) {
            text-align: left;

            img {
              width: 120px;
            }
          }
        }
      }

      .user {
        .user-message {
          padding: 0;
          color: $social-links-color;
        }

        .popover {
          width: $menu-popover-desktop-width;
          padding: 0;
          left: calc(-275px + 100%);

          ul {
            &.nav {
              li {
                &.nav-item {
                  padding: 14px 27px;
                  width: 100%;
                  font-family: $main-font;
                  font-size: $menu-popover-item-font-size;
                  font-weight: bold;
                  border-bottom: solid 1px #eee;

                  a {
                    color: $black-text;
                  }

                  &:last-child {
                    border-bottom: unset;

                    a {
                      color: $primary-color-1;
                    }
                  }
                }
              }
            }
          }

          @include respond-to-max-width($max-mobile-width) {
            width: 100%;
            position: fixed;
            left: 0;
            right: 0;
            max-width: 100%;
          }
        }
      }

      &.right-menu {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
        padding-right: 15px;

        .user,
        .minicart,
        .wishlist-header-icon {
          display: inline-flex;
          margin: 0;
        }
      }

      .search {
        margin: 0;
        width: 100%;

        .site-search {
          margin-right: 0;
        }
      }
    }
  }

  @include respond-to-max-width(1341px) {
    .header {
      .navbar-header {
        .brand {
          display: inline-flex;
          margin: 0;
          padding: 0;
          width: auto;
        }
      }
    }

    .main-menu {
      margin-right: 0;

      &.multilevel-dropdown {
        &.menu-toggleable-left.navbar-toggleable-sm {
          left: 0;
          width: 100%;
          z-index: 99;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .close-menu {
          width: 35px;
          display: flex;
          justify-content: center;
          padding: 15px;
          max-height: 70px;
          align-items: center;

          .back {
            button {
              outline: none;
              box-shadow: none;
              display: none;
              color: $background-nav;
              font-size: 18px;

              span {
                margin-right: 10px;
              }
            }

            img {
              width: 100px;
              height: 25px;
            }
          }

          .close-button {
            margin-left: auto;

            button {
              padding: 0;
              line-height: 40px;
              margin-right: 15px;

              span {
                font-size: 37px;
                color: $background-nav;
              }
            }
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        border-top: none;

        .container {
          padding: 0;
        }
      }
    }

    .search-mobile {
      .site-search {
        height: 46px;
        width: 100%;

        .search-field {
          font-family: $main-font;
          height: 46px;
          border-radius: 0;
          padding-left: 80px;
        }

        .fa-search {
          top: 15px;
          left: 32px;
          right: unset;
        }

        @include media-breakpoint-up(sm) {
          margin-right: 0; /* 20/16 */
        }

        @include media-breakpoint-up(xl) {
          width: 100%; /* 320/16 */
        }

        @include media-breakpoint-only(md) {
          width: 100%; /* 225/16 */
        }

        @include media-breakpoint-only(sm) {
          width: 100%; /* 200/16 */
        }
      }
    }

    .account-mobile {
      background-color: white;
      position: absolute;
      z-index: 1000;
      width: 100%;

      ul {
        &.dropdown-menu {
          margin-top: 0;
          width: 100%;
          padding: 0;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

          li {
            &.dropdown-item {
              padding: 14px 27px;
              width: 100%;
              font-family: $main-font;
              font-size: $menu-popover-item-font-size;
              font-weight: bold;
              border-bottom: solid 1px #eee;

              &:active {
                background-color: unset;
              }

              a {
                color: $black-text;
              }

              &:last-child {
                border-bottom: unset;

                a {
                  color: $primary-color-1;
                }
              }
            }
          }
        }
      }

      .user {
        height: 46px;
        padding-top: 15px;
        padding-left: 24px;

        a {
          font-family: $main-font;
          font-size: 18px;
          font-weight: 700;

          i {
            color: $icon-color;
          }
        }
      }

      .nav-item {
        list-style-type: none;
        font-family: $main-font;

        .nav-link.dropdown-toggle {
          display: none;

          a[data-level="first"][role="button"] {
            @include respond-to-min-width($max-mobile-width) {
              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        .dropdown-menu {
          border: none;
          width: 100%;
          position: initial;

          .dropdown-item {
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: #eee solid 1px;

            &:last-child {
              a {
                color: $primary-color-1;
              }
            }

            a {
              font-family: $main-font;
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@include respond-to-min-width($customMobileBreak) {
  .main-menu {
    display: block;
  }

  .navbar-expand-md {
    flex-flow: row nowrap;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
}

@include respond-to-max-width($customMobileBreak) {
  .main-menu {
    display: none;

    &.in {
      display: block;
    }
  }

  .navbar-expand-md {
    flex-flow: row wrap;

    .navbar-nav {
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
}
