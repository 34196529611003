$empty-message-container-border-color: $medium-light-gray;
$empty-message-font-size: 16px;

.mini-cart-empty {
  &-message {
    font-size: $empty-message-font-size;
    font-weight: $font-weight-medium;
    color: $black-text;

    &-container {
      border-radius: 3px;
      padding: 15px;
      text-align: center;
      margin-top: 50px;
      border: none;
    }
  }
}

