$max-mobile-width: 768px;

.search-results {
  .filter-header {
    @include respond-to-max-width($max-mobile-width) {
      .header-bar {
        background: $primary-color-2;
      }
    }
  }
}
