$breadcrumbs-font-size: 14px;
$breadcrumbs-color: #999;
$last-breadcrumb-color: $primary-color-2;

ol.breadcrumb {
  font-family: $main-font;
  font-size: $breadcrumbs-font-size;
  background-color: transparent;
  border-bottom: unset;
  padding: 0;
  margin: 30px 0 15px;

  li {
    a:not(.btn-primary, .btn-outline-primary) {
      color: $breadcrumbs-color;
    }

    &:last-child {
      a:not(.btn-primary, .btn-outline-primary) {
        color: $last-breadcrumb-color;
      }
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    margin: 13px 0;
  }
}

