#requestPasswordResetModal,
#requestMigratedPasswordResetModal,
#userReactivationModal {
  .modal {
    &-body {
      form {
        .request-password-body {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $primary-color-2;
            font-family: $main-font;
          }

          .form-group {
            label {
              font-family: $main-font;
              font-weight: 400;
              font-size: 14px;
            }

            input {
              font-family: $main-font;
              font-size: 14px;
              height: 40px;
              border-color: $primary-color-2;

              &::placeholder {
                color: transparent;
              }
            }
          }
        }

        .send-email-btn {
          .btn.btn-primary.btn-block,
          .submit-email-button {
            font-family: $main-font;
            height: 50px;
            background-color: $primary-color-2;
            font-size: 14px;
            border-radius: 25px;
            text-transform: uppercase;
          }
        }
      }
    }

    &-header {
      align-items: center;

      &.header-padding {
        padding: 0.8rem;
      }

      .request-password-title {
        color: $primary-color-2;
        font-weight: 500;
        font-size: 20px;
        margin: 0;
        font-family: $main-font;
      }

      .close {
        display: flex;
      }
    }

    &-dialog {
      @include respond-to-min-width($max-mobile-width) {
        max-width: 375px;
      }
    }
  }
}
