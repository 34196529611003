.error {
  &-500 {
    display: grid;
    height: 80vh;
    align-content: center;
    align-items: center;
    padding: 0;
  }

  &-content {
    max-width: 430px;
    display: block;
    margin: 0 auto;

    i {
      font-size: 24px;
      text-align: center;
      display: block;
    }

    .back-to-home {
      text-align: center;

      .continue-shopping {
        text-decoration: underline;
      }
    }

    .five-hundred {
      padding-top: 15px;
      font-size: 16px;
      text-align: center;
    }

    .client-number {
      padding-top: 15px;
      font-size: 16px;
      text-align: center;
    }

    @include respond-to-min-width($max-mobile-width) {
      i {
        font-size: 60px;
      }

      .five-hundred,
      .client-number {
        font-size: 18px;
      }
    }
  }

  &-message {
    margin: 0;
    padding-top: 15px;
    font-family: $main-font;
  }
}
