.empty {
  &-wishlist-message {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &-wishlist-message,
  &-order-message {
    .fa-heart {
      font-size: 32px;
    }

    .empty-text {
      font-size: 20px;
      font-weight: $font-weight-medium;

      @include respond-to-min-width($max-mobile-width) {
        font-size: 30px;
      }
    }

    .back-to-account {
      color: $custom-link-color;
      text-decoration: underline;
      font-size: 16px;
      font-weight: $font-weight-medium;
      font-family: $main-font;

      &::before {
        content: '<';
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
