$card-header-font-size-desktop: 20px;
$card-header-font-size-mobile: 16px;

.shipping-summary,
.customer-summary {
  &.checkout {
    .card-header {
      background: $medium-light-gray 0% 0% no-repeat padding-box;
      border-radius: 3px 3px 0 0;

      .edit-button {
        font-family: $main-font;
        font-weight: $font-weight-medium;
        color: $custom-link-color;
        text-decoration: underline;
        font-size: 14px;
      }

      &-custom {
        font-family: $main-font;
        font-size: $card-header-font-size-desktop;
        font-weight: $font-weight-medium;
        color: $black-text;

        @include respond-to-max-width($max-mobile-width) {
          font-size: $card-header-font-size-mobile;
          font-weight: bold;
          color: $primary-color-2;
        }
      }
    }

    .shipping {
      &-method-status,
      &-phone {
        display: none;
      }
    }

    .summary {
      &-section-label {
        padding-top: 10px;

        &.shipping-addr-label {
          &.multi-shipping {
            padding: 0;
            margin-bottom: 0;
            font-weight: $font-weight-regular;
            font-size: 16px;
          }
        }
      }

      &-details-gift {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 40px;
        border-top: 1px dashed $medium-light-gray;
      }
    }

    .gift-summary {
      margin-bottom: 0;

      .form-group {
        margin-bottom: 0;

        label {
          font-size: 14px;
          font-family: $main-font;

          i {
            font-size: 18px;

            &.fa-gift {
              margin: 0 5px;
            }
          }

          .send-as-gift-price {
            font-weight: $font-weight-medium;
          }
        }

        .custom-checkbox {
          font-size: 14px;
          margin-bottom: 25px;
          margin-top: 15px;

          .custom-control-input:checked ~ .custom-control-label::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
            background-color: $primary-color-2;
          }

          label {
            padding-top: 2px;

            &::before,
            &::after {
              @include respond-to-min-width($max-mobile-width) {
                left: 5px;
              }
            }

            &::after {
              cursor: pointer;
              background-color: white;
              border: 1px solid black;
              border-radius: 3px;
            }

            @include respond-to-min-width($max-mobile-width) {
              padding-left: 25px;
            }
          }

          @include respond-to-min-width($max-mobile-width) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
