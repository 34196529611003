.modal-dialog.quick-view-dialog {
  &.recommendation-dialog {
    max-width: 400px;
    margin: 0 auto;

    .modal-content.content-recommendation {
      margin-top: 0%;

      .slick-list.draggable {
        width: 100%;
        height: unset;
      }

      .modal {
        &-header {
          background: none;
          border-bottom: 2px solid #cacaca;

          .closePopUpRecommendation {
            border: none;
            background: none;
            color: #999;
            padding: 0;

            img.close-img {
              background: url(../images/Icon_x.svg) no-repeat;
              margin: 0;
              background-size: 16px;
              padding: 8px;
            }
          }
        }

        &-body {
          padding: 10px 16px 25px;
          scale: 1;
          max-height: 45em;

          .slick-arrow {
            &.slick-prev {
              margin-left: 10px;
            }

            &.slick-next {
              margin-right: 10px;
            }
          }

          .imageContainer {
            .productImage {
              max-width: 100%;
              display: block;
              margin-left: auto;
              margin-right: auto;
              border-bottom: 1px solid #cacaca;
            }
          }

          .product-name.name-recommendation {
            padding-top: 15px;
            max-height: 4rem;
          }

          .price {
            .productPrice {
              font-size: 30px;
              font-weight: bold;
              text-align: left;
            }

            .productPriceDiscounted {
              font-size: 28px;
              font-weight: normal;
              color: #cacaca;
              text-decoration: line-through;
            }
          }

          .detail-panel {
            .attribute {
              margin-left: 0;
              border: none;
              padding-top: 0;
            }

            .attributes .attribute-popup-recommendation-custom-css {
              margin: 0;

              .size {
                margin-bottom: 1rem;
                font-weight: 500;
              }

              li.selected {
                border-radius: 0;
              }
            }
          }

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: linear-gradient(75deg, #cfcfcf 14%, #d1d1d1 64%);
            border-radius: 9px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: linear-gradient(13deg, #ccc 5%, #d6d6d6 74%);
          }

          &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 9px;
            box-shadow: inset 7px -4px 12px #f0f0f0;
          }

          @include respond-to-between(300px, 768px) {
            max-height: 37em;
            overflow-y: scroll;
          }

          @include respond-to-between(768px, 1100px) {
            max-height: 30em;
            overflow-y: scroll;
          }
        }

        &-title {
          color: $primary-color-2;
          text-align: left;
          font-size: 20px;
          font-weight: bold;
          font-family: $family;
        }
      }

      .product-quickview {
        scale: 0.97;
      }

      @include respond-to-max-width(468px) {
        scale: 0.85;

        .quick-view-dialog .modal-content .product-detail {
          padding-left: 0;
        }

        .modal-content.content-recommendation {
          scale: 0.88;
        }

        .product-detail {
          padding-left: 0;
        }
      }
    }

    .choose-bonus-product-dialog,
    .quick-view-dialog {
      .modal-content {
        .modal-body {
          .btn-outline-custom-css {
            width: 100%;
            margin: 20px 0;
            height: 50px;
            text-transform: uppercase;
            background-color: #ed3237;
            color: white;
          }

          .button-container button {
            border-radius: 25px;
          }
        }

        .modal-body,
        .modal-body-custom-css {
          max-height: 45em;
          overflow-y: scroll;
          padding: 0;
          scale: 1;

          .row-multiple-selected {
            padding-left: 16px;
            padding-right: 16px;

            .selected-recommended-products-container {
              position: relative;

              .fa-times-recommendation {
                position: absolute;
                top: 20px;
              }
            }
          }

          .button-container {
            margin: 0;

            .pdp-checkout-button {
              padding-left: 16px;
              padding-right: 16px;
              margin-bottom: 30px;

              .button-add-recommended {
                height: 50px;
                background-color: #121212;
                border-color: #121212;
                text-transform: uppercase;
              }
            }
          }

          &:root {
            scrollbar-width: none !important;
          }
        }
      }
    }

    @include respond-to-min-width(768px) {
      scale: 1;

      .modal-content.content-recommendation {
        scale: 1;
      }
    }

    @include respond-to-min-width(1200px) {
      scale: 0.81;

      .modal-content.content-recommendation {
        scale: 0.81;
      }
    }

    @include respond-to-min-width(1440px) {
      scale: 0.93;

      .modal-content.content-recommendation {
        scale: 0.93;
      }

      .choose-bonus-product-dialog,
      .quick-view-dialog {
        .modal-content {
          .product-detail {
            padding-left: 0;
          }
        }
      }
    }
  }
}

#quickViewModal {
  background: rgba(0, 0, 0, 0.5);
}

.modal-custom-css {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-and-ipay-custom-css {
  padding-bottom: 0;
}

.button-container.button-recommended .cart-and-ipay .pdp-checkout-button .button-add-recommended {
  background-color: #121212;
  border-color: #121212;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  height: 50px;
  width: 98%;
}

.productBoxCSS {
  border: 2px solid #cacaca;
  padding: 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.recommended-product-row {
  display: inline-block;
  width: 200px;
  margin-bottom: 0;
}

.fa-times-recommendation {
  margin-left: 100px;
  cursor: pointer;
}

.selected-items-label {
  padding-top: 0.8rem;
  margin-bottom: 0.2rem;
  border-top: 1px solid #cacaca;
}

.slick-prev-reco {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 25%;
  display: block;
  width: 100px;
  height: 44px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 999;

  @media (max-width: 768px) {
    display: block !important;
  }
}

.slick-next-reco {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 25%;
  display: block;
  width: 100px;
  height: 44px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 999;

  @media (max-width: 768px) {
    display: block !important;
  }
}

.slick-prev-reco.slick-disabled,
.slick-next-reco.slick-disabled {
  display: none !important;
}
