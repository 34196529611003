.question {
  color: $black-text;
  font-size: 16px;
  font-weight: $font-weight-medium;
  display: flex;
  justify-content: space-between;
  font-family: $main-font;
  cursor: pointer;
  border-bottom: 1px solid #cacaca;
  padding: 15px;

  &[aria-expanded="true"] {
    .fa-chevron-down {
      transform: rotate(180deg);
    }
  }

  .fa-chevron-down {
    transition: 0.5s ease-in-out all;
  }

  &-container {
    max-width: 800px;
    margin: auto;

    @include respond-to-max-width(768px) {
      padding: 15px 10px;
    }
  }
}

.answer {
  margin-top: 15px;
  color: $black-text;
  font-size: 16px;
  font-family: $main-font;
  padding: 0;
}
