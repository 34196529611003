[data-action="Account-EditPassword"] {
  #maincontent {
    .breadcrumb-container {
      padding-top: 0;

      ol {
        border: none;
        margin-bottom: 0;
        padding-left: 0;
        font-size: $breadcrumbs-font-size;
        background-color: transparent;

        li.breadcrumb-item {
          a:not(.btn-primary):not(.btn-outline-primary) {
            color: $breadcrumbs-color;

            &[aria-current="page"] {
              color: $last-breadcrumb-color;
            }
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        display: block;
      }
    }

    .container {
      padding: 30px 20px;
      background-color: $background;
      min-height: 500px;

      h1 {
        font-size: 30px;
        font-weight: $font-weight-bold;
        font-family: $main-font;
        padding-bottom: 15px;
        margin-bottom: 30px;
        border-bottom: 1px solid #cacaca;

        @include respond-to-min-width(768px) {
          width: 100vw;
          padding: 0 24px 0.75rem;
          margin-left: calc(-50vw + 50%);
        }
      }

      h3 {
        margin-bottom: 20px;
        color: $primary-color-2;
        font-weight: $font-weight-medium;
        font-family: $main-font;
      }

      .card {
        .card-header {
          background-color: #f9f9f9;

          h2 {
            font-size: 20px;

            @include respond-to-max-width($max-mobile-width) {
              font-size: 16px;
            }
          }
        }

        .profile-back-to-account-link {
          margin-top: $margin-small;
          font-size: 16px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 12px;
          }
        }

        form {
          p {
            font-weight: $font-weight-bold;
            font-size: 14px;
            line-height: 24px;
            color: $primary-color-2;
            font-family: $main-font;
          }

          .form-group {
            label {
              font-family: $main-font;
              font-weight: $font-weight-regular;
              font-size: 14px;
            }

            input {
              font-family: $main-font;
              height: 40px;
              border-color: $primary-color-2;
              font-size: 14px;

              &::placeholder {
                color: transparent;
              }
            }
          }

          .controls-container {
            button {
              font-family: $main-font;
              color: $background;
              height: 50px;
              font-weight: $font-weight-bold;
              background-color: $secondary-color-1;
              font-size: 16px;
              border-radius: 25px;
              text-transform: uppercase;

              &:hover{
                box-shadow: none !important;
              }
            }

            a {
              font-family: $main-font;
              height: 50px;
              font-weight: $font-weight-bold;
              background-color: $primary-color-2;
              color: $background;
              font-size: 16px;
              padding: 10px;
              line-height: 1.7;
              border-radius: 25px;
              text-transform: uppercase;

              &:hover{
                box-shadow: none !important;
              }
            }
          }
        }
      }
    }
  }
}
